
import * as THREE from 'three'
import OrbitController from '../scene/Orbit';
import CamManager from './CamManager'
import Utils from '../scene/Utils'
import ParseLight from '../scene/LightParser'
import { FresnelShader } from '../shaders/FresnelShader';
import {getContentPath} from 'system/AssetManager'
import gsap,{Power2} from 'gsap';
import {getAlphaFresnel,getAlphaFresnel2} from '../shaders/FresnelAlpha'
import Globals from 'system/Globals';
// import Globals from 'system/Globals';

export default class DefaultProject {

    constructor(){
        this.scene = new THREE.Scene()
        this.running = false
        this.mesh = null
        this.cameraOrtho = null
        this.clearColor=0xe8e9f7;
        this.frametimer=0
        this.controls=null

        this.camera= new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.001, 1000)
        this.camera.up = new THREE.Vector3(0, 1, 0);
        this.camera.position.set(0, 1, -1);
        this.camera.name = 'Camera';
        this.scene.add(this.camera);
        this.clearColor=0x333333;
        this.sceneOrtho = new THREE.Scene()
        this.model= null
        this.current_view=null
        this.helperColor=0xFF2244
        this.camManager = this.getCamManager()
        this.markerLookup=[]
        this.arrReflections=[]
        this.skybox_folder='default'
        
        this.lights=[]
        this.colorOffsetMeshes={}
        this.colorOffsetViews={}
        this.interactiveMeshes=[]
        console.log('THREE VERSION'+THREE.REVISION)
    }

    setConfig(config){ 
        this.config=config
        // if(this.config.scene.skybox.enabled){
            this.loadReflectionCube()
        // }
    }

    getMouse(event){
      let size = new THREE.Vector2()
      this.renderer.getSize(size)
      let mouse = new THREE.Vector2()
      let posX= (event.touches && event.touches.length === 1)?event.touches[0].clientX:event.clientX
      let posY=(event.touches && event.touches.length === 1)?event.touches[0].clientY:event.clientY
      mouse.x = ( posX / size.x ) * 2 - 1
      mouse.y = - ( posY / size.y ) * 2 + 1
      return mouse
    }
    onDown(event){
      
      let mouse = this.getMouse(event)
      let raycaster = new THREE.Raycaster()
        raycaster.setFromCamera(mouse, this.cameraOrtho)

      let intersects2d = []
      this.camManager.icons.forEach(function(obj) {
          var r = raycaster.ray.origin
          var s = obj.sprite
          var p = s.position
          if (
            p.x - s.scale.x / 2 < r.x &&
            p.x + s.scale.x / 2 > r.x &&
            p.y - s.scale.y / 2 < r.y &&
            p.y + s.scale.y / 2 > r.y
          ) {
            intersects2d.push(obj)
          }
      })
      raycaster = undefined
		
      // console.log(intersects2d)
      if(intersects2d.length){
        let obj = intersects2d[0]
        // console.log(intersects2d[0])
        if(obj.key){
          this.stopRender()
          this.renderer.domElement.parentElement.dispatchEvent(new CustomEvent('module',{detail:obj}))
        }
      }
    }

    init(dom){
      return new Promise(resolve=>{
        this.initializeRenderer()
        dom.appendChild(this.renderer.domElement)
        
        dom.style.display='none'
        this.initializeControls()
        this.initializeCameraOrtho()
        this.parseViews()
        this.parseLights()
        this.initScene()
        this.renderGL()
        this.stopRender() 
        // console.log('white texture loading',getContentPath('core/webgl/textures/white.png',true))
        this.uniontexture = new THREE.TextureLoader().load(getContentPath('core/webgl/textures/white.png',true),()=>{ console.log('white loaded');
        dom=null
        resolve() })
        // resolve()
      })
        // this.startRender()
    }

    initScene(){
        // if(this.config.scene.skybox.enabled){
            if(this.cubeCam===undefined){
                console.log("set cube cam")
                this.cubeRenderTarget = new THREE.WebGLCubeRenderTarget( 2048, {  generateMipmaps: true, minFilter: THREE.LinearMipmapLinearFilter } );
                // this.cubeRenderTarget.encoding=THREE.sRGBEncoding
                // this.cubeRenderTarget.outputEncoding=THREE.sRGBEncoding
                // this.cubeRenderTarget.outputEncoding=THREE.GammaEncoding
                // this.cubeRenderTarget.texture.type = THREE.HalfFloatType;
                // this.cubeRenderTarget.gammaInput = true
                // this.cubeRenderTarget.gammaOutput = true
                // this.cubeRenderTarget.gammaFactor = 2

                this.matRefl = this.getReflectionMaterial(0.45,1.02,3,0.8,2)
                // this.matRefl2 = this.getReflectionMaterial(0.7,1.1,3,0.7,2)
                this.cubeCam = new THREE.CubeCamera(this.camera.near,this.camera.far,this.cubeRenderTarget)
                this.scene.add(this.cubeCam)
            }
            // console.log(this.cubeCam)
            this.cubeCam.update(this.renderer, this.scene);
        // }
    }
    
    initializeCameraOrtho(){
        let size = new THREE.Vector2()
        this.renderer.getSize(size)
        this.cameraOrtho = new THREE.OrthographicCamera(-size.width / 2, size.width / 2, size.height / 2,-size.height / 2, 0, 1000);
        this.cameraOrtho.position.z = 1;
    }
    initializeRenderer(){
        if(!Globals.instance().glRenderer){
          let opt ={antialias:true,format:THREE.sRGBEncoding}
          Globals.instance().glRenderer = new THREE.WebGLRenderer(opt)
         }
         this.renderer =Globals.instance().glRenderer
         this.renderer.outputEncoding = THREE.sRGBEncoding

        // this.renderer.gammaFactor = 2.2
         
        // this.renderer.gammaInput = true
        // this.renderer.gammaOutput = true
        
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.autoClear = false;
        this.renderer.setSize(window.innerWidth , window.innerHeight );
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.renderer.shadowMapSoft = true;
        this.renderer.shadowMap.enabled = true;
        this.renderer.clear()
       this.renderer.toneMapping=THREE.ACESFilmicToneMapping;
      //  this.renderer.toneMapping=THREE.ReinhardToneMapping;
       this.renderer.toneMappingExposure=1.1;
      //  console.log(this.renderer)
        // this.renderer.toneMapping=THREE.ACESFilmicToneMapping;
        // this.renderer.toneMapping=THREE.CineonToneMapping
        // this.renderer.toneMappingExposure=
    }
    initializeControls(){
        this.controls = new OrbitController(this.camera, this.renderer.domElement);
        this.controls.autoRotateSpeed = 0.5;
        this.controls.enableDamping = true;
        this.controls.dampingFactor = 0.25;
        this.controls.enableZoom = true;
        this.controls.maxDistance=10000;
        this.controls.screenSpacePanning = true;
        
        this.controls.addEventListener('start',()=>{
            this.controls.autoRotate=false
        })
    }

    renderGL(){
        if (this.running===false) { return; }
        // console.log("re")
        
        this.renderer.clear(true, true ,true);
        // this.renderer.setClearColor(this.clearColor, 1);
        
        this.renderer.autoClear=true
        
        if (this.mesh) { this.mesh.rotation.y += 0.005;}
        this.renderer.render(this.scene, this.camera);
        this.renderer.autoClear=false
        this.renderer.clearDepth();
        // this.renderer.clear(false, true ,false);
        this.renderer.render(this.sceneOrtho, this.cameraOrtho)
     
        if(this.fps)this.fps.update();

        if(this.camManager)
        this.camManager.update()
        this.updateCubeCam()
        this.controls.update();
        this.renderer.autoClear=true
        
        // this.cubeCam.update(this.renderer, this.scene);
        setTimeout( ()=>{
            this.frametimer=requestAnimationFrame(this.renderGL.bind(this));
        }, 1000 / 60 );
    }
    stopRender() {
        console.log("render stop")
        this.running = false;
        cancelAnimationFrame(this.frametimer)
    }
    startRender() {

        this.running = true;
        console.log("rendering start")
        cancelAnimationFrame(this.frametimer)
        this.frametimer=requestAnimationFrame(this.renderGL.bind(this));
    }
    handleResize(){
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      this.renderer.domElement.style.width=`${window.innerWidth}px`
      this.renderer.domElement.style.height=`${window.innerHeight}px`

      this.cameraOrtho.left=-window.innerWidth / 2
      this.cameraOrtho.right=window.innerWidth / 2
      this.cameraOrtho.top=window.innerHeight / 2
      this.cameraOrtho.bottom=-window.innerHeight / 2
      this.cameraOrtho.updateProjectionMatrix()
    }

    parseViews(){
      
        this.config.views.forEach((v,index)=>{
            if(v.default===true){
                this.camera.position.set(v.position[0],v.position[1],v.position[2])
                this.camera.updateProjectionMatrix();
                this.controls.target.set(v.lookat[0], v.lookat[1], v.lookat[2]);
                this.controls.maxDistance = v.max;
                this.controls.minDistance = v.min;
                this.controls.minPolarAngle = v['min-polar'];
                this.controls.maxPolarAngle = v['max-polar'];
                this.camManager.currentView=v
                
                this.controls.enablePan = false
                this.controls.update()
                if(v.auto_rotate)this.controls.autoRotate=true
                this.initCamViewIndex = index
            }
            if(v.icons && this.markerLookup){
              v.icons.forEach(i=>{
                const m  = this.markerLookup.find(obj=>{return i.image===obj.key})
                if(m){
                  i.url=m.markerImage
                  i.key=m.key
                }
              })
              // v.icons = v.icons.filter( i=> i.markerImage)
            }
        })

    }

    loadReflectionCube(){
      if(this.config.scene.skybox.path)
      this.skybox_folder=this.config.scene.skybox.path

        return  new Promise((resolve)=>{

          let path = getContentPath('core/webgl/skybox/'+this.skybox_folder+'/',true);

          const format = '.png';
          let urls = [
            `${path}px${format}`, `${path}nx${format}`,
            `${path}py${format}`, `${path}ny${format}`,
            `${path}pz${format}`, `${path}nz${format}`,
          ];
          
            // let imgs=[this.config.textures.px,this.config.textures.nx,this.config.textures.py,this.config.textures.ny,this.config.textures.pz,this.config.textures.nz]
           this.reflectionCube = new THREE.CubeTextureLoader().load(urls,(text)=>{
            this.reflectionCube.mapping = THREE.CubeReflectionMapping;
            // this.renderer.outputEncoding = THREE.sRGBEncoding
          //  this.reflectionCube.encoding = THREE.sRGBEncoding
           
            this.bSetCubeCam=false
            console.log("Reflection cube loaded up")
            if(this.config.scene.skybox.enabled!==false){
              this.scene.background = this.reflectionCube  
              // this.scene.environment = text;
            }
            resolve()
           });
           this.reflectionCube.mapping = THREE.CubeReflectionMapping;
           this.reflectionCube.encoding = THREE.sRGBEncoding
           
          //  console.log(this.config,'config')
          //  if(this.config.scene.skybox.enabled)
             
           
           this.updateCubeCam()
        })
        
    }
    updateCubeCam() {
      
      if (this.bSetCubeCam === false) {

          let tempBG=this.scene.background
          if(!this.scene.background){
              this.scene.background= this.reflectionCube
          }

          
          this.arrReflections.forEach(ref=>{ref.visible=false})
          this.cubeRenderTarget.clear(this.renderer, true, true, true);
          
          this.cubeCam.position.set(-0.0, 0.05, -0.01)
          this.controls.update()
          this.cubeCam.lookAt( this.camera.position.x,this.camera.position.y,this.camera.position.z)
           

          // this.cubeCam.update
          if(this.model)this.model.visible=false
          if (this.model && this.config.scene.model && this.config.scene.skybox.render_reflection === true ) {
            this.model.visible = true;
          }
          this.scene.visible=true
          
          this.cubeCam.update(this.renderer, this.scene);
          
          this.matRefl.uniforms.tCube.value=  this.cubeRenderTarget.texture
          // this.matRefl2.uniforms.tCube.value=  this.cubeRenderTarget.texture
          
          this.arrReflections.forEach(ref=>{
            // console.log("setupt")
            // ref.envMap=this.reflectionCube
            ref.uniforms.tCube.value=  this.cubeRenderTarget.texture
            ref.needsUpdate=true
            ref.visible=true
          })
         
        

          if(this.model)this.model.visible = true;
          this.scene.background=tempBG  //set to the previous bg 
          this.bSetCubeCam = true;
      }
    }
    
    parseLights(){
        this.config.lights.forEach((light,index)=>{
            let l=ParseLight(light)
            if(light.shadow)this.setShadow(l)
            this.lights.push(l)
            this.scene.add(l)
        })
    }

    setShadow(L){
      L.castShadow = true;
      L.shadow.bias = 0.000001;
      L.shadow.darkness = 0.0001;
      L.shadow.mapSize.width = 2048;
      L.shadow.mapSize.height = 2048;
      L.shadow.camera.right = 1;
      L.shadow.camera.left = -1;
      L.shadow.camera.top = 1;
      L.shadow.camera.bottom = -1;
      L.shadow.camera.near = 0.15;
      L.shadow.camera.far = 100;
  }
  
    
    getCamManager(){ return new CamManager(this) }

    getMenuViews(){
      let menu={}
      this.config.views.forEach(v=>{
        if(!menu[v.category])menu[v.category]=[]
        menu[v.category].push(v)
      })
      // console.log(menu)
      return  menu
    }

    async traverseMesh(child,meshDefault){
      
      // await new Promise(r => setTimeout(r, 0));

      let settings = this.config.scene.mesh_settings
      if (Object.prototype.hasOwnProperty.call(settings, child.name)) {
        if(settings[child.name])
        console.log(settings[child.name])
        const objSettings = settings[child.name];
        
        if (objSettings.interactive === true) {
          console.log("adding interactive ")
          this.interactiveMeshes[child.name] = child;
        }
        this.setMeshParams(child, Object.assign({}, meshDefault, objSettings));
      }
      else if (child.name.toLowerCase().indexOf('glass') === 0 ) {
        // console.log('glass')
        child.material.dispose()
        child.material=this.matRefl
        child.material.depthWrite = false;
        child.material.depthTest = true;
        child.renderOrder = -1;
        child.castShadow = false;
        child.receiveShadow = false;
        child.material.transparent = true;
    } else if (child.name.toLowerCase().indexOf('refl') === 0) {
        // console.log('reflection')
        // const cubeMaterial3 = new THREE.MeshLambertMaterial( { transparent:true, envMap: this.reflectionCube} );
        
				// child.material = new THREE.MeshStandardMaterial( {
				// 	envMap: this.cubeRenderTarget.texture,
				// 	roughness: 0.05,
				// 	metalness: 1
				// } );

        let map
        if(child.material.map ){
            // console.log("map",child.material.map.format,THREE.RGBAFormat)
              map = child.material.map
              map.encoding=THREE.sRGBEncoding
              // child.material.color= child.material.color.convertSRGBToLinear()
              let mat = getAlphaFresnel2(this.cubeRenderTarget,map,
                1.5,1.02,0.1,2.0,0.1,2.0)
              // Utils.disposeMaterial(child.material)
              child.material.dispose()
              child.material = mat
              child.material.needsUpdate=true
              child.material.depthWrite = false;
            }else{
              // console.log("base texture")
              // console.log(this.uniontexture)
              let mat = getAlphaFresnel2(this.cubeRenderTarget,this.uniontexture,
                1.5,1.02,0.1,2.0,0.1,2.0)
              Utils.disposeMaterial(child.material)
              child.material.needsUpdate=true
              child.material = mat
        }   
        child.material.depthTest = true;
        child.material.depthWrite = false;
        child.renderOrder = 1;
        child.castShadow = false;
        child.receiveShadow = false;
        child.material.transparent = true;
        // child.material=cubeMaterial3
        child.material.needsUpdate=true
        this.arrReflections.push(child.material)
        
      }
    
      else  if(child.name.toLowerCase().indexOf('chrome')===0){
        child.geometry.computeVertexNormals()
        this.setChromeMaterial(child)
     
        
      }
      else {
        this.setMeshParams(child, Object.assign({}, meshDefault));
      }
      
      
      this.mapColorOffset(child)
      // console.log(child===this.scene)
    }

    mapColorOffset(child){
      if(this.colorOffsetMeshes[child.name]){
        
        let offsetObj=this.colorOffsetMeshes[child.name]
        Object.keys(offsetObj).forEach(key=>{
          let view = this.config.views.find(v => { return v.name.toLowerCase()===key})
          child.oColor = child.material.color.clone()
          child.highColor = new THREE.Color(offsetObj[key].colorOffset)
          if(view){
            if(this.colorOffsetViews[view.id])
              this.colorOffsetViews[view.id].push(child)
            else 
              this.colorOffsetViews[view.id]=[child]
          }else{
            console.warn('could not find color offset view '+key)
          }
        })
      }
    }
    traverseModel() {
        this.interactiveMeshes=[]
       
          let meshDefault = null;
          if (this.config.scene.mesh_settings && this.config.scene.mesh_settings.default) { meshDefault = this.config.scene.mesh_settings.default; }
          // console.log(this.model)
          this.model.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              if (this.config.scene.mesh_settings){
                this.traverseMesh(child,meshDefault)
              }
            }else{
                this.traverseModelObject(child)
            }
          });

          
      }
      traverseModelObject(child){}
      setChromeMaterial(child){
        let mat=child.material
        if(!mat){
          var chrome = new THREE.MeshStandardMaterial( {
          color: 0xFFFFFF,
          envMap: this.cubeRenderTarget.texture,
          roughness:0.2,
          metalness:1 
        } );
          mat=chrome
          child.material=mat  
        }
        mat.envMap=this.cubeRenderTarget
        mat.roughness=0.2
        mat.metalness=1;
        mat.needsUpdate=true
        mat.flatShading=false
        mat.needsUpdate=true
        // console.log(chrome)
        // return chrome
      }

      // getReflectionMaterial2(){
      //   let mat = new THREE.MeshPhysicalMaterial( {
			// 		map: null,
			// 		color: 0xffffff,
			// 		metalness: 0.85,
			// 		roughness: 0.1,
			// 		opacity: 0.0,
			// 		transparent: true,
			// 	} );
      //   mat.blending=THREE.AdditiveBlending
      //   console.log(mat)
      //   return mat
      // }
      getReflectionMaterial(blendAlpha,refractionRatio,power,bias,scale){
        const shader = FresnelShader;
        const uniforms = THREE.UniformsUtils.clone(shader.uniforms);
        uniforms.tCube.value = this.cubeRenderTarget.texture//this.reflectionCube;
        uniforms.mFresnelPower.value = power//3;
        uniforms.mFresnelBias.value = bias///0.80;
        uniforms.mFresnelScale.value = scale//2.0;
        uniforms.mRefractionRatio.value = refractionRatio;
        
        const fragmentShader = [
          'uniform samplerCube tCube;',
          'varying vec3 vReflect;',
          'varying vec3 vRefract[3];',
          'varying float vReflectionFactor;',
          'void main() {',
          '	vec4 reflectedColor = textureCube( tCube, vec3( vReflect.x, vReflect.yz ) );',
          ' reflectedColor.r = ( reflectedColor.r * 1.1) + 0.0;',
          ' reflectedColor.g = ( reflectedColor.g * 1.00 ) + 0.0;',
          ' reflectedColor.b = ( reflectedColor.b * 0.95 ) + 0.0;',
          ' reflectedColor.a = '+blendAlpha+';',
          '	vec4 refractedColor = vec4( 1.0 , 1.0 , 1.0 ,  '+blendAlpha+');',
          '	refractedColor.r = 0.0 + textureCube( tCube, vec3( vRefract[0].x, vRefract[0].yz ) ).r * 1.05;',
          '	refractedColor.g = 0.0 + textureCube( tCube, vec3( vRefract[1].x, vRefract[1].yz ) ).g * 1.05;',
          '	refractedColor.b = 0.0 + textureCube( tCube, vec3( vRefract[2].x, vRefract[2].yz ) ).b * 0.95;',
          '	gl_FragColor = mix( refractedColor, reflectedColor, clamp( vReflectionFactor, 0.0, 1.0 ) );',
          '}',
        ].join('\n');

        const material = new THREE.ShaderMaterial({
          uniforms,
          vertexShader: shader.vertexShader,
          fragmentShader:fragmentShader,
        });
        // material.sides=THREE.DoubleSide
        
        return material
      
      
      }
    setMeshParams(child, params) {
        if (params) {
          Object.keys(params).forEach((key) => {
            if (key !== 'material') {
              child[key] = params[key];
            }
          });
    
          if (params.material) {
            Object.keys(params.material).forEach((mkey) => {
              if (mkey === 'shading') {
                if (params.material[mkey] === 2) { params.material.flatShading = false; } else { params.material.flatShading = true; }
              } else {
                child.material[mkey] = params.material[mkey];
              }
            });
            child.material.needsUpdate = true;
          }
        }
      }
      async addModel(gltf,cb){

        let objScene = gltf.scene
        this.model=objScene
        objScene.scale.set(this.config.scene.model.scale[0],this.config.scene.model.scale[1],this.config.scene.model.scale[2])
        objScene.position.set(this.config.scene.model.position[0],this.config.scene.model.position[1],this.config.scene.model.position[2])
        this.scene.add(objScene)
        objScene=null;


        this.traverseModel()
        
        // await new Promise(r => setTimeout(r, 300));

        
        this.startRender()
        this.bSetCubeCam=false
        this.updateCubeCam()
        this.renderGL()

        this.stopRender()
        this.renderer.domElement.parentElement.style.display='block'
        gsap.fromTo(this.renderer.domElement,{opacity:0},{duration:1,delay:0,opacity:1,ease:Power2.easeInOut,onComplete:(o,callb)=>{
          o.startRender()
          o.camManager.onCompleteAnimView()
          callb()
        },onCompleteParams:[this,cb]})
    }
    onChangeCategory(c){
      //hanlder for view category changes
    }
    cleanup(){
      console.log("CLEANUP")
      this.stopRender()  
        this.arrReflections=undefined
        
        this.colorOffsetMeshes=undefined
        this.colorOffsetViews=undefined
        if(this.matRefl)this.matRefl.dispose()
        this.matRefl=undefined
        // this.matRefl2=undefined
        
        if(this.listenerStart)
        this.controls.removeEventListener('start',this.listenerStart)
        this.controls.dispose();
        this.controls = undefined;

        this.camManager.destroy();
        this.camManager = undefined;

        this.model=undefined;
        this.interactiveMeshes=undefined;
        this.markerLookup=undefined
        this.lights=undefined
      
        // cleaup renderer
        
        if(this.cubeRenderTarget){
          this.cubeRenderTarget.dispose()
          this.cubeRenderTarget=undefined
        }
        if(this.cubeCam){
          this.cubeCam=undefined
        }

        this.reflectionCube=undefined

        if(this.renderer.domElement)
          this.renderer.domElement.remove()
          
          // this.renderer.dispose();
        // this.renderer.forceContextLoss();
        // this.renderer.context=null
        // this.renderer.domElement = undefined;
        this.renderer = undefined;


        Utils.disposeHierarchy(this.scene)
        Utils.disposeHierarchy(this.sceneOrtho)
        

        this.camera = undefined;
        this.cameraOrtho=undefined
        this.current_view = undefined;
        this.scene = undefined;
        this.sceneOrtho=undefined;
        this.project = undefined;
        if(this.uniontexture)
          this.uniontexture.dispose()
        this.uniontexture=undefined;
       
    }

}