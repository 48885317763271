
import React,{useRef,useState,useEffect,memo,useCallback} from 'react'
import {connect} from 'react-redux'
import {fetchCMSJSON,fetchCMSJSONs} from 'system/AssetManager'
import Page from './Page'
import styles from './narrative.module.scss'
import gsap,{Power3,Power2,Power1} from 'gsap'
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import NarrativeMenu  from "./NarrativeMenu"
import ModuleContainer from './modules/ModuleContainer'
import Globals from 'system/Globals'
import LoaderWheel from 'system/components/LoaderWheel'
import NarrativeControls from './NarrativeControls'
import DrawControls  from './DrawControls'
import Markup  from  './Markup'
import ControllerServerComs from 'system/ControllerServerComs'
import postEvent from 'system/components/UserEvent';

// import { matchPath } from 'react-router-dom/cjs/react-router-dom.min'


gsap.registerPlugin(ScrollToPlugin);

const Narrative = function(props){
   
    const [status,setStatus] = useState('')
    const refLoadPercent = useRef({percent:0})
    const [loaded,setLoaded] = useState(false)
    // const [scrolling,setScrolling] = useState(false)
    const [NarrativeModel ,setNarrativeModel] = useState({})
    const [PageModels,setPageModels] = useState(0)
    const pageIndexLookup = useRef({})
    // const [Interaction,setInteraction] = useState()
    
    const [theme,setTheme]=useState(null)
    const [modules, setModules]=useState([])
    const [currentModule, setCurrentModule]=useState(null)
    const [syncComplete,setSyncComplete]=useState(false)
    const [nid,setNId] = useState()
    const [showNarrativeControls, setShowNarrativeControls] = useState(false)
    const [showDrawControls, setShowDrawControls] = useState(false)
    const [showMarkup, setShowMarkup] = useState(false)
    const [menuItems,setMenuItems] = useState([])

    const [openMenuFull, setOpenMenuFull] = useState(false);
 
    const refEle =useRef()
    const refMenu =useRef()
    const refNarrativeControls =useRef()
    const refInteraction =useRef()
    
    const refTopBar =  useRef()
    const refChildrenPages =useRef()
    const refCurrentPage =useRef(null)
    const refScrollTable =useRef({})
    // const refModule = useRef()
    
    const refListeners= useRef([])
    const refResizeListener= useRef()
    const refScroller =useRef()
    const refScrollerContainer =useRef()
    const refScrollVel= useRef(0)
    const refDown= useRef(false)
    const timerScroller =useRef()
    const timerSearchParams =useRef()
    const pageTableLeft =useRef([])
    const pageTableWidth =useRef([])
    const PageLoadCount =useRef(0)
    const refScrollLeft = useRef(0)
    const refScrollSpeed = useRef(0)
    const refTotalWidth=useRef(0)
    const refInitialSlide=useRef(0)
    const refInitialModule=useRef(0)
    const refDimensionCache = useRef([])
    const refLoaderContainer=useRef()
    const refWindowListener =  useRef()
    const refServerAppListener =  useRef()
    const refKeyListenerUp =  useRef()
    const refKeyListenerDown =  useRef()
    const refScrolltween = useRef()
    const refScrollPos = useRef()

    
    let firstObservered = false
    // let useiPad=useRef(false)

    
    useEffect( ()=>{
        setStatus('Loading')

        if(!loaded){
            gsap.to(refLoaderContainer.current,{duration:0.35,ease:Power3.easeInOut,opacity:1})
            gsap.to(refLoaderContainer.current.querySelector('.loader-container svg'),{duration:0.5,scale:1,ease:Power3.easeInOut,opacity:1})
        }
        let params = new URLSearchParams(new URL(window.location.href).search);
        if(Globals.instance().useHashRouter){
            let arrurl=window.location.href.split("?")
            if(arrurl.length)
            params = new URLSearchParams(arrurl[1])
        }
        
        refInitialSlide.current=params.get("slide")
        refInitialModule.current=params.get("module")
          
        let observer = new IntersectionObserver((entries)=>{
            entries.forEach((e)=>{
                let index = e.target.getAttribute("index")
                refChildrenPages.current[index].dispatchEvent(new CustomEvent('intersection',{detail:e}))
            })
        },{threshold:[0.001,0.35,0.6]})
        refInteraction.current=observer
        // setInteraction(observer)
        let eleScroller = refScroller.current;
        
        let nid= (props.history.location.pathname.indexOf('/narrative')===0 )?props.history.location.pathname.split("/")[2]:props.nid 
        setNId(nid?nid:props.nid )   
        Globals.instance().nid=props.nid
        
        let listen =false
        let strSync=localStorage.getItem('manualSync')
        if(Globals.instance().ios && strSync!=='false' && !Globals.instance().controllerApp){
            window.webkit.messageHandlers.notification.postMessage({ Object: 'sync-narrative', narrative:nid })
            listen=true
        }
        if(Globals.instance().electron && strSync!=='false'){
            listen=true
            window.api.send("toApp", {"event":"sync-narrative","narrative":nid});
         }
        
        if(listen){
            
            if(Globals.instance().serverApp)
            ControllerServerComs.instance().postMessageFromServerToElectron( "narrative-load-state",{"narrative":nid,"state":"loading"})
            

            setStatus('Loading')
            refWindowListener.current=(evt)=>{
                
                let detail = evt.detail
                if(!detail)return
                if(detail.narrative===nid){
                    setStatus(detail.message)
                }
                if(detail.message==="Complete"){
                    setSyncComplete(true)
                    window.removeEventListener('window-event',refWindowListener.current)
                    if(Globals.instance().serverApp)
                        ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"complete"})
                    }
                if(detail.event==='sync-error'){
                    console.log("Received Error",detail.message)

                    if(Globals.instance().homeLink){
                        props.history.push(Globals.instance().homeLink)
                    }else{
                        props.history.push(Globals.instance().getRoute('/home'))
                    }
                    Globals.instance().showAlert('Connection Error',detail.message)
                    if(Globals.instance().serverApp)
                    ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"error"})
                }
            }
            window.addEventListener('window-event',refWindowListener.current)
            
           
        }
        else{
            setSyncComplete(true)
            if(Globals.instance().serverApp)
            ControllerServerComs.instance().postMessageFromServerToElectron("narrative-load-state",{"narrative":nid,"state":"complete"})
        }
        refKeyListenerDown.current=(evt)=>{
            if (evt.code === "ArrowLeft" || evt.code === "ArrowRight"){
                evt.preventDefault()
            }
        }
        refKeyListenerUp.current=(evt)=>{
            if (evt.code === "ArrowLeft"){
                scrollOffset(-1)
            }
            else if (evt.code === "ArrowRight") {
                scrollOffset(1)
            }
        }

        document.addEventListener('keydown',refKeyListenerDown.current)
        document.addEventListener('keyup',refKeyListenerUp.current)
        console.log("w", window.innerWidth, "h", window.innerHeight, "d", window.devicePixelRatio)
        
        

        return ()=>{
            //cleanup
            console.log("CLEANUP")
            if(eleScroller && refListeners.current){
                refListeners.current.forEach(l=>{ eleScroller.removeEventListener(l.e,l.f)})
            }
            if(refInteraction.current)refInteraction.current.disconnect()

            refListeners.current=undefined
            onScrollEvent=undefined

            window.removeEventListener('resize',refResizeListener.current)
            window.removeEventListener('window-event',refWindowListener.current)
            window.removeEventListener('server-event',refServerAppListener.current)
            document.removeEventListener('keyup',refKeyListenerUp.current)
            document.removeEventListener('keydown',refKeyListenerDown.current)
            
            observer.disconnect();
            
            cancelAnimationFrame(timerScroller.current)
            clearTimeout(timerSearchParams.current)
      
            refScrollTable.current=undefined
            refListeners.current=undefined
            refScrolltween.current=undefined
            refResizeListener.current=null
            refChildrenPages.current=undefined

            postEvent(props.user.username, {"event": "page-exit", "page": "narrative", "page_id": props.nid, "page_title": NarrativeModel.heading}, props.user.market, props.user.category);
        }

        
      // eslint-disable-next-line react-hooks/exhaustive-deps  
    },[])
    // const scrollBy = useCallback((p,dur,easeeq)=>{
    //     console.log("scrollby"+p)
    //     if(p<0)p=0
    //     if(p>refScroller.current.scrollWidth-refScroller.current.clientWidth){
    //         p=refScroller.current.scrollWidth-refScroller.current.clientWidth
    //         console.log("over")
    //     }
    //     // console.log(p)  
    //     if(refScrolltween.current) refScrolltween.current.kill() 
    //     let objtween={left:parseFloat(refScrollPos.current.style.left?refScrollPos.current.style.left:0)}
    //     refScrolltween.current = gsap.to(objtween,
    //         {duration:dur?dur:2.5, left:p,
    //             onUpdate:(objtw)=>{
    //                 refScrollPos.current.style.left=objtw.left+'px'
    //                 // onScrollEvent()
    //             },
    //             onUpdateParams:[objtween],
    //             ease:easeeq?easeeq:Power3.easeInOut,
    //             onComplete:(eleScroll)=>{
    //             eleScroll.style.pointerEvents='all'
    //             },onCompleteParams:[refScroller.current]
    //     })
    // },[refScrolltween])

    const scrollToPage = useCallback((p,animFade,dur,easeeq)=>{
        console.log('scroll to '+p)
        refScroller.current.style.overflow='hidden'
        let pEle = refScrollerContainer.current.querySelector(".Page[index=\""+p+"\"]")
        let rect= pEle.getBoundingClientRect()
        let offsetScroll = parseFloat(pEle.getAttribute("scroll_offset"))
        let offSet = (refScroller.current.clientWidth - pageTableWidth.current[p]) * offsetScroll
        if(refScroller.current.clientWidth>rect.width)offSet=0
        
        refScroller.current.style.overflow='scroll hidden'
        refScroller.current.style.pointerEvents='none'

        if(refScrolltween.current)refScrolltween.current.kill() 
        
        if(animFade === 'fade'){
            let eleColor = document.createElement('div')
            eleColor.style.backgroundColor = 'var(--primary-background)'
            eleColor.style.opacity=0
            eleColor.classList.add('fullscreen')
            refEle.current.insertBefore(eleColor,refScroller.current.nextSibling)
           
            gsap.to(eleColor,{opacity:1,duration:0.5,ease:Power2.easeIn ,onComplete:(offsetX,eleScroll,elcolor)=>{
                
                eleScroll.scrollLeft=offsetX

                eleScroll.style.pointerEvents='all'
                onScrollEvent()
                gsap.to(elcolor,{opacity:0,duration:0.5,ease:Power2.easeIn,onComplete:(el)=>{el.remove()},onCompleteParams:[elcolor]})
            },onCompleteParams:[pageTableLeft.current[p]-offSet,refScroller.current,eleColor]})
        }  else{

                refScrolltween.current = gsap.to(refScroller.current,{duration:dur?dur:2.5, scrollTo:{x:pageTableLeft.current[p]-offSet},ease:easeeq?easeeq:Power3.easeInOut,onComplete:(eleScroll)=>{
                    eleScroll.style.pointerEvents='all'
                },onCompleteParams:[refScroller.current],
            onUpdate:()=>{
                // onScrollEvent()
            }})    

        }

},[])


    const scrollOffset = useCallback((offset)=>{
        cancelAnimationFrame(timerScroller.current)
        let p =-1
           Array.from(refDimensionCache.current).forEach((ele,index)=>{
                
            
                if(refDimensionCache.current[index].left < refScroller.current.scrollLeft + 5){
                   p=index
               }
           })
        //    console.log(p)
           p=p+offset
           if(p<0)return
           if(p>=refDimensionCache.current.length)return
        scrollToPage(p,'scroll')  
   },[refScroller,refDimensionCache,scrollToPage])

    useEffect(()=>{

        if(syncComplete){
            
            let m = props.narratives.find( val=>{ return String(val.id)===nid})

            if(m===undefined){
                console.warn('could not find narrative '+nid)
                Globals.instance().showAlert('Error','Could not find narrative ('+nid+')')
            }
            else{
                (async ()=>{
                    
                    let res = null
                    let path = ""
                    try { 
                        // setStatus('Loading JSON')
                        path = m.content? m.content:'narrative/'+m.id+'/'
                        res=await fetchCMSJSON(path)
                        // console.log(res)
                    } catch(err){
                        console.error(err)
                        let strSync=localStorage.getItem('manualSync')
                        if(strSync==='false'){
                            Globals.instance().showAlert('The Narrative you requested is not available','Please check your that  manual sync is off in your settings to allow the narrative to download <br> <em>'+path+'</em>' )
                        }else{
                            Globals.instance().showAlert('Load Error','Narrative Content <br> <em>'+path+'</em>' )
                        }
                        //abort narrative go head back 
                        let params = new URLSearchParams()
                        console.log("going "+props.history.location.pathname)
                        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
                        return
                    }
                    
                    try {
                        // setStatus('Setting Theme',props.themes[res.theme?'t'+res.theme:'t1'])
                        setTheme(props.themes[res.theme?'t'+res.theme:'t1'])
                    }catch(err){
                        console.error(err)
                       
                        Globals.instance().showAlert('Error','Could not load narrative theme')
                        //abort narrative go head back 
                        let params = new URLSearchParams()
                        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
                        return
  
                    }
                    
                        setNarrativeModel(res);
                        refChildrenPages.current=(new Array(res.pages.length))
                    try{    
                        res = await fetchCMSJSONs(res.pages)
                        // setStatus('Loading Content')
                        res.forEach((p,i)=>{ pageIndexLookup.current[String(p.id)]=i})
                        
                        //platform filter
                        res.forEach(p=>{
                            let filteredComponents = p.components.filter(c=>{
                                if(c.platform){
                                    console.log(c.platform, Globals.instance().ipad)
                                    if (c.platform==="ipad" ) return  Globals.instance().ipad
                                    else if (c.platform==="desktop" ) return  !Globals.instance().ipad
                                    else return true
                                }
                                else return true
                            })
                            p.components=filteredComponents
                            
                        })
                        setPageModels(res);
                    }catch(err){
                        console.error(err)
                        Globals.instance().showAlert('Error','Could not load narrative slides')
                        //abort narrative go head back 
                        let params = new URLSearchParams()
                        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
                    }
                })()
            }
            
           
            refMenu.current.style.visibility='hidden'
            refScroller.current.style.visibility='hidden'

            refResizeListener.current=()=>{
                if(refScroller.current){
                    let percent = window.innerHeight/Math.floor(refScroller.current.clientHeight)
                    
                    refScroller.current.style.transform='scale('+percent+')'
                    refScroller.current.style.width=window.innerWidth/percent+'px'
                    refScroller.current.style.transformOrigin='top left'
                    refDimensionCache.current.forEach( (cache,index)=>{
                        let ele =refScrollerContainer.current.childNodes.item(index)
                        cache.left=ele.offsetLeft
                        cache.right=ele.clientWidth
                    })
                    refChildrenPages.current.forEach((page,i)=>{
                        pageTableLeft.current[i]=page.offsetLeft        
                        pageTableWidth.current[i]=page.clientWidth
                      })
                  
                }
            }
            window.addEventListener('resize',refResizeListener.current)
            
            refScroller.current.style.height=window.innerHeight+'px'

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ syncComplete])

    //create a menuItems tree for both nar menu and controls 
    useEffect(()=>{
        if(NarrativeModel){
                
            let mitems=[]
                if(NarrativeModel.menu_links){
                    NarrativeModel.menu_links.forEach((val,index)=>{
                    
                        let menuItem = { label:val.label,index:index }
                    
                    if(val.group){
                        let grp = mitems.find(mi=>{return mi.label===val.group})
                        if(!grp){
                            grp={label:val.group, subs:[menuItem]}
                            mitems.push(grp)
                        }
                        else
                            grp.subs.push(menuItem)
                    }else{
                        mitems.push(menuItem)
                    }
                    })
                    // console.log("SET ",mitems)
                 setMenuItems(mitems)
                }
            NarrativeModel.heading && postEvent(props.user.username, {"event": "page-enter", "page": "narrative", "page_id": props.nid, "page_title": NarrativeModel.heading}, props.user.market, props.user.category);

        }
    },[NarrativeModel])

    useEffect(()=>{
        // console.log('THEME',theme)
        if(theme){
         for (const key of Object.keys(theme.css)) {
            refEle.current.parentElement.style.setProperty('--'+key,theme.css[key])
          }
        }
    },[theme])

    useEffect(()=>{
        if(modules && modules.length && refInitialModule.current)
        {
            let module = modules.find(m=>{return String(m.id)===String(refInitialModule.current)})
            if(module){
                let m = {...module}
                m.direct=true
                // console.log(m)
                setCurrentModule(m)
            }
            refInitialModule.current=null
        }
    },[modules])

    useEffect(()=>{
        let eleVideo = refMenu.current.querySelector('video')
        if(eleVideo){
            if(currentModule)
                eleVideo.pause()
            else{
                 let p=  eleVideo.play()
                                if(p!==undefined){
                                    p.then(function() {
                                        // Automatic playback started!
                                      }).catch(function(error) {
                                        console.warn('MENU Video '+error)
                                      });
                                    }
            }
                
        }
    },[currentModule])
    
    function setScrollTable(cb,index){
        refScrollTable.current[index]=cb
    }

    // function checkScrolling(){
    //     if(timerScrolling){
    //     clearTimeout(timerScrolling)
    //         timerScrolling=0;
    //         // setScrolling(false)
    //     }
    // }
    
    function onClickTopBar(evt){
        // let index = evt.currentTarget.getAttribute("index")
        // let left =pageTableLeft.current[index]
        // refMenu.current.dispatchEvent(new CustomEvent('close-menu'))
        // gsap.to(refScroller.current,{duration:1, scrollTo:{x:left},ease:Power3.easeInOut})
    }

    const onScrollToEvent=(evt)=>{
        let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+evt.detail.page+"\"]")
        if(!pEle)return
        let index =pEle.getAttribute("index")
        scrollToPage(index,'fade')
    }
    const selectModule=(evt)=>{
        if(evt.detail.module)
        {   
            //set narrative url
            // console.log(evt.detail.module,props.history.location.search)
            let strP=props.history.location.search
            if(evt.detail.module.menuParams)
                strP=strP+evt.detail.module.menuParams
            let params = new URLSearchParams(strP)
            params.set("module",evt.detail.module.id)

            props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
            setCurrentModule(evt.detail.module)

        }
    }
    let onScrollEvent =()=>{
        // console.log("scroll")
        
        let pos = refScroller.current.scrollLeft
        refScrollSpeed.current=refScrollLeft.current - pos
        refScrollLeft.current=pos
    
        let fIndex = -1
        let posX = pos ;
        
        Array.from(refScrollerContainer.current.childNodes).forEach((ele,index)=>{
            
            if(Math.abs(refScrollSpeed.current) < 100 &&( (
                refDimensionCache.current[index].left + refDimensionCache.current[index].width > posX &&
                refDimensionCache.current[index].left <= posX + refEle.current.clientWidth
                ) )
            ){
                if(refScrollTable.current[index]){ refScrollTable.current[index].call(this,pos,refScroller.current.clientWidth)}
                
                // console.log("in "+index)
                 //top menu bar
                 if( refDimensionCache.current[index].left + refDimensionCache.current[index].width > posX+ refEle.current.clientWidth/2 &&
                    refDimensionCache.current[index].left <= posX + refEle.current.clientWidth/2){

                if(fIndex===-1 && refCurrentPage.current!==index){
                    let eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
                    if(eleSelected) eleSelected.setAttribute('active','false')
                    refCurrentPage.current= index
                    // console.log('setpage '+index)
                    eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
                    if(eleSelected)eleSelected.setAttribute('active','true')
                }
                fIndex = index 
                }
                   
            }
        })
        
    
    }

    async function loadModules(arr){
        
        if(!arr || arr.length===0) return[]
        let proms= []
        arr.forEach(async obj =>{ proms.push(fetchCMSJSON(obj))})

        return new Promise( (resolve,reject) => {
              
            Promise.all(proms).then((values)=>{ resolve(values) }).catch((err)=>{
                reject(err)
            })
        
        })
    }
    
    //on load highlight top bar
    useEffect(()=>{
        let eleSelected = refTopBar.current.querySelector("[index=\""+refCurrentPage.current+"\"]")
        if(eleSelected) eleSelected.setAttribute('active','true')
    },[loaded])
    
   async function onPageLoad(index,ele){
       
        refChildrenPages.current[index]=ele
        // Interaction.observe(ele)
        PageLoadCount.current = PageLoadCount.current +1
        let per = PageLoadCount.current/PageModels.length*100
        
        gsap.to(refLoadPercent.current,{ease:Power2.easeOut,duration:0.5,percent:per,onUpdate:()=>{
            setStatus(`${Math.floor(refLoadPercent.current.percent)}%`)
        }})
        
        
        if(PageLoadCount.current === PageModels.length){
          
          //load moduels
          gsap.killTweensOf(refLoadPercent.current)
          setStatus('Starting')
          let mods=[]
          try{
             mods = await loadModules(NarrativeModel.modules)
             setModules(mods)
          }catch(err){
            console.error(err)
            Globals.instance().showAlert("Error","Could not load narrative module")
          }

          pageTableLeft.current=  new Array(PageModels.length)


        let listenerPopup = (evt=>{
            if(evt.detail.typeName==="module"){
                let m = Object.assign({},evt.detail)
                let mode = mods.find(o=>{ return o.id===evt.detail.module})
                 m = Object.assign(m,mode)
                setCurrentModule(m) 
            }
            else if(evt.detail.typeName==="image"){
                setCurrentModule(evt.detail)
            }else if(evt.detail.typeName==="video"){
                setCurrentModule(evt.detail)
            }
        })

          refChildrenPages.current.forEach((page,i)=>{
            pageTableLeft.current[i]=page.offsetLeft        
            pageTableWidth.current[i]=page.clientWidth
          })
          
          refListeners.current.push({e:'scroll',f:onScrollEvent})
          refListeners.current.push({e:'wheel',f:onWheel})
          if(navigator.maxTouchPoints > 0)
          refListeners.current.push({e:'touchstart',f:onDown})
          else
          refListeners.current.push({e:'mousedown',f:onDown})
          refListeners.current.push({e:'narrative-pop-up',f:listenerPopup})
          
          refListeners.current.forEach((l)=>{
            refScroller.current.addEventListener(l.e,l.f,{passive:false})
          })
        
          
          refTotalWidth.current = refScrollerContainer.current.clientWidth
          
          Array.from(refScrollerContainer.current.childNodes).forEach((ele)=>{
              
             refDimensionCache.current.push({left:ele.offsetLeft,width:ele.clientWidth})
          })

        //   let initScrollPos =0
          if(refInitialSlide.current)
          { 
              let left =pageTableLeft.current[pageIndexLookup.current[refInitialSlide.current]]
            //   console.log(refInitialSlide.current)
              let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+refInitialSlide.current+"\"]")
              let offsetScroll = parseFloat(pEle.getAttribute("scroll_offset"))
              let offSet = 0
              offSet =(refScroller.current.clientWidth - refDimensionCache.current[pEle.getAttribute("index")].width    ) * offsetScroll
            //   if(Globals.instance().ipad && useiPad.current)
            //     refScrollPos.current.style.left=(left - offSet)+'px'
            //   else{
                refScrollLeft.current=left - offSet
                refScroller.current.scrollLeft = refScrollLeft.current
            //   }
            // console.log(refDimensionCache.current[pEle.getAttribute("index")])
              Array.from(refScrollerContainer.current.childNodes).forEach((ele,index)=>{
                if(refScrollTable.current[index]){ refScrollTable.current[index].call(this,left - offSet,refScroller.current.clientWidth)}
                })   

            
          }else{
            Array.from(refScrollerContainer.current.childNodes).forEach((ele,index)=>{
                if(refScrollTable.current[index]){ refScrollTable.current[index].call(this,0,refScroller.current.clientWidth)}
                })   
          }
          onScrollEvent()
             
         
          { 
            let eleT =document.getElementById('narrative-transition')
              if(eleT){  eleT.remove() }
          }
            
          /* Removed because of module loading on deep linking 
          ? check if any issues
          if(refEle.current)refEle.current.style.display='none'
         
          */
          if(refEle.current)
                refEle.current.style.visibility='visible'
            
          var p = new Promise(resolve=>{
            gsap.to(refLoaderContainer.current.querySelector('.loader-container svg'),{duration:0.25,scale:0.75,ease:Power3.easeInOut,opacity:0})
            gsap.to(refLoaderContainer.current,{duration:0.5,ease:Power3.easeInOut,opacity:0,onComplete:(r)=>{
              r()
            },onCompleteParams:[resolve]})
          })
          await p ;
        
          setLoaded(true)
              
          refMenu.current.style.visibility='visible'
          
          if(refMenu.current ){ 
            let eleVid=refMenu.current.querySelector('video')
            if(eleVid){
                            eleVid.currentTime=0
                            eleVid.pause()
                        }
            }
           
          if(refScroller.current) refScroller.current.style.visibility='visible'

          if(props.history.location.pathname.indexOf('/narrative')===0){
            if(refEle.current){
                refEle.current.style.display='block'
                refEle.current.style['background-color']='var(--narrative-background)'
            }
            
            gsap.from(refEle.current,{opacity:0,delay:0, duration:1.25, ease:Power3.easeInOut,onComplete:()=>{
                if( new URLSearchParams(props.history.location.search).get('nav')==='closed')observeElements()
                
                if(refMenu.current) 
                {   
                    let eleVid=refMenu.current.querySelector('video')
                    if(eleVid){
                        setTimeout(()=>{
                            eleVid.currentTime=0
                            try{
                                let p=  eleVid.play()
                                if(p!==undefined){
                                    p.then(function() {
                                        // Automatic playback started!
                                      }).catch(function(error) {
                                        console.warn('MENU Video '+error)
                                      });
                                }
                            }catch(e){
                                console.error(e);
                            }
                            
                        
                    },3000)
                    }
                }
                window.dispatchEvent(new CustomEvent('hide-initial-bg'))
            }})
          }
          else{
           
            //create bands for animation
             let divAnimBand=document.createElement('div') 
             divAnimBand.classList.add(styles.animBandContainer ,'force3d')
             const createBand=(w,c,z)=>{
                var divEle=document.createElement('div')
                divEle.classList.add(styles.animBand)
                divEle.style.width=w
                divEle.style.background=c
                divAnimBand.append(divEle)
                let divCorner =document.createElement('div')
                divCorner.classList.add(styles.animBandCorner)
                divCorner.innerHTML='<svg viewBox="0 0 100 100" width="100%" height="100%"><path d="M 0,100 S100,100 100,0 L100,100Z" fill="'+c+'"></path></path></svg>'
                divCorner.style.zIndex=4
                divEle.append(divCorner)
                return divEle
            }
   
            refEle.current.parentElement.append(divAnimBand)
            createBand('8.7%','var(--primary-background)',3)
            createBand('15%','var(--primary)',2)
            let first =createBand('40%','#FFFFFF',1)
            first.style['border-bottom-right-radius']= 'calc(122px * var(--scaleiPad))';
    
            
            Array.from(divAnimBand.children).forEach((ele,i)=>{
                let w='5%'
                if(i===0)
                    w='1%'
                    else if(i===1)
                    w='5%'
                    else
                    w='14%'
                gsap.to(ele,{css:{width:w}, duration:1.2*1.25, ease:Power1.easeInOut})
            })
                divAnimBand.style.marginRight=getComputedStyle(divAnimBand).getPropertyValue('margin-right')
                if(refEle.current)refEle.current.style.display='block'
                if(refScroller.current)refScroller.current.style.display='none'
                
                gsap.to(divAnimBand,{css:{'left':'-120%'}, duration:1.2*1.25, ease:Power1.easeInOut,onComplete:()=>{
                    if(props.nid){
                        props.history.push('/narrative/'+props.nid)
                    }  
                    if(divAnimBand)divAnimBand.remove()
                    if(refScroller.current)refScroller.current.style.display='block'
                    setTimeout(()=>{ 
                    if(refMenu.current ){
                        let eleVid=refMenu.current.querySelector('video')
                        if(eleVid){
                            eleVid.currentTime=0
                            let p= eleVid.play()
                            p.then(function() {
                                // Automatic playback started!
                              }).catch(function(error) {
                                console.warn('MENU Video '+error)
                              });
                        }
                    }
                    },300)
                }})
                
            // gsap.set(refEle.current,{css:{'left':'150%'}})
                gsap.from(refEle.current,{css:{'left':'130%'}, duration:0.92*1.25, delay:0.05*1.25, ease:Power2.easeInOut,onComplete:(rel)=>{
                    rel.style['background-color']='var(--narrative-background)'
                },onCompleteParams:[refEle.current]})
          }
          
          setupServerComs(mods);

          Globals.instance().trackPage(NarrativeModel.heading+'-'+nid)


       }//if all pages loaded

   } //end on Page Load

//    useEffect(()=>{console.log('mod',currentModule)},[currentModule])

    
   /*  Listening in Server  Mode Only */
   
   function setupServerComs(mods){

    if(Globals.instance().serverApp){
        window.removeEventListener('server-event',refServerAppListener.current)
        refServerAppListener.current=(evt)=>{
            let detail = evt.detail
            if(!detail)return
            console.log(detail.event,detail.command)
            if(detail.event==='narrative-module'){ 
                console.log('narrative-module received', detail.command, mods)
                setCurrentModule(null)
                let params = new URLSearchParams(props.history.location.search)
                params.delete("slide")
                // if(refModule.current){
                //     console.log(detail.command!=="",detail.command)
                //     refModule.current.closeModule().then(()=>{
                //         if(detail.command!=="")
                //             selectModule({detail:{module:mods[Number(detail.command)]}})
                //     })
                // }else{
                    if(detail.command!=="") selectModule({detail:{module:mods[Number(detail.command)]}})
             
                // }
            }
            else if(detail.event==='narrative-menu-position'){ 
                observeElements()
                gsap.to(refMenu.current,{duration:1,ease:Power3.easeInOut,opacity:0,onComplete:(ele)=>{ele.style.display='none'},onCompleteParams:[refMenu.current]})
            }
            else if(detail.event==='narrative-menu-open-full'){ 
                setOpenMenuFull(true);
                console.log('narrative-menu-open-full, received2')
                refMenu.current.style.removeProperty('display');
                gsap.to(refMenu.current,{duration:1,ease:Power3.easeInOut,opacity:1, delay:0.2, onCompleteParams:[refMenu.current]})
                
            }
            else if(detail.event==='narrative-menu-link'){
                console.log('narrative-menu-link')
                // console.log(currentModule,refModule.current)
                let parts=detail.command.split(",")
                let pageid=parts[0]
                console.log(parts)
                let type = parts.length>1?parts[1]:'fade'
                // if(refModule.current){ refModule.current.closeModule()}
                let pEle = refScrollerContainer.current.querySelector(".Page[slideid=\""+pageid+"\"]")
                if(!pEle)return
                    let index =pEle.getAttribute("index")
                    scrollToPage(index,type)
            }
            else if(detail.event==='narrative-close-module'){
                // if(refModule.current){ refModule.current.closeModule()} console.log('narrative-close-module received')
                let params = new URLSearchParams(props.history.location.search)
                params.delete("module")
                params.delete("file")
                params.delete("group")
                params.delete("layer")
                params.delete("category")
                params.delete("links")
                props.history.push({pathname:props.history.location.pathname,search:params.toString()})
                setCurrentModule(null)
                
            }
            else if(detail.event==='narrative-scroll'){ 
                //let parts=detail.command.split(",")
                // console.log(detail.command)
                // console.log(dir,refScroller.current.scrollLeft)
                let pindex = Number(refCurrentPage.current)
                let ele = refScrollerContainer.current.querySelector(".Page[index=\""+pindex+"\"]")
                let left = refScroller.current.scrollLeft
                // let rEle = ele.getBoundingClientRect()
                let leftSide =ele.offsetLeft
                let rightSide= ele.offsetLeft+ele.clientWidth
                
                const screenWidth= refScroller.current.clientWidth
       
                if(detail.command==='left'){
                    if(leftSide < left){
                        refScrolltween.current = gsap.to(refScroller.current,{duration:1.5, scrollTo:{x:leftSide},ease:Power3.easeInOut,onComplete:(fscroll)=>{
                            fscroll()
                        },onCompleteParams:[onScrollEvent]})   
                    }
                }else{
                    if(left + screenWidth < rightSide){
                        refScrolltween.current = gsap.to(refScroller.current,{duration:1.5, scrollTo:{x:rightSide-screenWidth},ease:Power3.easeInOut,onComplete:(fscroll)=>{
                            fscroll()
                        },onCompleteParams:[onScrollEvent]})   
                    }
                }
        
            }
            
        }
        window.addEventListener('server-event', refServerAppListener.current)
    }
   }

   //initiate interaction observers
   function observeElements(){
       if(firstObservered) return
       if(refChildrenPages.current)
        refChildrenPages.current.forEach(ele=>{
            refInteraction.current.observe(ele)
            firstObservered=true
        })  
   }

   function onIntersect(idn){
       clearTimeout(timerSearchParams.current)
        timerSearchParams.current = setTimeout(()=>{

        let params = new URLSearchParams(props.history.location.search)
        params.set('slide',idn)
        // console.log(history.location.pathname,props.history)
        props.history.push({
            pathname:props.history.location.pathname,
            search:params.toString()
        })  
    },500)
   }
   
   function onFirstClose(){
       if(!Globals.instance().ipad)
        setShowNarrativeControls(true)
       
       if(loaded) observeElements()
   }
   const onWheel=(evt)=>{
        refScroller.current.scrollLeft += evt.deltaY/5;
        
        onScrollEvent()
        
   }
//    let timeTarget=0;
   let timeMouse=0;
   let xVel=0
   let overlapPnt = 0
   let underlapPnt = 0
   let initPage = 0
   let pEle = null
   let count = 0
   

    const onDown=(evt)=>{
        
        if(refScrolltween.current) refScrolltween.current.kill() 
        // console.log(evt)
        
        let posX=evt.touches?evt.touches[0].clientX:evt.clientX
        const screenWidth=refScroller.current.clientWidth
        refDown.current=true
        cancelAnimationFrame(timerScroller.current)
        refScrollVel.current=0
        // evt.preventDefault()
        if(refScrolltween.current)refScrolltween.current.kill() 
        

        initPage = Number(refCurrentPage.current)
        // console.log('initpage'+initPage)
        pEle = refScrollerContainer.current.querySelector(".Page[index=\""+initPage+"\"]")
        let rect =pEle.getBoundingClientRect()
        overlapPnt = pEle.offsetLeft + rect.width
        underlapPnt = pEle.offsetLeft 
  
        timeMouse = Date.now()
        xVel=0
        count=0
       
        
        if(!refScrollPos.current.style.left)refScrollPos.current.style.left='0px'
        // console.log('down '+scrollOffset,'-',refScrollPos.current)

        let listenerUp = ()=>{

            window.removeEventListener('mouseup',listenerUp)
            window.removeEventListener('mouseleave',listenerUp)
            window.removeEventListener('touchend',listenerUp)
            window.removeEventListener('touchcancel',listenerUp)
            refScroller.current.removeEventListener('mousemove',listenerMove)
            refScroller.current.removeEventListener('touchmove',listenerMove)
            refDown.current=false

            //either go to right of page or new page
            let maxOverlap =screenWidth *.30

            refScrollVel.current=0
            
            cancelAnimationFrame(timerScroller.current)
            
                //project a point
                let projectedX = (refScroller.current.scrollLeft  - xVel * 400) + screenWidth/2;
         
                    //get page to scroll to 
                let projectedPage = 0
                
                if(projectedX>refScroller.current.scrollWidth){
                    projectedX=refScroller.current.scrollWidth
                    projectedPage=PageModels.length -1  
                }
                

                Array.from(refScrollerContainer.current.children).forEach((ele,index)=>{
                    if( projectedX   >= ele.offsetLeft && projectedX <ele.offsetLeft+ele.clientWidth){
                        projectedPage=index
                    }
                })
                // console.log(initPage)
                if(projectedPage !==  initPage){
                   
                    if(projectedX< 0)
                        projectedPage=0
                    else if(projectedX > refScroller.current.scrollWidth){
                        projectedPage=PageModels.length -1    
                    }
                    let nextTo=Math.abs(projectedPage-initPage)===1
                    scrollToPage(projectedPage,'scroll',nextTo?1.25:2.5,nextTo?Power3.easeOut:Power3.easeOut)
                    refScroller.current.style.pointerEvents='all'
                    return
                }else{
                    
            let twPos = 0
            let rightSideOverlap= projectedX+(screenWidth/2)     - overlapPnt
            let leftSideOverlap=   underlapPnt - (projectedX-screenWidth/2 )
            let time=1.5
            let ease=Power3.easeInOut
            // console.log(leftSideOverlap,rightSideOverlap )
            // console.log(underlapPnt,overlapPnt,rect.width)
            if(rightSideOverlap > maxOverlap/2) {//right edge is past the right side of the slide
                twPos = overlapPnt
                // console.log('over '+projectedX)  
                ease=Math.abs(xVel)>0.85? Power2.easeOut:Power3.easeInOut
            }
            else if (rightSideOverlap > 0 ) //left edge is past the left side of the slide
                twPos = overlapPnt-screenWidth
            else if(leftSideOverlap > maxOverlap/2 ){
                twPos = underlapPnt-screenWidth
                ease=Math.abs(xVel)>0.85? Power2.easeOut:Power3.easeInOut
            }
            else if(leftSideOverlap > 0 ){
                twPos = underlapPnt
            }
            else{
                time=0.35   
                ease=Power2.easeOut
                twPos=projectedX-screenWidth/2
            }
         
                refScrolltween.current = gsap.to(refScroller.current,{duration:time, scrollTo:{x:twPos},ease:ease,onComplete:(fscroll)=>{
                    fscroll()
                },onCompleteParams:[onScrollEvent]})   
         
            }
        }

        let listenerMove = (evtMove)=>{
            // evtMove.preventDefault()
            // console.log('move'+Globals.instance().ipad)
            if(refDown.current){
                //get right edge of current page
                const mx=evtMove.touches?evtMove.touches[0].clientX:evtMove.clientX
                let diffX = (mx - posX)
                posX=mx
           
                // if(Globals.instance().ipad  && useiPad.current){
                //     refScrollPos.current.style.left =  (parseFloat(refScrollPos.current.style.left?refScrollPos.current.style.left:0) - diffX)+'px'
                //     if(parseFloat(refScrollPos.current.style.left )<0)refScrollPos.current.style.left ='0px'
                //     // console.log(parseFloat(refScrollPos.current.style.left) ,refScroller.current.scrollWidth- refScroller.current.clientWidth)
                //     if(parseFloat(refScrollPos.current.style.left) >refScroller.current.scrollWidth- refScroller.current.clientWidth)refScrollPos.current.style.left = (refScroller.current.scrollWidth-refScroller.current.clientWidth)+'px'
                // }
                // else{
                    refScroller.current.scrollLeft += -diffX
                    if(refScroller.current.scrollLeft<0)refScroller.current.scrollLeft=0
                    if(refScroller.current.scrollLeft>refScroller.current.scrollWidth)refScroller.current.scrollLeft=refScroller.current.scrollWidth
                    
                // }
                // console.log(refScroller.current.scrollLeft+' '+Globals.instance().ipad)
                let deltaTime = Date.now() - timeMouse
                let split=0.25 + (0.75 * (10-(count > 9?9:count))/10)
                
                if(deltaTime>0)
                    xVel= xVel*split + ((1 - split) * diffX/deltaTime)

                timeMouse=Date.now()

                
            }

          count++   
        }

        if(navigator.maxTouchPoints > 0)
          {
            window.addEventListener('touchend',listenerUp,{passive:!Globals.instance().ipad}) 
            window.addEventListener('touchcancel',listenerUp,{passive:!Globals.instance().ipad})
            refScroller.current.addEventListener('touchmove',listenerMove,{passive:!Globals.instance().ipad})
        
          }else{
            window.addEventListener('mouseup',listenerUp,{passive:true}) 
            window.addEventListener('mouseleave',listenerUp,{passive:true}) 
            refScroller.current.addEventListener('mousemove',listenerMove,{passive:true})
                  
          }
        
    }
    

    return  (
        <div className={`fullscreen narrative-holder`} >
            <div className={`fullscreen ${styles.narrativeContainer}`} ref={refEle}>
                <div className={`${styles.container} fullscreen hide-scroller  force3d ${loaded?'narrative_loaded':'false'}` } ref={refScroller}  >
                <div className={`${styles.scrollPosition}`} ref={refScrollPos} style={{left:'12px'}} ></div>     
                    <div className={`${styles.containerScroll} force3d`} ref={refScrollerContainer}>
                    {
                        PageModels && (
                            PageModels.map((val,index)=>{
                                return (
                                    <Page key={`page-${NarrativeModel.id}-${index}` } 
                                    id={`${NarrativeModel.id}-${index}` } 
                                    index={index} model={val}  
                                    onLoad={onPageLoad}
                                    interaction={refInteraction.current}
                                    theme={theme}
                                    onIntersect={onIntersect}
                                    setScrollCallback={setScrollTable}
                                    ></Page>
                                )
                            })   
                        )
                    }
                    </div>
                
                    
               
                </div>
                <div className={`${styles.topBarHolder}`} ref={refTopBar} loaded={loaded?'true':'false'}>
                    { loaded && PageModels && (
                            PageModels.map((_,index)=>{
                                return (
                                    <div className={`${styles.bar}`} index={index} 
                                    style={Globals.instance().getThemeStyle(['top-bar'],theme)}
                                    key={`nar-shortcut-${index}`} onClick={onClickTopBar} 
                                    > </div>
                                )
                            } )
                    )}     
                </div>
                {
                    <NarrativeMenu history={props.history} 
                    model={NarrativeModel} 
                    modules={modules} 
                    ref={refMenu} theme={theme} onFirstClose={onFirstClose} 
                    menuItems={menuItems}
                    setShowDrawControls={setShowDrawControls} 
                    setShowControls={setShowNarrativeControls}  
                    setShowMarkup={setShowMarkup}  
                    onScrollEvent={onScrollToEvent} 
                    onSelectModule={selectModule}
                    openMenuFull={openMenuFull}
                    setOpenMenuFull={setOpenMenuFull}
                    ></NarrativeMenu>
                }
                {  showNarrativeControls &&
                    <NarrativeControls 
                    scrollOffset={scrollOffset}
                    ref={refNarrativeControls} 
                    modules={modules} 
                    setShowControls={setShowNarrativeControls} 
                    model={NarrativeModel} 
                    menuItems={menuItems}
                    onScrollEvent={onScrollToEvent}  
                    onSelectModule={selectModule} 
                    key={'draw-controls-'+nid} >

                    </NarrativeControls>
                }
                
                
                
            </div>
            {   
                    
                currentModule && (
                    <ModuleContainer 
                        module={currentModule} 
                        history={props.history} 
                        theme={theme} 
                        nid={nid}
                        username={props.user && props.user.username}
                        category={props.user && props.user.category}
                        market={props.user && props.user.market}
                        setShowDrawControls={setShowDrawControls} 
                        setShowMarkup={setShowMarkup}  
                        closeModule={()=>{
                        let params = new URLSearchParams(props.history.location.search)
                        params.delete("module")
                        params.delete("file")
                        params.delete("group")
                        params.delete("layer")
                        params.delete("category")
                        params.delete("links")
                        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
                        setCurrentModule(null)
                        
                    }}></ModuleContainer>
                )
            }

            { 
                showMarkup &&
                    <Markup  setShowMarkup={setShowMarkup} nid={nid} history={props.history}></Markup>
            }

            { 
                showDrawControls &&
                    <DrawControls  setShowDrawControls={setShowDrawControls}  ></DrawControls>
            }

            { !loaded && (
                <div className={`fullscreen fcenter ${styles.loaderBG}`} ref={refLoaderContainer}>
                    <div className={`fullscreen fcenter`} style={{zIndex:1000}} >
                        <LoaderWheel status={status} ></LoaderWheel>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    const {narratives,themes,user} = state
    return { narratives:narratives,themes,user};
};

export default connect(mapStateToProps, null)(memo(Narrative,(prev,next)=>{
    return true 
}))