
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {getAnimationModel,animateParallax} from './CanvasAnimator'
import Globals from 'system/Globals'

function VideoComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [vidSrc,setVidSrc] = useState(0)
    
    let bLoaded=false
    const refEle = useRef()
    const refVid = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    // eslint-disable-next-line no-unused-vars
    const [vidStyle,setVidStyle] = useState({width:"100%",height:"100%",objectFit:props.fit?props.fit:'cover'})

    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
        }
       
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
               refVid.current.play()
        }
        else if(evt.detail.intersectionRatio <=0)
            refVid.current.pause()
    },[])

    useEffect(()=>{
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)

        let style ={position:'absolute',transform: 'translateZ(0)',willChange:'transform'}
        style.position='absolute'

        if(refFormat.current.style)
        {
            style = Object.assign(style,refFormat.current.style)
        }
        setInline(style)
        setVidSrc(getContentPath(refFormat.current.file))

        
        //set up animation Type
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            }
        }
        
        return ()=>{
        
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    

    async function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true
        }
        
    }
    
    return ( 
        <div style={inline} ref={refEle}  >
            <video style={vidStyle}  ref={refVid} loop  muted playsInline autoPlay src={vidSrc} type="video/mp4" onLoadedMetadata={onLoad}>
                <source src={vidSrc} type="video/mp4"></source>
            </video>
        </div>
        
    )

}

const VIDEO = memo( VideoComponent ,(prev,next)=>{ 
    return prev.imgSrc === next.imgSrc
})

export  {VIDEO}
