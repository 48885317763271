import './App.css';
import Main from './system/Main'
import React from 'react'
// import ControllerCursor from 'controller/ControllerCursor';
import Globals from 'system/Globals';

function App() {
  return (
    <div className={"App"}>
      
      <iframe id="analytics-frame" width="200" height="100" src="https://bpsv3-beta.s3.us-east-1.amazonaws.com/analytics/index.html" title="analytics"></iframe>
      
      <Main></Main>  
     
     {/* { Globals.instance().serverApp && ( <ControllerCursor></ControllerCursor> )} */}

    </div>
  );
}

export default App;
