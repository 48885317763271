
import React,{forwardRef,useEffect,useState, useRef} from 'react';
import {getContentPath} from 'system/AssetManager';
import ThreeVR from './threevr.js';
import { ReactCompareSlider, ReactCompareSliderImage, styleFitContainer } from 'react-compare-slider';
import Globals from 'system/Globals';
import gsap,{Power2} from 'gsap'
import mstyles from '../modules.module.css';
import galleryStyles from './gallery.module.css';
import LoaderWheel from 'system/components/LoaderWheel';
import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import {ReactComponent as ChevronThinButton} from 'assets/icons/caret_right.svg'
import {ReactComponent as TrayClose} from 'assets/icons/tray_close.svg'
import {ReactComponent as PlayButton} from 'assets/icons/play.svg'
import {ReactComponent as VRButton} from 'assets/icons/vr_white.svg'
import {ReactComponent as IconHamburger} from 'assets/icons/burger.svg';

import ToolsMenu from 'system/components/ToolsMenu'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

const GalleryModule = forwardRef((props, ref)=>{

    const refServerAppListener =  useRef();
    const refActiveFile = useRef();
    const refGalleryMain = useRef();
    const refThumbnails = useRef();
    const [loaded,setLoaded] = useState(false);
    const [status,setStatus] = useState('initializing');
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeCategory, setActiveCategory] = useState(0);
    const [activeCategoryHeading, setActiveCategoryHeading] = useState('All Images');
    const [activeFile, setActiveFile] = useState(0);
    const [navOpen, setNavOpen] = useState(true);
    const [thumbnailTransition, setThumbnailTransition] = useState(true);
    const [backgroundColor, setBackgroundColor] = useState('#6a6a6a');
    const [backgroundImage, setBackgroundImage] = useState(null);
    const [inTransition, setInTransition] = useState(false);
    const [showSwiper, setShowSwiper] = useState(false);
    const [activeFileType, setActiveFileType] = useState(null);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [slideNum,setSlideNum] = useState(0);
    const [dimControls, setDimControls] = useState(false);
    const [galleryTimeout, setGalleryTimeout] = useState(null);
    const [canTouchMove, setCanTouchMove] = useState(true);
    const [activeVR, setActiveVR] = useState(null);
    const [noSwiping, setNoSwiping] = useState(false);
    const [loadVR, setLoadVR] = useState(false);
    const [vrFile, setVRFile] = useState(null);
    const [paramsProcessed, setParamsProcessed] = useState(false);
    const [vidIsPlaying, setVidIsPlaying] = useState(false);
    const [showLanding, setShowLanding] = useState(true);
    const [showThumbnails, setShowThumbnails] = useState(false);
    const [showSliderNav,setShowSliderNav] = useState(true);
    

    const [vrZoom, setVrZoom] = useState(false);

    function removeParamUrl(name){
        let params = new URLSearchParams(props.history.location.search);
        params.delete(name)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
    }

    function onClickClose(evt){
        props.closeModule()
        setLoadVR(false);
        removeParamUrl('file');
    }   

    // function startGalleryTimeout(){
    //     setDimControls(false);
    //     var dimTimeout = setTimeout(() => {
    //         setDimControls(true);
    //     }, 1000 * 1);
    //     setGalleryTimeout(dimTimeout);
    // }
    // function setActiveFileId(elem){
    //     var elem = document.getElementsByClassName('swiper-slide-active')[0];
    //     var elemId = elem.getElementsByTagName('div')[0].dataset.fileid;
    //     if (elemId) {
    //         setActiveFile(elemId);
    //         console.log('elemId', elemId);
    //     }
    // }


    function convertColors(elemId) {
        var elem = document.getElementById(elemId);
        if (elem) {
            const style = getComputedStyle(elem)
            const backgroundColor = style.backgroundColor
            var newBackgroundColor = backgroundColor.replace(')', ', 0.85)').replace('rgb', 'rgba');
            elem.style.backgroundColor = newBackgroundColor;
        }
        
    }

    useEffect(()=>{ 
        removeParamUrl('slide')
        console.log("gallery module starting", props);
        ref.current.style.display='block';
        gsap.from(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
        }});

        (async ()=>{
            try { 
                setStatus('Loading JSON')
                if (props.model.files) {
                    setFiles(props.model.files);
                    if (props.model.files.length > 0) {
                        setActiveFile(props.model.files[0].id)
                    }
                    
                    if (props.model.backgroundColor) {
                        setBackgroundColor(props.model.backgroundColor);
                    }
                    setCategories(props.model.categories);
                }
                setStatus('Setting Gallery')
           
            }catch(err){
          
            }
        })();

        // Globals.instance().getFormat

        // startGalleryTimeout();
        if (props.standard !== false) {
            convertColors('rightNav');
            convertColors('rightNavMenu');
        }
        
    },[]);

    function onVrZoomIn() {
        console.log('onVrZoomIn');
        setVrZoom(-20)
    }

    function onVrZoomOut() {
        console.log('onVrZoomOut');
        setVrZoom(20)
    }

    function onVidBack() {
        console.log('onVidBack');
        var elem = document.getElementById("file_" + activeFile);
        elem.currentTime = elem.currentTime - 15;
    }

    function onVidForward() {
        console.log('onVidForward');
        var elem = document.getElementById("file_" + activeFile);
        elem.currentTime = elem.currentTime + 15;
    }

    function onVidPause() {
        console.log('onVidPause');
        let videos = document.getElementsByTagName('video');
        let vidArray = [].slice.call(videos);
        for (let i = 0; i < vidArray.length; i++) {
            vidArray[i].pause();
        }
    }

    function onVidPlay(activeFileId) {
        console.log('onVidPlay', activeFileId);
        var elem = document.getElementById("file_" + activeFileId);
        if (elem) {
            elem.play();
        }
        
    }

    function onClickCloseVR() {
        setActiveVR(null);
        setVRFile(null);
        setLoadVR(false);
        
    }

    useEffect(() => {
        if (props.model.files) {
            setFiles(props.model.files);
            if (props.model.backgroundColor) {
                setBackgroundColor(props.model.backgroundColor);
            }
            if (props.model.background_image) {
                setBackgroundImage(props.model.background_image)
            }
            
            setCategories(props.model.categories);
        }
    }, [props.model.files])

    useEffect(()=>{ 
        setLoaded(true) 
        if (files.length > 0){
            // setActiveFile(files[0].id);
            // console.log('files', files, files[0].id);
            
        }
    },[files]);

    useEffect(()=>{ 
        // console.log('GALLERY')
        if (swiperInstance && loaded) {
            const swiper  = swiperInstance;
            swiper.update();
            processParams();
            var videos = document.getElementsByTagName('video');
            var vidArray = [].slice.call(videos);
                                           
            for (let i = 0; i < vidArray.length; i++) {
                vidArray[i].currentTime = vidArray[i].currentTime + 1;
            }
        } 
        
    },[loaded, swiperInstance]);

    useEffect(()=>{ 
        if (activeFile) {
            console.log('activeFile', activeFile);
            refActiveFile.current = activeFile;
            var file = files.filter(item => (item.id === activeFile))[0];
            
            if (file) {
                setActiveFileType(file.fileType);
                console.log('file.fileType set', file.fileType);
            } else {
                setActiveFileType('image');
            }

            if (Globals.instance().serverApp) {
                window.removeEventListener('server-event', refServerAppListener.current)
                const serverAppListener = (evt)=>{
                    let detail = evt.detail
                    if(!detail)return
                    if(detail.event==='module-gallery-select-file'){ 
                        
                        var file = files.filter(item => (item.id === parseInt(detail.command)))[0];
                        console.log('module-gallery-select-file', detail.command, file, files)
                        handleThumbnailClick(file.id, file.fileType, file.file);
                        refActiveFile.current = file.id;
                        setShowLanding(false);
                    }
                    if(detail.event==='module-gallery-select-category'){   
                        setActiveCategory(parseInt(detail.command));
                        setShowLanding(false);
                    }
                    if(detail.event==='module-gallery-command'){ 
                        
                       switch(detail.command) {
                            case "prev":
                                setTimeout(() => {
                                    handleGalleryPrev(refActiveFile.current);
                                }, 300);
                                
                                break;
                            case "next":
                                setTimeout(() => {
                                    handleGalleryNext(refActiveFile.current);
                                }, 300);
                                
                                break;
                            case "vrzoomin":
                                onVrZoomIn();
                                break;
                            case "vrzoomout":
                                onVrZoomOut();
                                break;
                            case "vidback":
                                onVidBack();
                                break; 
                            case "vidforward":
                                onVidForward();
                                break;
                            case "vidpause":
                                onVidPause();
                                break;
                            case "vidplay":
                                onVidPlay(refActiveFile.current);
                                
                                break;
                            case "closeVr":
                                onClickCloseVR();
                                break;
                            default:
                                {}
                       }
                    }
                }
                refServerAppListener.current = serverAppListener
                window.addEventListener('server-event', refServerAppListener.current)
                
            }
        } 
        
    },[activeFile]);
    
    useEffect(() => {
        if (activeFileType) {
            if (activeFileType === 'compare') {
                setNoSwiping(true);
                console.log('no swiping', activeFileType);
            } else {
                setNoSwiping(false);
                console.log('swiping', activeFileType);
            }
        }
    }, [activeFileType])
    

    useEffect(()=>{ 
        setTimeout(() => {
            setThumbnailTransition(false); 
            setShowThumbnails(true);
        }, 500);
    },[activeCategory, swiperInstance]);

    useEffect(() => {
        if (swiperInstance) {
            if (noSwiping === true) {
                var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
                swiperElem.classList.add('swiper-no-swiping');
            } else {
                var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
                swiperElem.classList.remove('swiper-no-swiping');
            }
        }  
    }, [noSwiping])

    useEffect(() => {
        if (slideNum && files) {
            var sliderFiles = files.filter((file) => {
                if (activeCategory === 0) {
                    return file
                } else {
                    return (file.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            console.log('swiper change', sliderFiles[slideNum-1]);
            if (sliderFiles[slideNum-1]) {
                setActiveFile(sliderFiles[slideNum-1].id);
            }
        }
    }, [slideNum])

    function processParams(){
        console.log("processParams started", props.history.location.search);
        let params = new URLSearchParams(props.history.location.search);
        let module = params.get('module');
        let moduleName = module.split('-');
        let file0 = params.get('file');
        let cat0 = params.get('category');
        let thumbs = params.get('thumbs');
        
        if ((file0) && (moduleName[0] === ("gallery"))){
            setShowLanding(false);
            if (cat0) {
                var activeCategory = parseInt(cat0);
                var cat = categories.filter(item => ((item.id === parseInt(cat0))))[0];  
                if (cat) {
                    setActiveCategoryHeading(cat.short_name);
                }
                
            } else {
                var activeCategory = 0;
                
            }
            setActiveCategory(activeCategory);

            var sliderFiles = files.filter((file1) => {
                if (activeCategory === 0) {
                    return file1
                } else {
                    return (file1.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(parseInt(file0));
           
            setActiveFile(parseInt(file0));
            setSlideNum(index);
            setShowSwiper(true);
            const swiper = swiperInstance;
            swiper.update();
            swiper.slideTo(index, 0);

            console.log("processParams 0", index, file0, activeCategory, sliderFiles);

        } else {
            var file = files[0];
            if (moduleName[0] === ("gallery")) {
                setActiveFile(file.id);
                updateParamUrl("file", file.id);

                console.log("processParams 1", index, file0, activeCategory, sliderFiles, file);
            }
            
        }

        if (thumbs && (thumbs === "false")) {
            setNavOpen(false);
            setShowSliderNav(false);
        } else {
            setNavOpen(true);
            setShowSliderNav(true);
        }
        

        

    }

    function updateParamUrl(name, objIDs){
        let params = new URLSearchParams(props.history.location.search);
        params.set(name, objIDs)
        props.history.replace({ pathname:props.history.location.pathname, search:decodeURIComponent(params.toString())})
    }

    function handleMenu(){
        setMenuOpen(prevCheck => !prevCheck);
        if (menuOpen) {
            gsap.to(refThumbnails.current, {opacity: 1, duration: 0.2});
        } else {
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0.2});
        }
       
    }


    function handleThumbnailClick(objID, fileType, filePath){
        document.querySelectorAll('video').forEach(vid => vid.pause());
        setActiveFile(objID);
        // console.log("objid", objID, fileType, filePath);
        var sliderFiles = files
        .filter((file) => {
            if (activeCategory === 0) {
                return file
            } else {
                return (file.categories.includes(activeCategory));
            }
        })
        .sort((a, b) => a.ordering - b.ordering);

        if (activeCategory) {
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(objID);
        } else {
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(objID);
        }

        if (activeVR) {
            var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
            swiperElem.classList.remove('swiper-no-swiping');
            setActiveVR(null);
        }

        if (fileType === 'compare') {
           setNoSwiping(true);
        } else {
            setNoSwiping(false);
        }
        
        setSlideNum(index);
        setShowSwiper(true);
        const swiper = swiperInstance;
        swiper.update();
        swiper.slideTo(index, 0);

        updateParamUrl('file', objID)
        
    }

    function onClickHide() {
        if (navOpen === true) {
            setNavOpen(false)
        } else {
            setNavOpen(true);
        }
    }

    function handleCategoryClick(objID, heading, anim) {
        console.log('handleCategoryClick', objID, heading);
        setShowLanding(false);
        setThumbnailTransition(true);
        setMenuOpen(false);
        if (!anim) {
            gsap.to(refGalleryMain.current, {opacity: 0, duration: 0});
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0});
        } else {
            gsap.to(refGalleryMain.current, {opacity: 0, duration: 0.3});
            gsap.to(refThumbnails.current, {opacity: 0, duration: 0.3}, "-=0.3");
        } 
        
        setTimeout(() => {
            setActiveCategory(objID);
            setActiveCategoryHeading(heading);
            updateParamUrl('category', objID);
            const swiper = swiperInstance;
            setSlideNum(0);
            setShowSwiper(true);

            var sliderFiles = files.filter((file1) => { return (file1.categories.includes(objID)) })
            .sort((a, b) => a.ordering - b.ordering);
            if ((sliderFiles[0])&&(objID !== 0)) {
            setActiveFile(sliderFiles[0].id);
            } else {
            setActiveFile(files[0].id);
            }
            
        //    console.log("handleCategoryClick", objID, sliderFiles)
            swiper.update();
            swiper.slideTo(0, 0);
            gsap.to(refGalleryMain.current, {opacity: 1, duration: 0.3});
            gsap.to(refThumbnails.current, {opacity: 1, duration: 0.3});

        }, 200);
        // if (objID !== activeCategory) {
            
        // }
    }

    function handleCategoryThumbnailClick(objID, heading) {
        handleCategoryClick(objID, heading, false);
    }

    function onSwiper(swiper){
        setSwiperInstance(swiper);
        swiper.slideTo(slideNum, 0);
        // console.log('swiper init');
        swiper.on('sliderMove', function() { 
            setInTransition(true);            
        });

        swiper.on('touchEnd', function() { 
            setInTransition(false); 
        });
        
      }

    function onSlideChange(swiper){
        setSlideNum(swiper.realIndex + 1);
        // setActiveFile(sliderFiles[swiper.realIndex + 1].id)
        
        swiper.on('slideChangeTransitionEnd', function() { 
            setInTransition(false);
            // setActiveFileId();
            var actives = document.querySelectorAll('.activeSlide > div');
            actives.forEach((active, i) => {
                // console.log('change', active.dataset)
                setActiveFileType(active.dataset.filetype);
               
            });
            // console.log('this slider', active)
        });
    
       
    }
    
    function handleGalleryNext(activeFileId){
        if (activeFileId) {
            var sliderFiles = files
            .filter((file) => {
                if (activeCategory === 0) {
                    return file
                } else {
                    return (file.categories.includes(activeCategory));
                }
            })
            .sort((a, b) => a.ordering - b.ordering);
            var index = sliderFiles.map(function(x) {return x.id; }).indexOf(activeFileId);

            var next = sliderFiles[index+1];
            if (next) {
                console.log('handleGalleryNext', activeFileId, sliderFiles, activeCategory, index, next);
                setActiveFile(next.id);
                swiperInstance.slideNext(500);
               
            }
        }
        // console.log("handleGalleryNext", activeCategory, next)
    }
    
    function handleGalleryPrev(activeFileId){
        // document.querySelectorAll('video').forEach(vid => vid.pause());
        var sliderFiles = files
        .filter((file) => {
            if (activeCategory === 0) {
                return file
            } else {
                return (file.categories.includes(activeCategory));
            }
        })
        .sort((a, b) => a.ordering - b.ordering);
        var index = sliderFiles.map(function(x) {return x.id; }).indexOf(activeFileId);
        console.log(sliderFiles, activeFileId);
        if (index) {
           if (sliderFiles[index-1]) {
                var next = sliderFiles[index-1].id;
                setActiveFile(next);
                swiperInstance.slidePrev(500);
           }
            
        }
        
    }

    function handleFullScreen(){
        var elem = document.getElementById("file_" + activeFile);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { 
            elem.msRequestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
            elem.webkitEnterFullscreen(); 
         }
        elem.play();
        
        elem.addEventListener("fullscreenchange", function () {
            if (document.fullscreenElement) {
            } else {
                elem.pause();
            }
        }, false);
        elem.addEventListener("mozfullscreenchange", function () {
            if (document.mozfullscreenchange) {
            } else {
                elem.pause();
            }
        }, false);
        elem.addEventListener("webkitfullscreenchange", function () {
            if (document.webkitfullscreenchange) {
            } else {
                elem.pause();
            }
        }, false);

    }
    
    function handleStartVR(objID) {
        var swiperElem = document.getElementsByClassName('swiper-wrapper')[0];
        swiperElem.classList.add('swiper-no-swiping');
        setActiveVR(objID);
        var vrfile = files.filter(item => ((item.id === objID)))[0];
        setVRFile(vrfile.file);
        setLoadVR(true);
    }
    
   
    
    function handleComparePadding(fileId) {
        return 0
        
    }

    

    return (
        <div className={`fullscreen fcenter ${mstyles.moduleHolder} ${(vrFile)?galleryStyles.vr:""}` } ref={ref}>
            {(!loaded)?(<div className={`fullscreen fcenter`}>
            <div>
                <LoaderWheel status={status}></LoaderWheel>
            </div>
             </div>): null }

            <section id="galleryContainer" >
        
            {(showLanding && swiperInstance && categories && (categories.length > 1)) ? 
                (<div className={`${galleryStyles.landingContainer}`} style={{background: backgroundColor}}>
                    
                    <div className={`${galleryStyles.letterbox}`}>
                        <div className={`${galleryStyles.heading}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'], props.theme))}>
                              {props.model.title} Gallery
                        </div>
                        <div className={`${galleryStyles.itemsContainer}`}>
                            {categories.map((category, i) => (
                            <div 
                                className={`${galleryStyles.itemContainer} ${(activeCategory === category.id)?(galleryStyles.choiceOn):("")}`}
                                key={'gallery_thumb_' + i}
                                onClick={() => handleCategoryThumbnailClick(category.id, category.short_name)}
                            >
                                <img src={getContentPath(category.thumbnail)} alt=""></img>
                                <div className={`${galleryStyles.caption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>{category.name}</div>
                              
                            </div>
                            ))}
                        </div>
                    </div>
                    {( backgroundImage && <img src={getContentPath(backgroundImage)} alt="" className={`${galleryStyles.backgroundImage}`}/>)}
                </div>)
                : null}
                <React.Fragment>
                    <div id="galleryMain" className={galleryStyles.frame} style={{background: backgroundColor}}>
            
                        {(swiperInstance && !Globals.instance().serverApp)? (
                            <div className={`${galleryStyles.galleryControlsContainer} ${(((files.length === 1)&&(activeFileType==="image"))||(!showSliderNav))?galleryStyles.controlsOff:''}`}>
                                <div className={`${galleryStyles.galleryControls} ${(activeFileType === "video")?(galleryStyles.galleryControlsWide):""}`}>
                                    
                                    <div onClick={() => handleGalleryPrev(activeFile)} className={`${galleryStyles.galleryPrev}`}>
                                        <ChevronThinButton></ChevronThinButton>
                                    </div>
                               
                                {(activeFileType === "video")? <div onClick={() => handleFullScreen()} className={`${galleryStyles.galleryFullScreen}`}><PlayButton></PlayButton></div> : null } 
                                    <div onClick={() => handleGalleryNext(activeFile)} className={`${galleryStyles.galleryNext}`}>
                                        <ChevronThinButton></ChevronThinButton>
                                    </div>
                                </div>
                            </div>) : null}
                            <span 
                            ref={refGalleryMain} 
                            >
                            <Swiper 
                                preloadImages={true} 
                                longSwipes={false} 
                                // allowTouchMove={false}
                                onSwiper={(swiper) => onSwiper(swiper)} 
                                onSlideChange={(swiper) => onSlideChange(swiper)} 
                                loop={false}
                                
                            >
                            {
                            files
                            .sort((a, b) => a.ordering - b.ordering)
                            .map((file, i) => (
                        
                                <SwiperSlide 
                                    key={'sw-slide-'+i}
                                    className={`${(file.categories.includes(activeCategory)||(activeCategory === 0))?(galleryStyles.swiperSlideOn):(galleryStyles.swiperSlideOff)} ${(activeFile === file.id)?("activeSlide"):""}`}
                                >
                                    <div 
                                        className={`${galleryStyles.swiperSlide} ${(file.fileType === 'vr')?('vrSlide'):("")} ${(file.fileType === 'compare')?(galleryStyles.compareSlide):("")}`}
                                        data-filetype={(file.fileType === 'vr')?(file.fileType):(file.fileType)} 
                                        data-file={file.file} 
                                        data-fileid={file.id}
                                        // data-target={(file.fileType === 'vr')?('file_vr_' + file.id):""}
                                        data-target={"vrContainer"}
                                    >
                                        {(file.fileType === 'image') ? <img src={getContentPath(file.file)} className={`${galleryStyles.galleryImage}`} alt="" /> : ""}
                                        {(file.fileType === 'video') ? (
                                            (file.loop === true)?
                                            (<video id={"file_" + file.id} width="100%" height="100%" className={`${galleryStyles.galleryVideo}`} loop disablePictureInPicture allowFullScreen controlsList="nodownload">
                                                <source src={getContentPath(file.file)} type="video/mp4" />
                                            </video>):
                                            (<video id={"file_" + file.id} width="100%" height="100%" className={`${galleryStyles.galleryVideo}`} disablePictureInPicture allowFullScreen controlsList="nodownload">
                                                <source src={getContentPath(file.file)} type="video/mp4" />
                                            </video>)
                                        ) : null}
                                        {(file.fileType === 'vr') ? (
                                        <div className={`${galleryStyles.galleryImage}`}>
                                        {(activeVR)?(null):( 
                                            <div>
                                                <div className={`${galleryStyles.galleryGo}`} onClick={(e) => handleStartVR(file.id)}><VRButton></VRButton>Start VR</div>
                                                <img src={getContentPath(file.thumbnail)} className={`${galleryStyles.galleryImageVR}`} alt="" />
                                            </div>
                                        )}
                                        </div>
                                        ) : null}
                                        {(file.fileType === 'external') ? (
                                            <iframe aria-label="matterport" id={"file_" + file.id} title={'gallery_iFrame_' + i} src={file.external_src} frameborder="0" sandbox="allow-same-origin allow-scripts allow-popups allow-forms" allowfullscreen allow="xr-spatial-tracking" className={`${galleryStyles.galleryiFrame}`}></iframe> 
                                            
                                        ) : null}
            
                                        {(file.fileType === 'compare') ? 
                                        
                                        <ReactCompareSlider
                                            className={`${galleryStyles.galleryImage}`}  
                                            position={25}
                                            portrait={file.vertical_compare}
                                            boundsPadding={handleComparePadding("compareFile_" + file.id)}
                                            handle={
                                                <div className={(file.vertical_compare)?(`${galleryStyles.galleryHandle} ${galleryStyles.galleryHandleVertical}`):(`${galleryStyles.galleryHandle}`) }>
                                                <div className={`${galleryStyles.galleryHandleButton}`}>
                                                <div className={`${galleryStyles.galleryControlsContainer}`}>
                                                <div className={`${galleryStyles.galleryControls}`}>
                                                    
                                                    <div className={`${galleryStyles.galleryPrev}`}>
                                                        <ChevronThinButton></ChevronThinButton>
                                                    </div>
                                                    <div className={`${galleryStyles.galleryNext}`}>
                                                        <ChevronThinButton></ChevronThinButton>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                                </div>
                                                <span className={`${galleryStyles.galleryHandleLine}`}></span>
                                                </div>
                                            }
                                            itemOne={
                                                <ReactCompareSliderImage 
                                                src={getContentPath(file.file)}
                                                id={"compareFile_" + file.id}
                                                style={{
                                                    ...styleFitContainer({
                                                        objectFit: 'contain',
                                                        objectPosition: 'center'
                                                    })
                                                }}
                                                alt="" />
                                            }
                                            itemTwo={
                                                <ReactCompareSliderImage 
                                                src={getContentPath(file.file_compare)} 
                                                style={{
                                                    ...styleFitContainer({
                                                        objectFit: 'contain',
                                                        objectPosition: 'center',
                                                    })
                                                }}
                                                alt="Image two" />
                                            }
                                            />
                                        
                                        : ""}
                        
                                    </div>
                                </SwiperSlide>
                            )
                            ) 
                        }
                            
                            </Swiper>      
                            </span>
                        
                        </div>
                        
                        {/* Gallery Nav */}
                        {((props.standard === true)&&(!Globals.instance().serverApp)) ? (
            
                        <div id="rightNav" className={`${galleryStyles.rightNavMenu} ${(menuOpen)?(galleryStyles.menuOn):("") } ${(navOpen)?(galleryStyles.navOpen):("")} ${(categories.length === 0)?galleryStyles.headingOff:''}`} style={Globals.instance().getThemeStyle(['module-drawer-bg'],props.model.theme)}>
            
                            <nav id="rightNavMenu" 
                                className={`${galleryStyles.rightNavMenuNav}`}
                                >
                                
                                {(!menuOpen ? <div 
                                    className={`${galleryStyles.navHeading}`}
                                    style={Globals.instance().getThemeStyle(['module-heading-bg'],props.model.theme)}
                                    onClick={() => (categories.length > 1)? handleMenu() : console.log('no categories')}
                                    >
                                    <span
                                    className={`${galleryStyles.moduleHeading}`}
                                    style={Globals.instance().getThemeStyle(['module-heading'],props.model.theme)}
                                    >
                                
                                        {activeCategoryHeading}
            
                                        {(categories.length > 1) ? <div className={`round-btn ${galleryStyles.navBurger}`}>
                                            <IconHamburger></IconHamburger>
                                        </div> : null}
                                        
                                    </span>
                                </div> : <div>{categories.map((category, i) => (
                                    <div 
                                        className={`${galleryStyles.dropdownChoice} ${(activeCategory === category.id)?(galleryStyles.choiceOn):("")}`}
                                        key={'gallery_category_' + i}
                                        style={Globals.instance().getThemeStyle(['module-dropdown'],props.theme)}
                                        onClick={() => handleCategoryClick(category.id, category.short_name, true)}
                                    >
                                    {category.name}
                                
                                    </div>
                                ))}</div>)}
            
                               
                                
                            </nav>
            
                            {(showThumbnails && 
                            <div className={`${galleryStyles.galleryThumbnails} ${(menuOpen)?(galleryStyles.off):("")}`} ref={refThumbnails}>
                            {files
                                .sort((a, b) => a.ordering - b.ordering)
                                .map((file, i) => (
                                <div 
                                    onClick={() => handleThumbnailClick(file.id, file.fileType, file.file)}
                                    key={'gallery_file_' + i} 
                                    className={`${galleryStyles.galleryThumbnail} ${(thumbnailTransition === false)?(galleryStyles.ready):(galleryStyles.fade)} ${(activeFile)?((activeFile === file.id) ? (galleryStyles.galleryThumbnailActive): (galleryStyles.galleryThumbnailInactive) ): ""} ${(file.categories.includes(activeCategory)||(activeCategory === 0))? (galleryStyles.galleryThumbnailOn): (galleryStyles.galleryThumbnailOff)}`}
                                >
                                <img src={getContentPath(file.thumbnail)} className={`${galleryStyles.thumbnail}`} alt="" />
                                {(file.caption) ? 
                                    <div 
                                    className={`${galleryStyles.caption}`}
                                    style={Object.assign({}, Globals.instance().getThemeStyle(['module-heading'],props.theme))}
                                    >{file.caption}</div> 
                                    : null}
                                </div>
                            ))}
                            </div>)}
                        </div>
            
                    ):(null)}
                        
                    {((props.standard === true)&&(!Globals.instance().serverApp)) ? (
            
                    <div className={`${galleryStyles.rightNavBtn2} ${(navOpen)?(galleryStyles.navOpen):("")}`}>
                        <div className={`round-btn`} onClick={() => onClickHide()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <TrayClose></TrayClose>
                        </div>
                    </div>
            
                    ):(null)}
            
            
                    {(loadVR) ? ( <div id="vrPopup" className={`${galleryStyles.vrPopup} ${(activeVR)?(galleryStyles.vrPopupOn):(galleryStyles.vrPopupOff)}`}>
            
                        {(!Globals.instance().serverApp)? (<div className={`${mstyles.closeBtnContainer} ${galleryStyles.vrCloseBtnContainer}`} ><div id="vrCloseBtn" className={`round-btn`} onClick={(e) => onClickCloseVR()} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}><CloseButton></CloseButton></div></div>):null}
                        <ThreeVR 
                            file={vrFile} 
                            load={(loadVR)?true:false}
                            vrZoom={vrZoom}
                            setVrZoom={setVrZoom}
                        >
                        </ThreeVR>
                        
                    </div>) : null}
            
                </React.Fragment>

            </section>
                
            <div className={`${mstyles.closeBtnContainer} ${galleryStyles.closeBtnContainer}`} >
         
                <ToolsMenu theme={props.theme} 
                setShowMarkup={props.setShowMarkup}
                setShowDrawControls={props.setShowDrawControls} 
                model={props.model}  
                location={props.history.location}
                key={`tools-menu`}></ToolsMenu>
                
                <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                    <CloseButton></CloseButton>
                </div>
            </div>
            
        </div>
        
    )

})

export default GalleryModule



