// import {fetchCMSJSON} from '../../system/AssetManager'

export const SET_USER = "SET_USER";
export const SET_GUEST_USER = "SET_GUEST_USER";
export const SYNCH_CORE ="SYNCH_CORE"
export const SET_NARRATIVES ='SET_NARRATIVES'
export const SET_THEMES ='SET_THEMES'
export const SET_NARRATIVE_TREE ='SET_NARRATIVE_TREE'
export const SET_INITIALIZED ='SET_INITIALIZED'

export function setInitialized(payload){ 
    return {type : SET_INITIALIZED , payload}
}

export function setNarratives(payload){ return {type : SET_NARRATIVES , payload }}

export function setThemes(payload){ 

    // let count =0;
    // const total = payload.length
    let themes={}
    // console.log(payload)
    return {type : SET_THEMES , payload: new Promise( async (resolve,reject)=>{
        payload.forEach((t)=>{
            
            themes['t'+t.id]=t
            let title = ""
            if(t.name)title=t.name
            if(t.title)title=t.title
            
            if(title.toLowerCase()==='default'){
                themes['default']=Object.assign({},t)
            }

            resolve(themes)
            // fetchCMSJSON(t.json).then((result)=>{
            //     count++;
            //     themes[result.name]=result
            //     if(count>=total){
            //         resolve(themes)
            //     }
            // })
        })
    })
}}

export function setNarrativeTree(payload){
    //set tree idenitifers before saving tree object
    const recurseSetId=(branch,depth)=>{
        if (branch) {
            branch.forEach((val,index)=>{
                var d=depth+1
                if(val.narratives)
                    val.narratives.forEach((nar,index)=>{
                        nar.treeId='n-'+d+'-'+index
                    })
    
                val.treeId='b-'+d+'-'+index
                if(val.children){
                    recurseSetId(val.children,d)
                }
            })
        }
       
    }
    recurseSetId(payload,0)
    return {type : SET_NARRATIVE_TREE , payload} 
}

export function setUser(payload) { console.log('set user');return { type: SET_USER, payload}};
export function setGuestUser() { return { type:SET_GUEST_USER, payload:{ }}};


export function synchCore(payload) { return { type: SYNCH_CORE, payload }};

