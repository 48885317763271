

import React,{useState,useEffect,useRef} from 'react'
import styles from './stack.module.scss'
import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'
import ReactDOM from 'react-dom'
import gsap,{Power3} from 'gsap'
// import Globals from 'system/Globals'

const PortalContainer =  ({ children, className = 'root-portal', el = 'div' , onclick}) => {
    const [container] = React.useState(() => {
      return document.createElement(el);// This will be executed only on the initial render
    });
  
    React.useEffect(() => {
      container.classList.add(className,'fullscreen')
     
      document.body.appendChild(container)
      return () => {
        document.body.removeChild(container)
      }
    }, [])
  
    return ReactDOM.createPortal(children, container)
  }
  

const StackDropUp = (props)=>{

    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(props.selectedItem?props.selectItem:0)
    const [iconStyle,setIconStyle]=useState({})
    const refContainer = useRef()
    const refEle = useRef()

    
    function onToggle(){
        setIsOpen(!isOpen)
       
    }

    function onSelectItem(evt){
        let index =evt.currentTarget.getAttribute("index")
        // setSelectedItem(index)
        setIsOpen(false)
        if(props.onSelect)props.onSelect(index)
    }
    
  

    useEffect(()=>{

        const copyVar=(s,toEle,str)=>{
            toEle.style.setProperty(str,s.getPropertyValue(str))
        }

        if(isOpen){
            let s = getComputedStyle(refEle.current)
            copyVar(s,refContainer.current,'--color')
            copyVar(s,refContainer.current,'--primary')
            copyVar(s,refContainer.current,'--primary-accent')
            copyVar(s,refContainer.current,'--primary-background')
            copyVar(s,refContainer.current,'--secondary')
            copyVar(s,refContainer.current,'--secondary-background')
            
            let rect= refEle.current.getBoundingClientRect();
            refContainer.current.style.bottom='auto'
            let bottom =window.innerHeight - rect.y
            refContainer.current.style.bottom= (window.innerHeight - rect.y -50)+'px';
            if(props.down===true) {
                refContainer.current.style.bottom = (window.innerHeight-rect.y-rect.height-30)-refContainer.current.clientHeight+'px'
                bottom= (window.innerHeight-rect.y-rect.height)-refContainer.current.clientHeight
            }

            refContainer.current.style.left=rect.x+'px';
            refContainer.current.style.width=rect.width+'px';
            refContainer.current.style.opacity=0
            gsap.to(refContainer.current,{duration:0.35,opacity:1,bottom,ease:Power3.easeInOut})
        }
    },[isOpen, props.down])

    useEffect(()=>{
        
        if(props.selectItem!==undefined && selectedItem!==props.selectItem )
            setSelectedItem(props.selectItem)

        if(props.themeHeader['--svg-stroke'])
        {
            setIconStyle({'--svg-stroke':props.themeHeader['--svg-stroke'],width:'32px',minWidth:'32px'})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.selectItem])

    
    return (
        <div className={`${styles.btnMenuContainer}`} onClick={onToggle} open={isOpen} style={props.themeHeader} length={props.items.length} ref={refEle} >
            {props.items[selectedItem] && (
                <span ><Chevron style={iconStyle} ></Chevron> &nbsp; {props.items[selectedItem].name}</span>
            )}
            {isOpen && (
            <PortalContainer onclick={onToggle}>
            <div className={`fullscreen ${styles.portalBG}`} onClick={onToggle} onTouchStart={(evt)=>{console.log(evt)}}  
                onMouseDown={(evt)=>{ 
                    /* prevent clicks under  */
                    evt.preventDefault();evt.stopPropagation();
                }}></div>
            <div className={`${styles.dropupContainer}  `}  open={isOpen} count={`${props.items.length}`} style={props.themeContainer} ref={refContainer}>
                <div className={`${styles.dropupScrollContainer} hide-scroller ${(props.down===true)?'down':'up'}`}> 
                {   
                    props.items.map((val,index)=>{
                        return (
                            <div className={`${styles.dropupItem}`} active={index===Number(selectedItem)?'true':'false'} index={index} onClick={onSelectItem} key={props.keyName+'-'+val.name+'-dd-'+index} 
                            style={props.themeItem}
                            >
                                {val.name}
                            </div>
                        )
                    })
                }
                </div>
            </div>
            
            </PortalContainer>
            )}
        </div>
    )

}

export default StackDropUp