import React,{useEffect, useState} from "react";
import gsap,{Power3} from 'gsap'
import styles from './controller-narrative.module.css'
import mstyles from '../narrative/modules/modules.module.css'
import Globals from "system/Globals";
import {getContentPath} from  'system/AssetManager'
import {ReactComponent as ZoomOut} from '../assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from '../assets/icons/plus.svg'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'
import {ReactComponent as CaretUp} from '../assets/icons/caret_up.svg'
import {ReactComponent as CaretDown} from '../assets/icons/caret_down.svg'
import ControllerJoystick from './ControllerJoystick'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import ControllerVideoUI from './ControllerVideoUI'
import ControllerServerComs from 'system/ControllerServerComs'
import Help2Finger from '../assets/help/Directions-2fingers.webp'
import HelpSwipe from '../assets/help/Directions-swipe.webp'
import HelpTouch from '../assets/help/Directions-touch.webp'

let ControllerGalleryPopup = React.forwardRef( (props,ref)=>{
    const [fileType, setFileType] = useState(null);
    const [isMatterport, setIsMatterport] = useState(false);
    const [vidIsPlaying, setVidIsPlaying] = useState(false);

    useEffect(()=>{
        if (props.model.files) {

            setFileType(props.model.files[0].fileType)
        
            if (props.model.files[0].fileType === "video") {
                setVidIsPlaying(false);
            }
            console.log(props.model.files[0])
    
            console.log("TYPE :"+props.model.files[0].fileType)
            let result = props.model.files[0].fileType === "external" &&  props.model.files[0].external_src.includes('matterport');
            console.log('marker.files[0]',  props.model.files[0]);
            setIsMatterport(result);
            console.log(ref)
            if(ref.current)
            gsap.to(ref.current, {opacity: 1, duration: 0.3})
                if(ref.current.firstElementChild)ref.current.firstElementChild.style.opacity=1

        }


    },[props.model.files, ref])

    useEffect(() => {
        if(props.model){
            gsap.to(ref.current, {opacity: 1, duration: 0.3})
            if(ref.current.firstElementChild)ref.current.firstElementChild.style.opacity=1
        }
    }, [fileType, props.model, ref])

    const onKeyToggle = (command) => {
        ControllerServerComs.instance().sendUDPFromControllerToServer('key', command);
     }
     
     function onClickClose(){ animateCloseWindow() }
 
     function animateCloseWindow(){
         gsap.to(ref.current,{duration:0.5,opacity:0,top:'80%', ease:Power3.easeInOut,onComplete:()=>{
            props.setCurrentModule(null)
            ControllerServerComs.instance().sendUDPFromControllerToServer('gallerypopup-close','')   
         }})
     }

     

    return(
        <section className={`${styles.galleryPopup}`} ref={ref}>
                    
        { 
        (fileType ==='vr' || fileType ==='external') &&
            <ControllerJoystick 
            fileType={`${(isMatterport)?"matterport":fileType}`}
            twoFingerDrag={(fileType === "external")?true:false}></ControllerJoystick>
        }
        {(isMatterport) ?
            <div className={`${styles.arrowRowsFlex}`}>
            <div className={`${styles.arrowRows}`}>
                <div className={`${styles.arrowRow} ${styles.arrowRow0}`}>
                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('i')}><CaretUp /></div>
                </div>
                <div className={`${styles.arrowRow}  ${styles.arrowRow1}`}>
                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('left')}><CaretLeft /></div>
                </div>
                <div className={`${styles.arrowRow}  ${styles.arrowRow2}`}>
                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('right')}><CaretRight /></div>
                </div>
                <div className={`${styles.arrowRow}  ${styles.arrowRow3}`}>
                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('k')}><CaretDown /></div>
                </div>
            </div>
        </div>

            
        : null }
        
        { fileType === "vr" && 
       <div className={`${styles.buttons}  ${styles.buttons70}`}>
       <div className={`${styles.pillBtns}`}>
            <div className={`${styles.pillBtn}`} onClick={() => {
                ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomout");
            }}><ZoomOut /></div>
            
            <div className={`${styles.separator}`}></div>
            <div className={`${styles.pillBtn}`} onClick={() => {
                ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomin");
            }}><ZoomIn /></div>
        </div>
        </div>
        }

        {
        fileType ==='video'  &&
            <ControllerVideoUI vidIsPlaying={vidIsPlaying} setVidIsPlaying={setVidIsPlaying}></ControllerVideoUI>
        }
        {fileType ==='image'  &&
            <div className="fullscreen fcenter" style={{backgroundColor:'white'}} onClick={onClickClose}>
                <img src={getContentPath(props.model.files[0].file)} alt="" className={mstyles.imgOverlay} onClick={()=>{}}></img>
            </div>
        }

        {((isMatterport)||(fileType === "external")||(fileType === "vr")) ?
            <div className={`${styles.helpPanel}`}>  
                <h2>Directions</h2>                  
                {(isMatterport) ?
                <div className={`${styles.helpRow}`}>
                    <h3>Matterport VR</h3>
                    <p>Use the trackpad to click hotspots around the room</p>
                    <img src={HelpTouch} alt="touch" /> 
                    <p>Use arrow controls to change viewpoint</p>
                </div>
                : null }
                {(!isMatterport && (fileType === "external")) ?
                <div className={`${styles.helpRow}`}>
                    <h3>Embedded VR</h3>
                    <p>Use 1 finger like a cursor to select on-screen buttons</p>
                    <img src={HelpTouch} alt="touch" />
                    <p>Use 2 fingers to pan around the room</p>
                    <img src={Help2Finger} alt="touch" />
                </div>
                : null }
                {(fileType === "vr") ?
                <div className={`${styles.helpRow}`}>
                    <h3>360 VR</h3>
                    <p>Tap once to enter the VR</p>
                    <img src={HelpTouch} alt="touch" /> 
                    <p>Slide finger to pan around the space</p>
                    <img src={HelpSwipe} alt="touch" />
                    <p>Use buttons to zoom in/out</p>
                </div>
                : null }
            </div>
            : null }

          <span className={`${styles.joystickClose}`}>
          <div className={`${mstyles.closeBtnContainer}`}>
              <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
              <CloseButton></CloseButton>
              </div>
          </div>
         </span>
        </section>
    )

})

export default ControllerGalleryPopup