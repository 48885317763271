import React,{useRef,forwardRef,useCallback,useState,useEffect} from 'react'
import styles from './narrative.module.scss'
import {getContentPath} from 'system/AssetManager'
import gsap,{Power3} from 'gsap'

import {ReactComponent as IconMap} from 'assets/icons/_modules/map.svg';
import {ReactComponent as IconGallery} from 'assets/icons/_modules/gallery_image.svg'
import {ReactComponent as IconStack} from 'assets/icons/_modules/stack_availabilities.svg'
import {ReactComponent as Icon3D} from 'assets/icons/_modules/3d.svg'
import {ReactComponent as IconDocs} from 'assets/icons/_modules/doc_library.svg'
import {ReactComponent as IconVR2} from 'assets/icons/_modules/gallery_vr.svg'
import {ReactComponent as ControllerNarrative} from 'assets/icons/_modules/controller_narrative.svg'
import {ReactComponent as IconDragger} from 'assets/icons/dragger.svg'
import {ReactComponent as IconArrowLeft} from 'assets/icons/arrow_left.svg'
import {ReactComponent as IconBookmark} from 'assets/icons/favorite.svg'

// import {useHistory} from 'react-router-dom'
import Globals from 'system/Globals'
import ControllerServerComs from 'system/ControllerServerComs';
import ToolsMenu from 'system/components/ToolsMenu'
import NarrativeMenuItem from './NarrativeMenuItem';
import axios from 'axios';

function getModuleIcon(m,theme,i){

    return new Promise(resolve=>{
    let i = null
    let s = Globals.instance().getThemeStyle(['nav-menu-module-icon'], theme)
    if(m.icon){
        const ext = m.icon.split(".").pop()
        if(ext.toLowerCase()==="svg")
        {
            axios.get(getContentPath(m.icon)).then(response=>{
                resolve(<div style={{width:'100%',height:'100%'}} dangerouslySetInnerHTML={ {__html : response.data} }></div> )
            })
        }else 
            resolve( <img src={getContentPath(m.icon)} alt="" className={'themeSVGStroke themeSVGStrokeHover'}></img>)
    } else {
        
        switch(m.typeName){
            case 'map':
                i=<IconMap style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconMap>
            break;
            case 'mapbox':
                i=<IconMap style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconMap>
            break;
            case 'gallery':
                i=<IconGallery style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconGallery>
            break;
            case 'stack':
                i=<IconStack style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconStack>
            break;
            case 'vr':
                i=<IconVR2 style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconVR2>
            break;
            case 'webgl':
                i=<Icon3D style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></Icon3D>
            break;
            case 'docs':
                i=<IconDocs style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconDocs>
            break;
            case 'controller-narrative':
                i=<ControllerNarrative style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></ControllerNarrative>
            break;
            default:
                i=<IconGallery style={s} className={'themeSVGStroke themeSVGStrokeHover'} key={`anarmodicon${i}`}></IconGallery>
            break;
        }
        
        resolve(i)
    }
    })
}
function isVideo(path){
    let ext = path.split('.').pop()
    if(ext.toLowerCase()==="mp4" || ext.toLowerCase()==="ogg" || ext.toLowerCase()==="mov" || ext.toLowerCase()==="mpeg" || ext.toLowerCase()==="mp4" || ext.toLowerCase()==="m4v" || ext.toLowerCase()==="mp4" || ext.toLowerCase()==="m4v"){
        return true
    }
    else return false
}

const NarrativeMenu =  forwardRef((props,ref)=>{
    
    const refDragger = useRef()
    const refRightCol = useRef()
    
    const refBtnContainer = useRef()
    const [selectedBookmark, setSelectedBookmark]=useState()
    const [first,setFirst]=useState(true)
    const [icons,setIcons] = useState([])
    const [menuOpen,setMenuOpen] = useState(true)
    const refOnClose =useRef()
    const [isController, setIsController] = useState(false);
    const [controllerHeading, setControllerHeading] = useState("Narrative");
    const [navPos, setNavPos] = useState(null);
    // const [menuItems,setMenuItems] = useState([])

    useEffect(()=>{
        
        if (Globals.instance().controllerApp) {
            setIsController(true);
        }

        refOnClose.current=()=>{
            closeNav()
        }
        const current = ref.current
        ref.current.addEventListener('close-menu',refOnClose.current)

        let params = new URLSearchParams(props.history.location.search)
        if(params.get('nav')==='closed'){
            closeNav()
        }
        
        const fanimIn=()=>{
            // console.log('animate in ',refRightCol.current)
            let rightStyle = window.getComputedStyle(refRightCol.current)
            let w= rightStyle.width
            let p = rightStyle.paddingRight
            refRightCol.current.style['min-width']=0
            refRightCol.current.style.width=0
            current.removeEventListener('animateIn',fanimIn)
            gsap.to(refRightCol.current,{duration:1.5,ease:Power3.easeInOut,width:w,paddingRight:p})
        }
        current.addEventListener('animateIn',fanimIn)

        
       
        return ()=>{
            current.removeEventListener('animateIn',fanimIn)
            current.removeEventListener('close-menu',refOnClose.current) 
        }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    //create menu links 
    // useEffect(()=>{
        
    //     let mitems=[]
        
    //     if(props.model.menu_links){props.model.menu_links.forEach((val,index)=>{
    //         let menuItem = { label:val.label,index:index }
    //         if(val.group){
    //             let grp = mitems.find(mi=>{return mi.label===val.group})
    //             if(!grp){
    //                 grp={label:val.group, subs:[]}
    //                 mitems.push(grp)
    //             }
    //             else
    //                 grp.subs.push(menuItem)
    //         }else{
    //             mitems.push(menuItem)
    //         }
    //     })
    //     setMenuItems(mitems)
    //     }
    // },[setMenuItems, props.model.menu_links])

    useEffect(()=>{
        setSelectedBookmark(Globals.instance().bookmarks.indexOf(props.model.id)>=0)
        
   
    },[props.model])
    // useLayoutEffect(()=>{
    // })

   

    useEffect(()=>{

       if( props.modules){ 
            let ps=[]
           props.modules.forEach((val,index)=>{
                ps.push(getModuleIcon(val,props.theme,index))
            })
            Promise.all(ps).then(vals=>{ 
                setIcons(vals)
            })
        }
    },[props.modules,  props.theme])

  
    const closeNav= useCallback(()=>{

        
        // pauseVideo()
        if(ref.current && ref.current.querySelector('video')) ref.current.querySelector('video').pause()
        
        let params = new URLSearchParams(props.history.location.search)
        params.set('nav','closed')
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
        let eleColRight = ref.current.querySelector('.'+styles.menuColRight)
        
        // let vEle = ref.current.querySelector('video')
        // if(vEle) vEle.pause()
        if(!ref.current){
            Globals.instance().showAlert('error','could not close nav')
        }
        gsap.to(ref.current,{duration:1,left:'-100%',ease:Power3.easeInOut,
        
        onComplete:()=>{
            if(first && !Globals.instance().controllerApp){ 
                props.onFirstClose()
                setFirst(false)
            }
        }})
        
        gsap.to(refDragger.current,{duration:1,right:'-'+refDragger.current.clientWidth+'px',ease:Power3.easeInOut})
        gsap.to(refBtnContainer.current.querySelector('.btnBookmark'),{duration:1,opacity:0})
        // gsap.to(refBtnContainer.current,{left:posLeft,duration:1,ease:Power3.easeInOut})
        setMenuOpen(false)
        ref.current.setAttribute("overlap",'')
        
        if(first && Globals.instance().controllerApp){ 
            props.onFirstClose()
            setFirst(false)
        }
        
        let eleBG = ref.current.querySelector('.bg')
        if(eleBG && Globals.instance().presentationMode){
           gsap.to(ref.current,{duration:1,css:{backgroundColor:'transparent',ease:Power3.easeInOut}})
           gsap.to (eleColRight,{duration:1,borderRadius:0,ease:Power3.easeInOut})
           gsap.to(eleBG,{duration:1,css:{marginRight:eleColRight.clientWidth+'px',ease:Power3.easeInOut}})
        }
        // gsap.to(refTools.current,{duration:0.5,opacity:0,ease:Power3.easeInOut});
    },[first, props, ref])

    const openNav= (full)=>{
        let params = new URLSearchParams(props.history.location.search)
        params.set('nav','open')
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})

        let eleColRight = ref.current.querySelector('.'+styles.menuColRight)
        let pos=full?0:-eleColRight.offsetLeft
        gsap.to(ref.current,{duration:1,left:pos+'px',ease:Power3.easeInOut})
        gsap.to(refDragger.current,{duration:1,right:'0px',ease:Power3.easeInOut})
        let eleBG = ref.current.querySelector('.bg')
           
        setMenuOpen(true)
        if(full){
            refBtnContainer.current.querySelector('.btnBookmark').style.display='block'
            gsap.to(refBtnContainer.current.querySelector('.btnBookmark'),{duration:1,opacity:1})
            
            ref.current.removeAttribute("overlap")
            gsap.to(refBtnContainer.current,{left:0,duration:1,ease:Power3.easeInOut})
            playVideo()
            
            if(eleBG && Globals.instance().presentationMode){
                let color = ref.current.style.getPropertyValue('--primary-background'); 
                gsap.to(ref.current,{duration:1,css:{backgroundColor:color,ease:Power3.easeInOut}})
                gsap.to (eleColRight,{duration:1,css:{borderRadius:'75px 0 0 0'},ease:Power3.easeInOut})
                gsap.to(eleBG,{duration:1,css:{marginRight:0,ease:Power3.easeInOut}})
            }
        }else{
            
            ref.current.setAttribute("overlap",'')
            gsap.to(refBtnContainer.current,{left:window.innerWidth - ref.current.querySelector('.rightcolcontainer').clientWidth,duration:1,ease:Power3.easeInOut})
            gsap.to(refBtnContainer.current.querySelector('.btnBookmark'),{duration:0.2,opacity:0,onComplete:()=>{
                refBtnContainer.current.querySelector('.btnBookmark').style.display='none'
            }})
            
            if(eleBG && Globals.instance().presentationMode){
                gsap.to(ref.current,{duration:1,css:{backgroundColor:'transparent',ease:Power3.easeInOut}})
                gsap.to (eleColRight,{duration:1,borderRadius:0,ease:Power3.easeInOut})
                
                gsap.to(eleBG,{duration:1,css:{marginRight:eleColRight.clientWidth+'px',ease:Power3.easeInOut}})
            }
        }
        gsap.to(refDragger.current,{duration:1,right:'0px',ease:Power3.easeInOut})
        
        setNavPos(pos);
    }

    const onClickDragger =()=>{}

    const onDown= useCallback((evt)=>{
        let startPosX = (evt.touches && evt.touches.length)?evt.touches[0].clientX:evt.clientX
        let diffX = 0
        refDragger.current.display='none'
        const maxW=ref.current.getBoundingClientRect().width
        refDragger.current.display='block'
        let bDragging = false
        let colorFrom = getComputedStyle(ref.current).getPropertyValue('--primary-background'); 
                
        const sideBarWidth =refDragger.current.getBoundingClientRect().width
        let dis = 0
        let eleBG = ref.current.querySelector('.bg')
        // let eleMenuRight = ref.current.querySelector('.menu-right')

        const onMove=(evt)=>{
            
            let posX =  (evt.touches && evt.touches.length)?evt.touches[0].clientX:evt.clientX;
            diffX= posX-startPosX;
            startPosX=posX
            let destPosX = (ref.current.offsetLeft +diffX)
            destPosX=destPosX>0 ? 0 :destPosX
            destPosX=destPosX < -maxW?  -maxW:destPosX
            let percent = -destPosX/maxW
            refDragger.current.style.right= (percent* -sideBarWidth)+'px'
            ref.current.style.left =  destPosX+'px'
            let leftEdge=window.innerWidth - refBtnContainer.current.clientWidth
            refBtnContainer.current.style.left= (Math.min(-(ref.current.offsetLeft/window.innerWidth) * leftEdge,leftEdge))+'px'
            
            dis=dis + Math.abs(diffX)
            
            if(dis>10)
            bDragging=true

            if(Globals.instance().presentationMode){
                // console.log(Math.round(percent*75.0)+'px 0 0 0')
                let pW= Math.abs(percent * (1/(1-(refRightCol.current.clientWidth/maxW))))
                gsap.set(ref.current,{backgroundColor:gsap.utils.interpolate(colorFrom,'transparent',pW)})
                refRightCol.current.style.borderRadius=Math.round((1-pW)*75.0)+'px 0 0 0'
                eleBG.style.marginRight=(pW * parseFloat(refRightCol.current.clientWidth))+'px'
            }


  
            
        }
        const onUp=(evt)=>{
            evt.preventDefault(); 
            //check if we need open or close
            if(bDragging && startPosX/window.innerWidth > 0.35 ){
                openNav(true)
            }
            else if(!bDragging){
                if(menuOpen)
                    closeNav()
                else
                    openNav(false)
            }
            else {
                // let percent = (refFirst.current)?.5:0.1
                if(menuOpen)
                    closeNav()
                else
                    openNav()
            }
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
            window.removeEventListener('mouseleave',onUp)
        }

        window.addEventListener('mousemove',onMove,{passive:true})
        window.addEventListener('touchmove',onMove,{passive:true})
        window.addEventListener('touchend',onUp,{passive:false})
        window.addEventListener('touchcancel',onUp,{passive:false})
        window.addEventListener('mouseup',onUp,{passive:false})
        window.addEventListener('mouseleave',onUp,{passive:true})
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ref,closeNav,openNav])

    // const animNarrativeMenuPosForController = useCallback(() => {
    //     let buttonContainer = ref.current.querySelector('.' + styles.buttonContainer);
    //     // let menuColRight = ref.current.querySelector('.' + styles.buttonContainer);
    //     function adjustWidths() {
    //         gsap.to(buttonContainer,{duration:0, left: 0})
    //     }
    //     gsap.to(ref.current, {duration:1, width: 300, ease:Power3.easeInOut, onComplete: adjustWidths});
    //     ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-position','')
    // }, [])
    
    function onClickModule(evt){

        // if (isController) {
        //     console.log("controller: onClickModule")
        //     animNarrativeMenuPosForController();
        // }
        let index = evt.currentTarget.getAttribute("index")
        // console.log(props.modules,index)
        props.onSelectModule(new CustomEvent('select-module',{detail:{index:index,module:props.modules[index]}}))
        // ref.current.dispatchEvent()
    }
    
    function playVideo(){
        if(ref.current && ref.current.querySelector('video')) ref.current.querySelector('video').play()
    }
    function onClickBack(){
        // pauseVideo()
        
        if (navPos < 0) {
            openNav('full') 
        } else { 
            if(Globals.instance().ios){
                //dispatch close narrative webview
                window.webkit.messageHandlers.notification.postMessage({ Object: 'close-narrative' })
                return
            }
            else if(Globals.instance().homeLink){
                if(window.location.pathname.indexOf("/home")!==-1)return
                props.history.push(Globals.instance().homeLink)

                if(Globals.instance.controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate',Globals.instance().homeLink)   
                }

            }else{
                props.history.push(Globals.instance().getRoute('/home'))
                if(Globals.instance.controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/home')   
                }
            }
        }
       
    }

   function onSetDrawControls(val){
       closeNav()
       props.setShowDrawControls(val)
   }

    function onClickBookmark(){
        if(selectedBookmark){
            let i = Globals.instance().bookmarks.indexOf(props.model.id)
            if(i>=0)Globals.instance().bookmarks.splice(i,1)
        }else{
            if(Globals.instance().bookmarks.indexOf(props.model.id)===-1)
            Globals.instance().bookmarks.push(props.model.id)
            Globals.instance().trackEvent('click-bookmark',{ 'event_category':'tools'})
        }
       // console.log(selectedBookmark)
        localStorage.setItem('bookmarks',JSON.stringify(Globals.instance().bookmarks))
            // console.log(Globals.instance().bookmarks)
        setSelectedBookmark(!selectedBookmark)
        
    }
    
    
    const onClickLink = useCallback((evt) => {

        closeNav()
        let index = evt.currentTarget.getAttribute("index")
        console.log("onClickLink", evt, evt.currentTarget, props.model,index)
        const page = props.model.menu_links[index].page;
        props.onScrollEvent(new CustomEvent('scrollto',{detail:{page},bubbles: true}))
        Globals.instance().trackEvent('click-link',{ 'event_category':'tools'})
         
        
    },[closeNav, isController, props])
    
    useEffect(() => {
        console.log('navPos', navPos);

        if (navPos < 0) {
            props.setOpenMenuFull(false);
        }

    }, [navPos])

    useEffect(() => {
        

        if (props.openMenuFull) {
            console.log('props.openMenuFull', props.openMenuFull);
            openNav('full');
        }
        
    }, [props.openMenuFull])
    
    const onExpandSubs =useCallback(evt=>{
        console.log(evt)
        let openEle = refRightCol.current.querySelector(`.${styles.menuLink}[open]:not([index="${evt}"])`)
        console.log(openEle)
        if(openEle){
            openEle.dispatchEvent(new CustomEvent('close'))
        }
    },[])

    

    
    return (
            
            <div className={`${styles.menuContainer} force3d`}  open={menuOpen} ref={ref}>
            {
                props.model.menu_background_right &&
                ( <img src={getContentPath(props.model.menu_background_right)} alt="" className={`${styles.menuRightBg} bg`}></img>)
            }
            { !props.model.menu_background  && 
                (   <img src={getContentPath('core/narrative/loading-bg.png',true)} className={`${styles.menuBg}  bg`} alt="" />)
            }
            { props.model.menu_background && !isVideo(props.model.menu_background) && 
                    (   <img src={getContentPath(props.model.menu_background)} className={`${styles.menuBg}  bg`} alt="" />)
            }
            {
             props.model.menu_background && isVideo(props.model.menu_background) && 
                    (   <video muted loop playsInline autoPlay preload={1} src={getContentPath(props.model.menu_background)} className={`${styles.menuBg} constant bg`} alt="" />)
            }
            
            <div className={`${styles.menuColRight} rightcolcontainer `} ref={refRightCol} style={
                props.model.menu_background_right?{backgroundColor:'transparent'}:{}
            }>
            
            { props.model.menu_logo &&(
                <img src={getContentPath(props.model.menu_logo)} className={styles.menuLogo} alt="logo"></img>
            )}
            { !props.model.menu_logo &&(
                <div className={`${styles.menuTitle}`}>{props.model.heading}</div>
            )}
                
                <div className={`${styles.menuRightColContainer} menu-right hide-scroller`} >
                    <div className={`${styles.menuModuleContainer} ifServerHide`} style={Globals.instance().getThemeStyle(['nav-modules-container'],props.theme)}>
                        {
                            icons.map( (icon,index) =>{
                                return (
                                    <div className={`${styles.moduleLink}`} key={`nar-menu-mode-item-${index}`} index={index} 
                                    onClick={onClickModule}> 
                                       {icon}
                                    </div>
                            )})
                        }
                    </div>
                    <div className={`${styles.menuLinksContainer} hide-scroller`} >
                    {
                       
                       props.menuItems && props.menuItems.map((val,index)=>{
                           
                            return(
                             <NarrativeMenuItem
                             model={val}
                             index={index}
                             key={`nar-menu-link-${index}`} 
                             onClickLink={ onClickLink } 
                             onExpand={onExpandSubs}
                             className={styles.menuLink}
                             theme={props.theme}
                             style={Globals.instance().getThemeStyle(['nav-menu-item'],props.theme)}
                             ></NarrativeMenuItem>
                            
                            )
                        })
                    }
                    </div>

                    
                </div>
                
            </div>
           {!isController &&
            <div className={`${styles.sideDragger} ifServerHide`} ref={refDragger} onTouchStart={onDown} onMouseDown={onDown} onClick={onClickDragger} style={Globals.instance().getThemeStyle(['nav-menu-dragger'],props.theme)}>
                <IconDragger></IconDragger>
            </div>}

            <div className={`${styles.buttonContainer} ifServerHide `} ref={refBtnContainer}>
                        
           
            
              
                
                  
                        <div className={`round-btn`} onClick={onClickBack} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <IconArrowLeft ></IconArrowLeft>
                        </div>
                        <div className={`${styles.btnBookmark} ifControllerHide btnBookmark ${selectedBookmark?'selected':''}`} >
                            <div className={`round-btn`} onClick={onClickBookmark}  style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)} >
                                <IconBookmark  selected={selectedBookmark} ></IconBookmark>
                            </div>
                        </div>
                        <ToolsMenu theme={props.theme} 
                        setShowDrawControls={onSetDrawControls} 
                        setShowControls={props.setShowControls} 
                        setShowMarkup={props.setShowMarkup}
                        model={props.model}

                        ></ToolsMenu>
                        
                   

            </div>
        </div>
           
    )
})

const NarrativeMenuMemo  = React.memo(NarrativeMenu)

export {NarrativeMenuMemo as default, getModuleIcon,isVideo}

