import React,{useState,useEffect,useRef} from 'react'
import { connect } from 'react-redux';
import {
    Router,
    Switch,
    Route,
    Redirect,
    HashRouter
} from "react-router-dom";

import Landing from '../Landing'
import Launch from '../Launch'
import Home from '../Home'
import Login from '../Login'
import Empty from 'narrative/Empty'
import PageRender from 'narrative/PageRender'
import Narrative from 'narrative/Narrative'
import history from './MainRouterHistory'
import { TransitionGroup, CSSTransition ,SwitchTransition} from "react-transition-group";
import Globals from 'system/Globals';
import gsap,{Power3} from 'gsap';
import Denied from '../Denied'
import ControllerNarrativeMain from '../../controller/ControllerNarrativeMain';

const SystemRoutes = [
   
    {
        'path':Globals.instance().getRoute('/landing'),
        'key':'landing',
        'component':Landing
    },
    
    {
        'path':Globals.instance().getRoute('/login'),
        'key':'login',
        'component':Login
    },
    {
        'path':Globals.instance().getRoute('/denied'),
        'key':'login',
        'component':Denied
    },
    {
        'path':Globals.instance().getRoute('/home/myproperties'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/settings'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/search'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/:d1/:d2/:d3/:d4'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/home/:d1/:d2/:d3'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home/:d1/:d2'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home/:d1'),
        'key':'home',
        'component':Home
    },{
        'path':Globals.instance().getRoute('/home'),
        'key':'home',
        'component':Home
    },
    {
        'path':Globals.instance().getRoute('/controllernarrative/:id?'),
        'key':'controllernarrative',
        'component':ControllerNarrativeMain
    },
    {
        'path':Globals.instance().getRoute('/render/narrative/:id/:pageid'),
        'key':'narrative-page',
        'component':PageRender
    },
    {
        'path':Globals.instance().getRoute('/narrative/:id?'),
        'key':'narrative',
        'component':Empty
    },
    {
        'path':Globals.instance().getRoute('/redirect'),
        'key':'launch',
        'component':Launch
    },
    {
        'path':Globals.instance().getRoute('/'),
        'key':'launch',
        'component':Launch
    }
   
]

    

const MainRouter =function(props){

    const [showNarrative,setShowNarrative] = useState(undefined)
    const narrativeKey=useRef()
    const refSection =useRef()
    useEffect(()=>{
        history.listen(()=>{
            let params = new URLSearchParams(history.location.search)
            
            let narrative = params.get('narrative')
            if(history.location.pathname.indexOf('/narrative')===0 )
            narrative = history.location.pathname.split("/")[2]
            narrativeKey.current=narrative
            if( narrative){
                 setShowNarrative(narrative)
            }
            else{
                let eleNar=refSection.current.querySelector('.narrative-holder')
                if(eleNar){
                    eleNar.style['pointer-events']='none'
                    gsap.to(eleNar,{duration:0.75, opacity:0,ease:Power3.easeInOut,onComplete:()=>{
                        setShowNarrative(undefined)
                    }})
                }else{
                    setShowNarrative(undefined)
                }
                
            }
            // console.log(history.location.pathname)
        })
        
    },[])

    function transitionKey(loc){
        if(loc.pathname.indexOf('/home')===0)    
            return 'home'
        else if(loc.pathname.indexOf('/narrative')===0)
            return loc.pathname
        else 
            return loc.key
    } 

    function fadeTimeout(loc){
        if(Globals.instance().controllerApp)
            return { enter: 450, exit: 450}
        else
            return { enter: 0, exit: 0}
    }
    function fadeClass(loc){
        // console.log(loc)
        // if(loc.pathname.indexOf('/narrative')===0)
        //     return ''
        // else 
            return 'fade-page'
    }
  
    function renderNarrativeForLoad(location){
        let params = new URLSearchParams(location.search)
        let narrative = params.get('narrative')
        if(narrative){
            return 
                              
        }
    }

    function checkReRoute(location,init,user){
        // let reroute= false
        if(location && location.pathname!=='/' && location.pathname.indexOf('/admin')===0) {
            // console.log(Globals.instance().adminAPI)
            return  <Redirect  to={{ path:Globals.instance().adminAPI}} />
        }
        
        if(!init && location 
            && location.pathname!=='/' 
            && location 
            && location.pathname.indexOf('/render/')!==0 
            && location.pathname.indexOf('/redirect')!==0 
            && location.pathname.indexOf('/denied')!==0 
            && location.pathname.indexOf('/login')!==0 ) {
            return  <Redirect  to={{ pathname:'/', state: { referrer: history.location }}} />
        }
        
        if((location.pathname.indexOf("/narrative/")===0 || location.pathname.indexOf("/controllernarrative/")===0) && !(Globals.instance().mode === 'static-web') ){
            if(!user)return <Redirect  to={{ pathname:'/login', state: { referrer: history.location }}} />
            let arr = location.pathname.split("/")
            let nid = arr[arr.length-1]
            if(Number(nid)!==Globals.instance().narrativeHelp && user.narratives.findIndex(i=>{return String(i)===String(nid)}) === -1) 
                return <Redirect  to={{ pathname:'/denied' }} />
        }
    }

    function getComponentKey(route,location){
        let arrLoc=location.pathname.split("/")
        let key=route.key
        if(arrLoc.length>2 && arrLoc[1]==='narrative')key= route.key+'/'+arrLoc[2]
        return key
    }
  
    return (
        
        <section className={'fullscreen'} ref={refSection}>

           
            <Router history={history} basename={Globals.instance().basepath} >
           
          
            <Route render={({ location }) => (
                
                <TransitionGroup  style={ {
                    position: 'absolute',
                    top:0,
                    left:0,
                    width:'100%',
                    height:'100%',
                    zIndex:0
                  }}>
                
                     <CSSTransition
                     timeout={fadeTimeout(location)}
                     classNames={fadeClass(location)}
                     key={transitionKey(location)}
                    > 
            
                
                    <Switch location={location}>
                        {
                        checkReRoute(history.location,props.isInitialized,props.user) 
                        }
                       
                        {
                            SystemRoutes.map((route, i) => (
                                <Route
                                path={route.path}
                                key={('route'+getComponentKey(route,location))}
                                render={
                                    props => {
                                        return (
                                        <React.Fragment>
                                            <route.component {...props} routes={route.routes}  key={getComponentKey(route,location)} />
                                            {
                                                location.pathname.indexOf('/home'===0) && (
                                                renderNarrativeForLoad(location)
                                                )
                                            }
                                        </React.Fragment>
                                )}}
                                />

                            ))
                            
                        }
                    </Switch>
            
                </CSSTransition>
                </TransitionGroup>
            )} />
                    
            </Router>
            {
                showNarrative && 
                <Narrative history={history} nid={showNarrative} key={('componentnarrative'+narrativeKey.current)}  />
            }
        </section>
    )
}



  
const mapStateToProps = state => {
    const {intialized,user} = state
    return { isInitialized:intialized,user};
};



export default connect(mapStateToProps, null)(MainRouter)