import React, { useEffect, useState } from "react";
import styles from './controller-narrative.module.css'
import {getContentPath} from  'system/AssetManager'
import {ReactComponent as IconPlus} from 'assets/icons/plus.svg';
import {ReactComponent as ZoomOut} from '../assets/icons/close.svg'
import {ReactComponent as ZoomIn} from '../assets/icons/zoom.svg'
import {ReactComponent as IconClose} from '../assets/icons/close.svg'
import StackDropUp from '../narrative/modules/stack/StackDropUp'
import ControllerServerComs from 'system/ControllerServerComs'

const ControllerStackThumb = (props)=>{

    
    const [floor,setFloor] = useState(null)
    const [plan,setPlan] = useState(null)
    const [selectedFloor,setSelectedFloor]=useState(-1)
    const [selectedPlan,setSelectedPlan]=useState(-1)
    const [initPlan,setInitPlan] = useState(false)
    const [initFloor,setInitFloor] = useState(false)
    // const [ isZoom,setIsZoom] = useState(false)
    
    useEffect(()=>{
        //auto load the landing plan to sync with stack component
        if(props.stack && props.index===0){
            let landingIndex = 0
            
            if(props.landingParams && !initFloor){
                landingIndex= Number(props.landingParams.split[0])
                setInitFloor(true)
            }
            else if (props.stack.landing){
                landingIndex = props.stack.floors.findIndex((f)=>{ return f.floor === props.stack.landing})
            }
            console.log("set landing" ,landingIndex)
            setSelectedFloor(landingIndex)
        }
        else{
            setSelectedFloor(-1)
        }
    },[initFloor, props.index, props.landingParams, props.stack])

    function onThumbnailClick(){
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-scroll-to',props.index);
    }

    function onClickAddPlan(){
        console.log(props.stack)
        
        let landingIndex = 0
        if (props.stack.landing){
            landingIndex = props.stack.floors.findIndex((f)=>{ return f.floor === props.stack.landing})
        }
        
        setSelectedFloor(landingIndex)
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-add-plan',props.index);
    }

    function onClickZoom(){
    //    if(isZoom===false)
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-zoom-in-plan',props.index+'-'+selectedFloor+'-'+selectedPlan);
    //    else 
    //     ControllerServerComs.instance().sendUDPFromControllerToServer('stack-zoom-out-plan',props.index+'-'+selectedFloor+'-'+selectedPlan);
    //     setIsZoom(!isZoom)
    

        let m ={
            "key": "test",
            "markerImage": "",
            "files": [{
                "file": plan.plan,
                "thumbnail": plan.plan,
                "categories": "",
                "fileType": "image",
                "external_src": null,
                "loop": true,
                "ordering": 0
            }]
        }
        props.onClickMarker(m)
    }
    

    function onClickClose(){
         setSelectedFloor(-1) 
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-close-plan',props.index);
    }
    
    function onSelectFloor(f){
        setSelectedFloor(f)
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-select-floor',props.index+'-'+f);
    }

    function onSelectPlan(p){ setSelectedPlan(p)
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-select-plan',props.index+'-'+selectedFloor+'-'+p);
    }
    
    function onClickMarker(e){  
       
        let m =Number(e.currentTarget.getAttribute("index"))
        props.onClickMarker(plan.markers[m]) 
        ControllerServerComs.instance().sendUDPFromControllerToServer('stack-select-marker',props.index+'-'+selectedFloor+'-'+selectedPlan+'-'+m);  
        
        
    }

    useEffect(() => {
        console.log('plan', plan)
    }, [plan])

    useEffect(()=>{ 
        if(selectedPlan===-1 )setPlan(null)
        else if(floor && floor.plans && floor.plans.length)
         setPlan(floor.plans[selectedPlan]) 
    },[floor, selectedPlan])
    
    useEffect(()=>{
        if(selectedFloor===-1){setFloor(null);setSelectedPlan(-1)}
        else if(props.stack.floors && props.stack.floors.length){
            
            setFloor(props.stack.floors[selectedFloor])
            let landingplan=0
            if(props.landingParams && !initPlan){
                landingplan= Number(props.landingParams.split[1])
                console.log(landingplan)
                setInitPlan(true)
            }
                setSelectedPlan(landingplan)
        }
    },[initPlan, props.landingParams, props.stack, selectedFloor])

    useEffect(()=>{
        if(setInitPlan === false && props.index === 0){
            setPlan(floor.plans[props.landingPlanIndex])
        }
        else if(floor && floor.plans.length)
            setPlan(floor.plans[0])
        else
            setPlan(null)

        setInitPlan(true)
    },[floor, props.index, props.landingPlanIndex])

    return (
        <div 
         index={props.index}
         className={`${styles.stackThumbnail}`}
         style={{visibility:Number(props.index)<Number(props.planCount) ?'visible':'hidden' } }
        >
            <div className={`${styles.headingStack}`}>
                <div className="heading">Plan {props.index+1} </div>
                { plan && (
                    <React.Fragment>
                        <div className={`${styles.headingStackBtn}`} onClick={onClickZoom}>
                            <ZoomIn></ZoomIn>
                            
                        </div>
                        { Number(props.index) > 0 && (<div onClick={onClickClose} className={`${styles.headingStackBtn}`}><IconClose></IconClose></div>) }
                    </React.Fragment>
                )}
            </div> 
                            
            <div className={styles.stackThumbnailContainer}>
                { plan == null && (
                    <div className={styles.stackThumbnailHolder} onClick={onClickAddPlan}>
                        <div className="round-btn">
                             <IconPlus></IconPlus>
                        </div>
                    </div>
                )}
                 { plan && (
                    <div className={styles.stackThumbnailHolder} onClick={onThumbnailClick}
                    >
                        <img src={getContentPath(plan.plan)} alt=""></img>
                    </div>
                )}
                
            </div>

            {plan && (
                <React.Fragment>
                    <div className={`${styles.headingStack} ${styles.dropdown}`}>
                        <StackDropUp 
                        items={props.stack.floors} 
                        down={true}
                        onSelect={onSelectFloor} keyName={'flr-selector-'+props.index} 
                        selectItem={selectedFloor} 
                        theme={props.theme}
                        themeHeader={{"fontSize":'16px',border:'none',color:'#0F3057',"--svg-stroke":'#0F3057',minWidth:'auto',margin:0,letterSpacing:0,padding:'6px 0',lineHeight:'18px'}}
                        themeItem={{color:'#0F3057',"fontSize":'16px',padding:'8px 0',lineHeight:'25px'}}
                        themeContainer={{border:'1px solid #FF4C55',padding:'15px',marginTop:'15px'}}
                        ></StackDropUp>
                    </div>
                    { floor && floor.plans && floor.plans.length>1 && (<div className={`${styles.headingStack} ${styles.dropdown}`}>
                        <StackDropUp 
                        items={floor.plans} 
                        down={true}
                        onSelect={onSelectPlan} keyName={'flr-selector-'+props.index} 
                        selectItem={selectedPlan} 
                        theme={props.theme}
                        themeHeader={{"fontSize":'16px',border:'none',color:'#0F3057',"--svg-stroke":'#0F3057',minWidth:'auto',margin:0,letterSpacing:0,padding:'6px 0',lineHeight:'18px'}}
                        themeItem={{color:'#0F3057',"font-size":'16px',padding:'8px 0',lineHeight:'25px'}}
                        themeContainer={{border:'1px solid #FF4C55',padding:'15px',marginTop:'15px'}}
                        ></StackDropUp>
                    </div> )}

                    {plan.markers && plan.markers.map((m,i)=>{return (

                            <div className={styles.markerButton} onClick={onClickMarker} index={i} key={`marker${selectedFloor}-${selectedPlan}-${i}`}>{m.name}</div>

                        )}
                    )}
                </React.Fragment>
                )}
        
    </div>
    )
}

export default ControllerStackThumb