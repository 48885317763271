import React,{forwardRef,useRef,useEffect,useState,useCallback} from 'react'
import styles from './narrative.module.scss'
import NarrativeMenuItem  from './NarrativeMenuItem'

import {ReactComponent as ArrowLeft} from 'assets/icons/caret_left.svg'
import {ReactComponent as ArrowRight} from 'assets/icons/caret_right.svg'
import {ReactComponent as Close} from 'assets/icons/close.svg'
import {ReactComponent as Grip} from 'assets/icons/grip.svg'
import {ReactComponent as Dragger} from 'assets/icons/dragger.svg'
import {getModuleIcon } from './NarrativeMenu'

import gsap,{Power3} from 'gsap'
import Globals from 'system/Globals'

const Controls =forwardRef((props,ref)=>{
    // let ref = useRef()
    let refX=useRef(0)
    let refY=useRef(0)
    let refBtmDrawer = useRef()
    const [drawerOpen,setDrawerOpen ] = useState(false)
    const [icons,setIcons] = useState([])
    
    useEffect(()=>{
        gsap.to(ref.current,{opacity:1,duration:0.35,delay:0.5})
        gsap.from(ref.current,{scale:0.75,duration:0.35,delay:0.5})
        return ()=>{ }
    },[ref])

    useEffect(()=>{
       
       if( props.modules){ 
            let ps=[]
           props.modules.forEach((val,index)=>{
                ps.push(getModuleIcon(val,props.theme))
            })
            Promise.all(ps).then(vals=>{ 
                setIcons(vals)
            })
            
        }
    },[props.modules,  props.theme])
    
    useEffect(()=>{
        let dragger = document.querySelector('#controlsDragger');
        if(drawerOpen){
            let theight = refBtmDrawer.current.clientHeight;
            refBtmDrawer.current.style.height='auto'
            let height = refBtmDrawer.current.clientHeight;
            refBtmDrawer.current.style.height=theight+'px'
            gsap.to(refBtmDrawer.current,{height:height,ease:Power3.easeInOut,duration:0.5,onComplete:(ele)=>{ele.style.height='auto';},onCompleteParams:[refBtmDrawer.current]})
            gsap.to(dragger,{rotationX: 0,duration:0.25})
        }
        else{
            gsap.to(refBtmDrawer.current,{height:'32px',ease:Power3.easeInOut,duration:0.5})
            gsap.to(dragger,{rotationX: 180,duration:0.25})
        }
    },[drawerOpen])
    function onDown(e){
        
        if(e.touches){
            refX.current=e.touches[0].clientX
            refY.current=e.touches[0].clientY
        } else{
            refX.current=e.clientX
            refY.current=e.clientY
        }
        let onMove=(evt)=>{
            // evt.preventDefault();
            
            let xpos,ypos;
            if(evt.touches){
                xpos=evt.touches[0].clientX
                ypos=evt.touches[0].clientY
            } else{
                xpos=evt.clientX
                ypos=evt.clientY
            }
            let diffX = xpos-refX.current
            let diffY = ypos-refY.current
            let style= getComputedStyle(ref.current)
            refX.current=xpos
            refY.current=ypos
            
            ref.current.style.left = (parseFloat(style.getPropertyValue('left'))+diffX)+'px'
            ref.current.style.bottom = (parseFloat(style.getPropertyValue('bottom'))-diffY)+'px'
        }
        let onUp=()=>{
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }
        window.addEventListener('mousemove',onMove)
        window.addEventListener('touchmove',onMove)
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)
        

    }
    function onClickLeft(){
        setDrawerOpen(false)
        props.scrollOffset(-1)
    }
    function onClickRight(){
        setDrawerOpen(false)
        
        props.scrollOffset(1)
    }
    function onClickClose(){
        gsap.to(ref.current,{opacity:0,duration:0.25, onComplete:()=>{props.setShowControls(false)}})
        gsap.to(ref.current,{scale:0.75,duration:0.25})
    }
    function onClickLink(evt){
        
        setDrawerOpen(false)
        let index = evt.target.getAttribute("index")
        const page = props.model.menu_links[index].page;
        props.onScrollEvent(new CustomEvent('scrollto',{detail:{page},bubbles: true}))
        
    }
    function onClickModule(evt){
        let index = evt.currentTarget.getAttribute("index")
        console.log(props.model.modules[index])
        props.onSelectModule(new CustomEvent('select-module',{detail:{index:index,module:props.modules[index]}}))
        // ref.current.dispatchEvent(new CustomEvent('select-module',{detail:{index:index,module:props.modules[index]}}))
    }

    function onClickBtmDrawer(e){
        setDrawerOpen(!drawerOpen)
    }

    const onExpandSubs =useCallback(evt=>{
        console.log(evt)
        let openEle = refBtmDrawer.current.querySelector(`.${styles.controlsMenuLink}[open]:not([index="${evt}"])`)
        if(openEle){
            openEle.dispatchEvent(new CustomEvent('close'))
        }
    },[])

    return (

            <div className={`${styles.controlsContainer}`}  ref={ref}>
            <div className={`${styles.controlsTop}`} onMouseDown={onDown} onTouchStart={onDown} >
            
            <Close onClick={onClickClose}></Close>
            </div>

            <div className={`${styles.controlsBtm}`} >

                <div className={`${styles.controlsPill}`}>
                    <ArrowLeft onClick={onClickLeft}></ArrowLeft>
                    <ArrowRight  onClick={onClickRight}></ArrowRight>
                </div>
               
            </div>
            <div className={`${styles.controlsBtmDrawer}`} ref={refBtmDrawer}>
                <div className={`${styles.controlsMenuContainer} hide-scroller`}>
                
                {
                props.menuItems && props.menuItems.map((val,index)=>{
                    return(
                    <NarrativeMenuItem
                    model={val}
                    index={index}
                    key={`nar-ctls-menu-link-${index}`} 
                    onClickLink={ onClickLink } 
                    onExpand={onExpandSubs}
                    className={styles.controlsMenuLink}
                    theme={props.theme}
                    style={Globals.instance().getThemeStyle(['nav-menu-item'],props.theme)}
                    ></NarrativeMenuItem>
                    
                    )
                })

                    // props.model &&  props.model.menu_links && props.model.menu_links.map((val,index)=>{
                    //     return(
                    //     <React.Fragment key={`fragcntls-menu-link-${index}`}>
                    //         {val.label && 
                    //             (<div index={index} key={`cntls-menu-link-${index}`} onClick={onClickLink} className={`${styles.controlsMenuLink}`} >{val.label}</div>)
                    //         }
                    //     </React.Fragment>
                        
                    // )
                    // })
                }
                </div>
                <div className={`${styles.controlsModuleLinks}`} >
                        {   icons.map((val,index)=>{
                            return (
                                <div className={`${styles.controlsModuleLink}`} key={`nar-controls-mode-module-${index}`} index={index} 
                                onClick={onClickModule}> 
                                   {val}
                                </div>
                            )
                        }) 
                        }
                </div>
                <div className={`${styles.controlsMenuGrabber}`} onClick={(e) => onClickBtmDrawer(e)}><Dragger id="controlsDragger"></Dragger></div>
            </div>
        </div>
    )


})

export default Controls