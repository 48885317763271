
import React,{forwardRef,useRef,useEffect,memo, useCallback} from 'react'
import styles from './modules.module.css'
import SampleModule from './sample/sample'
import gsap,{Power3} from 'gsap'
import StackModule from './stack/'
import MapModule from './map/'
import GalleryModule from './gallery/'
import WebGLModule from './webgl'
// import VRModule from './VR/'
import DocumentsModule from './document'
import ImageModule from './image/'
import VideoModule from './video/'
import MapBoxModule from './mapbox/'
import Globals from 'system/Globals'
import postEvent from 'system/components/UserEvent';

const ModuleContainer =(props)=>{
    const refEle = useRef()
    
    const refModuleContainer = useRef()
    const tempid=(new Date()).getTime()
    const refModule = useRef()

    const  closeModule=useCallback(()=>{
        if(!refEle.current) { console.log("nil ref on close"); return }  
        gsap.to(refEle.current.firstElementChild,{duration:0.5,opacity:0, ease:Power3.easeInOut})
        gsap.to(refModuleContainer.current.firstElementChild,{duration:0.5,opacity:0,top:'80%', ease:Power3.easeInOut,onComplete:(close)=>{
            close()
        },onCompleteParams:[props.closeModule]})
    },[props.closeModule])


    function preAnimateWindowIn(){
        refEle.current.style.visibility='hidden'
        refModuleContainer.current.style.visibility='hidden'
        refEle.current.firstElementChild.style.visibility='hidden'    
    }

    useEffect(()=>{
        
        if(!refEle.current) { console.log("nil ref el"); return }  

        refEle.current.style.visibility='visible'
        
        
    },[closeModule, refEle])

    useEffect(()=>{
        console.log('props', props);
        if (props.username && props.nid) {
            postEvent(props.username, {"event": "page-enter", "page": props.module.typeName, "page_id": props.nid, "search": props.history.location.search, "page_title": props.module.title}, props.market, props.category);
        }
        
        refEle.current.addEventListener('close-module',closeModule)
        let ele =refEle.current
        return ()=>{
            ele.removeEventListener('close-module',closeModule)
            if (props.username && props.nid) {
                postEvent(props.username, {"event": "page-exit", "page": props.module.typeName, "page_id": props.nid, "search": props.history.location.search}, props.market, props.category);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    let animateWindowIn=useCallback(()=>{
        
        return new Promise ((resolve)=>{
            if(!refEle.current) { console.log("nil ele"); return }  
            refEle.current.style.visibility='visible'
            refModuleContainer.current.style.visibility='visible'
            refEle.current.firstElementChild.style.visibility='visible'
            refModuleContainer.current.style.opacity=0
                
            if(props.module.direct!==true){
                gsap.from( refModuleContainer.current,{duration:0.85,top:'95%', ease:Power3.easeInOut,onComplete: async ()=>{
                }})
            }
            gsap.to(refModuleContainer.current,{duration:0.85,opacity:1,ease:Power3.easeInOut,onComplete: async ()=>{}})
            gsap.from(refEle.current.firstElementChild,{duration:0.85,opacity:0, ease:Power3.easeInOut,onComplete:()=>{
                resolve()
            }})
        })
    },[])

    function getModule(){
        
        Globals.instance().trackEvent(props.module.typeName+'-module',{ 'id':props.module.id,'narrative-id':props.nid})
        // window.gtag('event', 'module-'+props.module.typeName, { 'event_category':'modules', 'event_label': 'clicked module '+props.module.typeName, 'value': props.module.typeName});
        console.log("render")
        let ident=props.module.id?props.module.id:tempid
        // console.log(ident)
        if(props.module.typeName==="stack"){
            return <StackModule key={ident} model={props.module} history={props.history} closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls} setShowMarkup={props.setShowMarkup}  ref={refModule}  ></StackModule>
        }
        else if(props.module.typeName==="image"){
            return <ImageModule key={ident} model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls}  setShowMarkup={props.setShowMarkup}  ref={refModule}  ></ImageModule>
        }else if(props.module.typeName==="video"){
            return <VideoModule key={ident} model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls} setShowMarkup={props.setShowMarkup}  ref={refModule}  ></VideoModule>
        }
        else if(props.module.typeName==="map"){
            return <MapModule key={ident} model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls} setShowMarkup={props.setShowMarkup}  ref={refModule}  ></MapModule>
        }else if(props.module.typeName==="mapbox"){
            return <MapBoxModule key={ident} model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls} setShowMarkup={props.setShowMarkup}  ref={refModule}  ></MapBoxModule>
        }
        else if(props.module.typeName==="gallery"){
            return <GalleryModule key={ident} model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn} theme={props.theme} setShowDrawControls={props.setShowDrawControls} standard={props.module.standard===false?false:true}  setShowMarkup={props.setShowMarkup}  ref={refModule}  ></GalleryModule>
        }
        else if(props.module.typeName==="webgl"){
            return <WebGLModule key={  ident } model={props.module} history={props.history}  closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls}  setShowMarkup={props.setShowMarkup}  ></WebGLModule>
        }
        else if(props.module.typeName==="document"){
            return <DocumentsModule key={ident}model={props.module} history={props.history} closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls}  setShowMarkup={props.setShowMarkup}  ></DocumentsModule>
        }
        return <SampleModule key={ident} model={props.module} history={props.history} closeModule={closeModule} preAnimateWindowIn={preAnimateWindowIn} animateWindowIn={animateWindowIn}  theme={props.theme} setShowDrawControls={props.setShowDrawControls}  setShowMarkup={props.setShowMarkup}  ></SampleModule>
    }

    return (
        <div className={`fullscreen maincontainer ${styles.container}`} ref={refEle}>
            <div className={`${styles.backgroundShadow} fullscreen`} alt=""></div> 
            <div className={`fullscreen ${styles.moduleHolder} `} alt="" ref={refModuleContainer}>
            { props.module &&  getModule()}
            </div> 
        </div>
        )
}


export default memo(ModuleContainer)
