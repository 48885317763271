import React,{forwardRef,useState,useEffect, useRef} from 'react'
import { connect } from "react-redux";
import styles from '../styles/settings.module.scss'
import gsap,{Power3} from 'gsap'
import LoaderWheel from './LoaderWheel'

import axios from 'axios'
import {setUser} from 'store/actions'
import Globals from 'system/Globals'
import store from '../../store/';
import Switch from './Switch'
import ControllerServerComs from 'system/ControllerServerComs';

const Settings = forwardRef((props,ref)=>{
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    
    useEffect(() => {
        gsap.to(ref.current,{duration:0.35,ease:Power3.easeInOut,opacity:0.25})
        gsap.to(ref.current.querySelector('.loader-container svg'),{duration:0.5,scale:1,ease:Power3.easeInOut,opacity:1})
        console.log('props.showContSettings', props)
    }, [])
   

    function getInitSyncValue(){
        let strSync=localStorage.getItem('manualSync')
        return Boolean(strSync!=='false')
    }

    function logout(){
        gsap.to(ref.current,{duration:0.25,ease:Power3.easeInOut,opacity:0,onComplete:(flogout)=>{flogout()}, onCompleteParams:[props.logout]})
    }
    function onChangeSync(val){
        localStorage.setItem('manualSync',JSON.stringify(val))
    }

    function clearControllerIP() {
        ControllerServerComs.instance().postMessageFromControllerToIOS('backToStart','');
    }

    function deleteAccount() {
     
        let comment = {
            username:store.getState().user.username,
            narrative:26,
            location:window.location.hash?window.location.hash.substring(1):window.location.pathname+window.location.search,
            comment:"delete user"
        }
        setLoading(true)
        console.log('comment', comment)
        axios( {
            method: 'post',
            url:Globals.instance().adminAPI+'/api/user-comments-add/',
            data:comment
        }).then(results=>{
            
            setSuccess(true);
            
            
        }).catch(err=>{
            Globals.instance().showAlert('Could not add comment ',err)
            console.error(err)
            setLoading(false)
        })
    }


    function cleariPadCache() {
        setLoading(true);
        gsap.to(ref.current,{duration:0.25,ease:Power3.easeInOut,opacity:0.25});
        window.webkit.messageHandlers.notification.postMessage({ Object: 'clear-ipad-cache' });
      
    }

    function connectToArduino(){
        if(Globals.instance().controllerApp) {
            if (props.userObj && props.userObj.device_sn) {
                ControllerServerComs.instance().sendUDPFromControllerToServer('assign-usb-device', props.userObj.device_sn)
                console.log('passing arduino sn:', props.userObj.device_sn)
            }
        }
    }

    return (
        <React.Fragment>
        <div className={`${styles.settingsContainer}`} ref={ref} >
      
             <div className={`${styles.heading}`}>
                <div className={styles.headingTitle}>Syncing</div>
                <Switch onChange={onChangeSync} selected={getInitSyncValue()}></Switch>
             </div>
             <p>When this is turned off the app will not check for new content when opening narratives</p>

             <div className={`${styles.buildAbout}`}>
             <p>Build</p>
             <p>{Globals.instance().build}</p>
         </div>
         
             <hr></hr> 

             <div className={`${styles.logoutContainer}`}>

             {(Globals.instance().controllerApp || Globals.instance().serverApp) && 
                <React.Fragment>
                <p>Controller using {window.location.host}</p>
                <div className={`round-btn`} onClick={() => clearControllerIP()}> Connect to a new display </div>
               
                {props.userObj && props.userObj.device_sn && <div className={`round-btn`} style={{marginTop: "30px"}}  onClick={() => connectToArduino()}>Connect to Model Lights </div>}
                </React.Fragment>
            }
               
                <div className={`round-btn`} style={{marginTop: "30px"}} onClick={logout}> Logout </div>

                {(Globals.instance().ios)&&
                    <div className={`round-btn`} style={{marginTop: "30px"}} onClick={() => cleariPadCache()}> Purge Synced Data</div>  }


                <h4 style={{top: '20px', position: 'relative'}}>Request Account Deletion</h4>
               
                <p style={{paddingTop: '10px'}}>Submit a request to delete your account. We will confirm this request and then delete your account. Account deletion will purge the system of all account data. We will email you when that process is complete.</p>
               
                {(success)?(<h4>Request successfully submitted</h4>): ((!loading)?<div onClick={deleteAccount} style={{paddingTop: '10px'}}> <u>Delete Account</u></div>:"Sending ...")}
            </div>
        </div>
        {loading && <div style={{position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center"}} >
            <LoaderWheel></LoaderWheel>
        </div>}

    </React.Fragment>            
    )

})


export default Settings

