

import React,{useEffect,useState,useRef,useCallback} from 'react'
import { connect } from "react-redux"

import styles from './markup.module.scss'
import {ReactComponent as IconPlus} from 'assets/icons/plus.svg';
import {ReactComponent as IconClose} from 'assets/icons/close.svg';
import {ReactComponent as IconCaret} from 'assets/icons/caret_down.svg';
import {ReactComponent as Grip} from 'assets/icons/grip.svg'
import axios from 'axios'
import store from '../store/';
import Globals from 'system/Globals';

const Markup=(props)=>{

    const [filter,setFilter]=useState({nid:0, label:'All Comments'})
    const [filters,setFilters]=useState([{nid:0, label:'All Comments'},{nid:0, label:'None'}])
    const [filterMenuOpen,setfilterMenuOpen]=useState(false)
    const [markups,setMarkups]=useState([])
    const [newComment,setNewComment]=useState()
    const [mode,setMode]=useState('')
    const refX=useRef()
    const refY=useRef()
    const refNew=useRef()
    const [loading, setLoading] = useState(false)

    const getHeading = useCallback((m)=>{
        let n = props.narratives.find(n=>{return m.narrative===n.id})
        return n?n.title: ''
    },[props.narratives])

    useEffect(()=>{
        //load comments
        setMode("comments")
    },[])

    useEffect(()=>{
        
        let newfilters=[{nid:0, label:'All Comments'}]
        markups.forEach(m=>{
            if(!newfilters.find((f=>{ return f.label===getHeading(m)}))){
                newfilters.push({nid:m.nid ,label:getHeading(m)})
            }
        })
        newfilters.push({nid:0, label:'None'})
        setFilters(newfilters)

    },[markups,getHeading,setFilters])
    useEffect(()=>{
        if(mode==="comments"){
            setLoading(true)
            axios.get(Globals.instance().adminAPI+'/api/user-comments/'+encodeURIComponent(store.getState().user.username)+'/').then(results=>{
                // console.log(results)
                setMarkups(results.data)
                setLoading(false)
            }).catch(err=>{
                Globals.instance().showAlert('Could not reach comments server',err)
                setLoading(false)
            })
        }
    },[mode])
    function onClickClose(){props.setShowMarkup(false)}
    
    function onChangeComment(evt){setNewComment(evt.target.value)}

    function onCancel(){setMode('comments')}

    function onDelete(id){
        setMode('delete')
        setLoading(true)
        axios( {
            method: 'post',
            url:Globals.instance().adminAPI+'/api/user-comments-delete/',
            data:{id:id}
        }).then(results=>{
             setMode('comments')
        }).catch(err=>{
            Globals.instance().showAlert('Could not remove comment ',err)
            console.error(err)
            setLoading(false)
        })

    }
    function onGoToPage(evt){
        let index = evt.target.getAttribute("index")
        let m = markups[index]
        let path=m.location
        // console.log(path)
        let paramsChar='?'
        if(path.indexOf("#")>-1)
        paramsChar='#'
        let arrloc=m.location.split(paramsChar)
        
        // let search=Globals.instance().useHashRouter?'#':'?'
        path = arrloc[0]
        let search=''
        if(arrloc.length>1)
            search=arrloc[1]
        console.log({pathname:path,search:search})
        let params = new URLSearchParams(search)
      
        params.set('nav', 'closed')
        props.history.push({pathname:path, search: params.toString()})

        console.log('onGoToPage path', path,  params.toString())
        
        window.location.reload();
    }

    function onClickFilterMenu(){ setfilterMenuOpen(!filterMenuOpen)}

    function onSubmit(){
        let comment = {
            username:store.getState().user.username,
            narrative:props.nid,
            location:window.location.hash?window.location.hash.substring(1):window.location.pathname+window.location.search,
            comment:newComment
        }
        setLoading(true)
        
        axios( {
            method: 'post',
            url:Globals.instance().adminAPI+'/api/user-comments-add/',
            data:comment
        }).then(results=>{
            
            setMode('comments')
            
            
        }).catch(err=>{
            Globals.instance().showAlert('Could not add comment ',err)
            console.error(err)
            setLoading(false)
        })

        
    }

    function onDownTop(e){

        if(e.touches){
            refX.current=e.touches[0].clientX
            refY.current=e.touches[0].clientY
        } else{
            refX.current=e.clientX
            refY.current=e.clientY
        }
        let onMove=(evt)=>{
            evt.preventDefault();
            
            let xpos,ypos;
            if(evt.touches){
                xpos=evt.touches[0].clientX
                ypos=evt.touches[0].clientY
            } else{
                xpos=evt.clientX
                ypos=evt.clientY
            }
            let diffX = xpos-refX.current
            let diffY = refY.current-ypos
            let style= getComputedStyle(refNew.current)
            refX.current=xpos
            refY.current=ypos
            
            refNew.current.style.left = (parseFloat(style.getPropertyValue('left'))+diffX)+'px'
            refNew.current.style.top = (parseFloat(style.getPropertyValue('top'))-diffY)+'px'
        }
        let onUp=()=>{
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }
        window.addEventListener('mousemove',onMove, { passive: false })
        window.addEventListener('touchmove',onMove, { passive: false })
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)
    }

    
    
    return <div className={`fullscreen ${styles.container} ${mode}`}> 
           <div className={`${styles.markupsContainer} hide-scroller`}>
           {  mode ==='comments' && !loading &&  markups.filter(f=>{
                if(filter.label==='None') return false
                else if(filter.label==='All Comments') return true;
                else return  (getHeading(f) === filter.label) 
            }).map( (m,index)=>{
              return (
                  <div className={`${styles.markupUIContainer}`} key={`markup-${index}-${m.id}`}>
                    <div className={`${styles.markupHeading}`}>{getHeading(m)} </div>
                    <div className={`${styles.markupComment}`}>{m.comment}</div>
                    <div className={`${styles.newBtmRow}`} >
                        <div className={`${styles.newCancel}`} onClick={onGoToPage} index={index}>Go to Page</div>
                        <div className={`${styles.newSubmit}`} onClick={()=>{onDelete(m.id)}}>Delete</div>
                    </div> 
                  </div>
              )    
           }) }
           
            
            </div>
           { mode ==='comments'  &&(
            <React.Fragment>
                
                <div className={`${styles.uiContainer} ${loading?styles.uiContainerLoading:''}` }>
                    <div  className={`${styles.uiBtn}`} onClick={()=>{setMode('add')}}>
                        <IconPlus></IconPlus>
                    </div>
                    <div className={`${styles.divider}`}></div>
                    <div  className={`${styles.uiCenter} ${filterMenuOpen?styles.uiCenterOpen:''}`} onClick={onClickFilterMenu}>
                        { filterMenuOpen && (
                        <div className={`${styles.uiDropDownContainer}`}>
                            {
                                filters.map((f,index)=>{
                                    return ( 
                                        <div className={`${styles.uiDropDownItem} ${filter.label===f.label?'selected':''}`} key={`key-heading-${index}`} onClick={()=>{setFilter(f)}}>{f.label}</div>
                                    )
                                })
                            }
                        </div>
                        )}
                        <span >{filter.label}</span>
                        <IconCaret></IconCaret> 
                    </div>
                    <div className={`${styles.divider}`}></div>
                    <div  className={`${styles.uiBtn}`} onClick={onClickClose}> 
                        <IconClose></IconClose>
                    </div>
                </div>
            
                <div className={`${styles.commentsContainer}`}>
                    
                </div>
                
            </React.Fragment>
            )}
            
             
            { mode ==='add' && (
                <div className={`${styles.newContainer}`} ref={refNew}>
                    <div className={`${styles.newTopRow}`} onTouchStart={onDownTop} onMouseDown={onDownTop} ><Grip></Grip></div>
                    <div className={`${styles.newInputRow}`} >
                       <textarea value={newComment} onChange={onChangeComment} placeholder="Enter a new Comment"></textarea>
                    </div>
                    <div className={`${styles.newBtmRow}`} >
                        <div className={`${styles.newCancel}`} onClick={onCancel}>Cancel</div>
                        <div className={`${styles.newSubmit}`} onClick={onSubmit}>Submit</div>
                    </div>
                </div>
            )}
    </div>
    
}

const mapStateToProps = state => {
    const {narratives,user} = state
    return { narratives:narratives,user};
};


export default connect(mapStateToProps)(Markup)