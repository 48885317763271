import React,{forwardRef,useEffect,useState,useRef,useCallback} from 'react'
import mstyles from '../modules.module.css'
import styles from './stack.module.scss'
// import LoaderWheel from 'system/components/LoaderWheel'
import {fetchCMSJSON, loadImage,getContentPath, loadContentFile} from 'system/AssetManager'
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import {ReactComponent as PlusButton} from 'assets/icons/plus.svg'
// import {ReactComponent as Chevron} from 'assets/icons/caret_down.svg'
import {ReactComponent as ZoomIn} from 'assets/icons/plus.svg'
import {ReactComponent as ZoomOut} from 'assets/icons/minus.svg'
import gsap,{Power2,Power3} from 'gsap'
import StackDropUp from './StackDropUp'
import StackPlan from './StackPlan'
import Globals from 'system/Globals'
import ToolsMenu from 'system/components/ToolsMenu'
import ModuleContainer from '../ModuleContainer'
import gsapCore from 'gsap/gsap-core'


const StackModule = forwardRef((props, ref)=>{

    let initSearch = new URLSearchParams(props.history.location.search)
    let initFloor = useRef(initSearch.get("floor"))
    let initPlan = initSearch.get("plan")
    let initStack = initSearch.get("stack")?initSearch.get("stack"):null
    
    const refServerAppListener =  useRef()
    const refServerModel =  useRef(null)
    
    //passed png
    if(props.model.data){
        if(props.model.data.stack)initStack=props.model.data.stack 
        if(props.model.data.floor)initFloor.current=props.model.data.floor
        if(props.model.data.plan)initPlan=props.model.data.plan
    }
    // console.log(initStack)

    const [loading, setLoading] = useState(true)
    const [floorModels,setFloorModels] = useState([])
    const [stackModel, setStackModel] = useState(null)
    const [stackModels , setStackModels]=useState([])

    const [selectedStack, setSelectedStack] = useState()
    const [srcSVG, setSrcSVG] = useState()
    const [zoom, setZoom] = useState()
    const [zoomBG, setZoomBG] = useState()
    const [BG, setBG] = useState()
    const [planBG, setPlanBG] = useState()
    const [module,setModule] =useState(null)
    
    // const refLoader =  useRef()
    const refContainer =  useRef()
    const refScroller =  useRef()
    const refScrollVel= useRef(0)
    const refSVGContainer = useRef()
    const refHighlights = useRef([])
    
    const refActivatesNode = useRef()
    const refHighlightsNode = useRef()
    const refZoomPlan =useRef()
    const refDown = useRef(false)
    const timerScroller =useRef()
    const refScrollToIndex=useRef(-1)

  
    const setStack =useCallback( async (stackmodel)=>{
       
        let res= await loadContentFile(stackmodel.base)
        setFloorModels([])
        setStackModel(stackmodel)
        setSrcSVG(res)
        console.log("set stack" ,stackmodel)

        if(stackmodel.plan_background){

            let format= Globals.instance().getFormat(stackmodel.plan_background)
            if(format)
                setPlanBG(getContentPath(format.file))
                
        }else{
            setPlanBG(null)
        }
        
        let params = new URLSearchParams(props.history.location.search)
        params.set("stack",stackmodel.id)
        params.delete("floor")
        params.delete("plan")
        if(initPlan!==null)
        params.set("iplan",initPlan)
        props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
       
    },[initPlan, props.history])
    // console.log("RENDER")
    
    let selectFloor = useCallback(async (flr)=>{
        let arrModels = [...floorModels]
        
        //check if you have selected highlights node then swap 
        let hIndex = arrModels.findIndex(h=>{ return h.floor===flr})
       
        //new selection
        if(hIndex===-1 ){
            
            //removeActived
            let index = arrModels.findIndex(m=>{return m.state==="active"})
            
            let fmi = stackModel.floors.findIndex(m=>{  return m.floor===flr})
           
            if(fmi===-1) return
           
            let m = {floor:flr,floorModelIndex: fmi ,state:"active",planIndex:0}
            
            
            if(index>-1)
                arrModels[index]=m
            else
                arrModels.push(m)
        }
        else 
        {
            console.log("highlighting")
            let index = arrModels.findIndex(m=>{return m.state==="active"})
            arrModels[index].state="highlighted"
            arrModels[hIndex].state="active"   
        }
        console.log(arrModels)
       
        setFloorModels(arrModels)
        let params = new URLSearchParams(props.history.location.search)
        params.set("floor",flr)
        params.delete("plan")

        if(refServerModel.current){ params.set("iplan",refServerModel.current.plan) }
        props.history.push({ pathname:props.history.location.pathname, search:params.toString()})
       
    },[floorModels, props.history, stackModel])


    let onSelectStack=useCallback((i)=>{
        if(String(selectedStack)!==String(i)) {
            console.log("setting new stack "+i)
            setSelectedStack(i)
            // console.log(i, stackModels)
            setStack(stackModels[i])
        }
    },[selectedStack, setStack, stackModels])

    let onServerSelectModule=useCallback(()=>{
        console.log(stackModel)
        if(stackModel && stackModel.id !== refServerModel.current.stack){
            console.log('setting stack ')
            let sindex = stackModels.findIndex(m=>{ return m.id===refServerModel.current.stack })
            console.log(sindex)
            if(sindex>=0){
                onSelectStack(sindex)
            }
        }else{
            console.log('setting floor and pland '+refServerModel.current.floor)
            Array.from(refActivatesNode.current.querySelectorAll(`[active="true"]`)).forEach(ele=>{ele.setAttribute("active","false")})
            selectFloor(refServerModel.current.floor)
        }
    },[onSelectStack, selectFloor, stackModel, stackModels])

    useEffect(()=>{
        
        props.preAnimateWindowIn()
        refContainer.current.style.display='block'
        
        ref.current.firstElementChild.style.display='block'

        if(props.model.background){
            setBG(getContentPath(props.model.background))    
        }
        //preload stack models
        let ps=[]
        
        props.model.stacks.forEach(async s =>{  ps.push(fetchCMSJSON(s.content))})

        Promise.all(ps).then((smodels)=>{
            setStackModels(smodels)

            //set initial stack by index
            let stackindex = smodels.findIndex(s=>{return s.id===initStack})
            if(stackindex===-1 || stackindex ===null )stackindex=0
            
            setSelectedStack(stackindex)
            setStack(smodels[stackindex])
        })

        return ()=>{
            console.log("kill stack")
            cancelAnimationFrame(timerScroller.current)
            window.removeEventListener('server-event', refServerAppListener.current)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    let generateNewPlan= useCallback(()=>{
        let flr=""
        let fmi=0 
        if(stackModel.landing){
            flr = stackModel.landing
            fmi=stackModel.floors.findIndex(m=>{return m.floor===flr})
        }
       return  {floor:flr,floorModelIndex: fmi ,state:"highlighted",planIndex:0}
    },[stackModel])
    
    let onClickAddPlan=useCallback(()=>{
        let arrPlans= [...floorModels]
        arrPlans.push(generateNewPlan())
        setFloorModels(arrPlans)
        refScrollToIndex.current=arrPlans.length-1
        
    },[floorModels, generateNewPlan])

    /* SERVER EVENT CODE */
    useEffect(()=>{
        if(Globals.instance().serverApp){
            window.removeEventListener('server-event', refServerAppListener.current)
            const serverAppListener = (evt)=>{
                let detail = evt.detail
                if(!detail)return
               
                if(detail.event==='stack-add-plan'){ 
                    let index =  Number(detail.command)
                    let arrPlans= [...floorModels]
                    arrPlans.splice(index,0,generateNewPlan())
                    refScrollToIndex.current=index
                    setFloorModels(arrPlans)
                }
                else if(detail.event==='stack-click-stack'){ 
                    onSelectStack(Number(detail.command))
                }
                else if(detail.event==='stack-click-basestack'){ 
                    let ele = refSVGContainer.current
                    gsap.to(refScroller.current,{duration:0.75,scrollTo:{x:ele.offsetLeft} ,ease:Power3.easeInOut})
                    console.log('stack-click-basestack received')

                }
                else if(detail.event==='gallerypopup-close'){ 
                    let elemodule =ref.current.querySelector('.'+mstyles.container)
                    if(elemodule)elemodule.dispatchEvent(new CustomEvent('close-module'))
                    //close zoom 
                    closeZoom()
                }
                else if(detail.event==='stack-zoom-out-plan'){   closeZoom()  }
                else if(detail.event==='stack-scroll-to'){ scrollTo(Number(detail.command)) }
                //close if zooomed
                if(detail.event.indexOf('stack-')===0 && detail.event!=='stack-zoom-in-plan'){
                    closeZoom()
                }
                
                
            }
            refServerAppListener.current=serverAppListener
            window.addEventListener('server-event', refServerAppListener.current)
            }
            return ()=>{
                window.removeEventListener('server-event', refServerAppListener.current)
            }   
    },[floorModels, generateNewPlan, onClickAddPlan, onSelectStack, onServerSelectModule, ref, stackModel])
    
    useEffect(()=>{
        if(zoom){
            if(refZoomPlan.current){
                refZoomPlan.current.style.opacity=0;
                gsap.to(refZoomPlan.current,{duration:0.35,ease:Power2.easeInOut,opacity:1})
            }
            else{
                gsap.to(refZoomPlan.current,{duration:0.35,ease:Power2.easeInOut,opacity:0})
            }
        }
    },[zoom])

    const closeZoom =()=>{
       
            if(refZoomPlan.current)
                gsap.to(refZoomPlan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut,onComplete:()=>{
                setZoom(null)
                setZoomBG(null)
                }})
            else{
                setZoom(null)
                setZoomBG(null)
            }
        
    }

    //resize svg to auto width
    useEffect(()=>{
        if(!srcSVG)return
        refHighlightsNode.current=null
        refActivatesNode.current=null
        refHighlights.current=[]
        
        if(refSVGContainer.current){
            refSVGContainer.current.querySelector('svg').style.width='auto'
            
            refSVGContainer.current.querySelector('svg').style.height=refSVGContainer.current.clientHeight+'px'
            refSVGContainer.current.parentElement.style['min-width']=window.getComputedStyle(refSVGContainer.current.parentElement).getPropertyValue('width')

            // 'auto';//window.getComputedStyle(refSVGContainer.current.parentElement).getPropertyValue('min-width')
            // refSVGContainer.current.parentElement.style.width=refSVGContainer.current.parentElement.clientWidth
            // console.log(refSVGContainer.current.parentElement)
            
            let arrlayers = ['highlights','actives']
            arrlayers.forEach(l=>{
                
                let layer  = refSVGContainer.current.querySelector('g[layer=\''+l+'\']')
                
                if(l==='highlights')refHighlightsNode.current=layer

                if(l==="actives")refActivatesNode.current=layer
                
                Array.from(layer.children).forEach(ele => {
                    let flrId = ele.getAttribute('floor')
                    if (flrId) {ele.style.display = 'none'}
                })

            })
            let clayer =refSVGContainer.current.querySelector('g[layer=\'clicks\']')
            if(clayer){ clayer.style.opacity=0}
            
            if(stackModel){
                let landing = stackModel.floors[0].floor
                if(refServerModel.current){
                    console.log("server model" ,refServerModel.current.floor )
                    landing = refServerModel.current.floor;
                    initFloor.current = null
                }
                else if(initFloor.current)
                {
                    console.log('set floor init ',initFloor)
                    landing = initFloor.current
                    initFloor.current = null
                    
                }else if (stackModel.landing){
                    landing = stackModel.landing
                }
                
                selectFloor(landing)
            }

            

        }   

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[srcSVG])


    useEffect(()=>{
        if(!refActivatesNode.current || !refHighlightsNode.current)return
        
        function showNode(ele){
            if(!ele)return
            // console.log('show',ele)
            ele.style.display='block'
            ele.setAttribute('active','true')
            gsap.killTweensOf(ele)
            gsap.to(ele,{duration:0.5,opacity:1,ease:Power2.easeInOut});
        }
        function hideNode(ele){
            if(!ele)return
            ele.setAttribute('active','false')
            console.log('hide',ele)
            gsap.to(ele,{duration:0.25,opacity:0,ease:Power2.easeInOut, onComplete:(e)=>{e.style.display='none'},onCompleteParams:[ele]});
        }
        
        let elementsActive = Array.from(refActivatesNode.current.querySelectorAll(`[active="true"]`))
        let elementsHighlighted = Array.from(refHighlightsNode.current.querySelectorAll(`[active="true"]`))

        floorModels.forEach((m)=>{
            // console.log(m.floor)
            if(m.state==="active"){
                showNode(refActivatesNode.current.querySelector(`[floor="${m.floor}"]`))
                let index = elementsActive.findIndex(e=>{return e.getAttribute('floor')===m.floor})
                if(index>-1)elementsActive.splice(index,1)
            }
            else if(m.state==="highlighted"){
               showNode (refHighlightsNode.current.querySelector(`[floor="${m.floor}"]`))
               let index = elementsHighlighted.findIndex(e=>{return e.getAttribute('floor')===m.floor})
               if(index>-1)elementsHighlighted.splice(index,1)
            }
        })
        elementsActive.forEach(ele=>{hideNode(ele)})
        elementsHighlighted.forEach(ele=>{hideNode(ele)})

    },[floorModels,refActivatesNode,refHighlightsNode])

    useEffect(()=>{
        
        cancelAnimationFrame(timerScroller.current)
    },[stackModel])

    function onClickClose(evt){
        
        let params = new URLSearchParams(props.history.location.search)
        params.delete("stack")
        params.delete("floor")
        params.delete("plan")
        params.delete("iplan")
        props.history.push({pathname:props.history.location.pathname,search:params.toString()})
        cancelAnimationFrame(timerScroller.current)
        props.closeModule()
    }

    /* scroll to latest */
    useEffect(()=>{
        if(refScrollToIndex.current >=0){
            scrollTo(refScrollToIndex.current)
            refScrollToIndex.current=-1
        }
    },[floorModels])

    function onUpSVG(evt){
        let ele
        if (evt.touches) { ele = document.elementFromPoint(evt.touches[0].pageX, evt.touches[0].pageY) } else { ele = document.elementFromPoint(evt.pageX, evt.pageY) }
        let floor = ''
        do {
            if (ele.hasAttribute('floor')) { floor = ele.getAttribute('floor') }
            else
                ele = ele.parentElement
        } while (ele && floor === '')
        
        if(ele){ selectFloor(floor)}
    }
    function onMoveSVG(evt){
        let ele
        if (evt.touches) { ele = document.elementFromPoint(evt.touches[0].pageX, evt.touches[0].pageY) } else { ele = document.elementFromPoint(evt.pageX, evt.pageY) }
        let floor = ''
        do {
            if (ele.hasAttribute('floor')) { floor = ele.getAttribute('floor') }
            else
                ele = ele.parentElement
        } while (ele && floor === '')
        if(ele){
            // showNode(ele)
        }
    }

  
   
    function onChangeFloorPlan(index,floor){
        let arr = [...floorModels]
        let fmi = stackModel.floors.findIndex(m=>{return m.floor===floor})
        
        arr[index].planIndex = 0
        arr[index].floor=floor
        arr[index].floorModelIndex=fmi
        
   
        setFloorModels(arr)

    }

    async function onZoom(plan,planBG){
        try{
            await loadImage(getContentPath(plan))
            setZoom(getContentPath(plan))
            // console.log(planBG)
            if(planBG){
            await loadImage(getContentPath(planBG))
            setZoomBG(getContentPath(planBG))
            }
        }catch(e){
            console.error(e)
        }
    }
    function onMouseDownScroller(evt){
        let posX=evt.clientX
        refDown.current=true
        
        let desX = refScroller.current.scrollLeft
        // let scrollPos =refScroller.current.scrollLeft
        
        timerScroller.current = cancelAnimationFrame(timerScroller.current)
        // var timeTarget=Date.now()+1000/60;

        let fAnimation =()=>{
            if(!refScroller.current)return
            let scrollPos = refScroller.current.scrollLeft
                // if(Date.now()<=timeTarget){
                    // console.log(refScrollVel.current)
                   scrollPos += -refScrollVel.current
                   refScroller.current.scrollLeft = Math.round( scrollPos)
                   refScrollVel.current= refScrollVel.current * 0.85 //decay
                //    console.log(refScroller.current.scrollLeft)
                //    timeTarget+=1000/60;
                //    if(Date.now()>=timeTarget){
                //     timeTarget=Date.now();
                //    }
            //    }
               if(!refDown.current && Math.abs(refScrollVel.current) < .0004){
                    refScrollVel.current=0
                    cancelAnimationFrame(timerScroller.current)
                }
                else{
                    timerScroller.current = requestAnimationFrame(fAnimation)
                }
           }

        
        let listenerUp = ()=>{
            window.removeEventListener('mouseup',listenerUp)
            window.removeEventListener('touchup',listenerUp)
            window.removeEventListener('mouseleave',listenerUp)
            window.removeEventListener('touchcancel',listenerUp)
            window.removeEventListener('mousemove',listenerMove)
            refDown.current=false
        }

        let listenerMove = (evtMove)=>{
            if(refDown.current){
                let diffX = (evtMove.clientX - posX)
                desX =desX - (evtMove.clientX - posX)
                posX=evtMove.clientX
                refScrollVel.current = diffX *1.5
                
            }
        }
        fAnimation()
        window.addEventListener('mouseup',listenerUp,{passive:true}) 
        window.addEventListener('touchend',listenerUp,{passive:true}) 
        window.addEventListener('mouseleave',listenerUp,{passive:true}) 
        window.addEventListener('touchcancel',listenerUp,{passive:true}) 
        window.addEventListener('mousemove',listenerMove,{passive:true})
    }

    function onClickCloseZoom(){
        // gsap.to(refZoomPlan.current,{duration:0.35,opacity:0,ease:Power3.easeInOut,onComplete:()=>{
        //     setZoom(null)
        //     setZoomBG(null)
        // }})
        closeZoom()
    }
    function onDownZoomPlan(e){
        let clientX = e.touches?e.touches[0].clientX:e.clientX
        let clientY = e.touches?e.touches[0].clientY:e.clientY
        let eleImg= refZoomPlan.current.firstElementChild
        
        let onMove=(evt)=>{
            // evt.preventDefault();
            
            let xpos = evt.touches?evt.touches[0].clientX:evt.clientX
            let ypos = evt.touches?evt.touches[0].clientY:evt.clientY
            let diffX = xpos-clientX
            let diffY = clientY-ypos
            let style= getComputedStyle(eleImg)
            if(!eleImg.classList.contains('zoomed'))return 
            eleImg.style.left = (parseFloat(style.getPropertyValue('left'))+diffX)+'px'
            eleImg.style.top = (parseFloat(style.getPropertyValue('top'))-diffY)+'px'
            
            let scale = 2
            let maxX = ((scale*parseFloat(style.getPropertyValue("width")))-window.innerWidth)/2
            let maxY = ((scale*parseFloat(style.getPropertyValue("height")))-window.innerHeight)/2
            
            if(parseFloat(style.getPropertyValue('left'))< -maxX ){
                eleImg.style.left = -maxX+'px'
            }else if(parseFloat(style.getPropertyValue('left'))> -maxX +window.innerWidth){
                eleImg.style.left = (-maxX +window.innerWidth)+'px'
            }
            if(parseFloat(style.getPropertyValue('top'))< -maxY ){
                eleImg.style.top = -maxY+'px'
            }else if(parseFloat(style.getPropertyValue('top'))> -maxY +window.innerHeight){
                eleImg.style.top = (-maxY +window.innerHeight)+'px'
            }
            clientX=xpos;
            clientY=ypos;
        }
        let onUp=()=>{
            window.removeEventListener('mousemove',onMove)
            window.removeEventListener('touchmove',onMove)
            window.removeEventListener('mouseleave',onUp)
            window.removeEventListener('mouseup',onUp)
            window.removeEventListener('touchend',onUp)
            window.removeEventListener('touchcancel',onUp)
        }
        window.addEventListener('mousemove',onMove)
        window.addEventListener('touchmove',onMove)
        window.addEventListener('mouseup',onUp)
        window.addEventListener('mouseleave',onUp)
        window.addEventListener('touchend',onUp)
        window.addEventListener('touchcancel',onUp)

    }
    function onClickZoomIn(){
        let img = refZoomPlan.current.querySelector('.zoom-plan')
        img.classList.add('zoomed')
        gsap.to(img,{duration:0.5,scale:2,ease:Power3.easeInOut})
    }
    function onClickZoomOut(){
        let img = refZoomPlan.current.querySelector('.zoom-plan')
        img.classList.remove('zoomed')
        gsap.to(img,{duration:0.5,scale:1,left:0,top:0,ease:Power3.easeInOut})
    }
    function removePlan(index){
        let items=[...floorModels]
        items.splice(index,1)
        // console.log(index,[...floorModels].splice(index,1))
        setFloorModels(items)
    }

    function onLoadPlanBG(evt){
        // gsap.to(evt.target,{opacity:1,ease:Power3.easeInOut,duration:0.5})
    }
    function onLoadStackPlan(index){
        
        if(index===0 && loading){

            setLoading(false)
            gsap.killTweensOf(ref.current.querySelector('.plan-container'))
            gsap.set(ref.current.querySelector('.plan-container'),{opacity:0})
            let elePlanBG =refContainer.current.querySelector('.'+styles.planBG)
            if(elePlanBG){
                // elePlanBG.style.display='block'
                gsap.set(ref.current.querySelector('.plan-container'),{opacity:0,x:-50})
            }
            
            props.animateWindowIn().then(()=>{
                let elePlanBG =refContainer.current.querySelector('.'+styles.planBG)
                if(elePlanBG){
                    elePlanBG.style.display='block'
                    gsap.fromTo(elePlanBG,{opacity:0, x:-50},{opacity:1,x:0,ease:Power3.easeInOut,duration:1,delay:0.0})
                }
                gsap.set(refSVGContainer.current,{x:"-50px"})
                gsap.to(refSVGContainer.current,{opacity:1,x:0,ease:Power3.easeInOut,duration:0.5, delay:0.2})
                gsap.killTweensOf(ref.current.querySelector('.plan-container'))
                // gsap.set(ref.current.querySelector('.plan-container'),{opacity:0})
                gsap.fromTo(ref.current.querySelector('.plan-container'),{opacity:0, x:-50},{opacity:1,x:0,ease:Power3.easeInOut,duration:0.5,delay:0.4})
            }) 
        }
    }

    function scrollTo(index){
        let ele = refScroller.current.querySelector(".plan-container[index=\""+index+"\"]")
        if(ele) gsap.to(refScroller.current,{duration:0.75,scrollTo:{x:ele.offsetLeft} ,ease:Power3.easeInOut})

        
    }
    
    return (
        <div className={`fullscreen fcenter` } style={{'background':'var(--primary-background)'}} ref={ref} >
            
            <div className={`fullscreen `  } ref={refContainer}>
            { 
                BG && ( <img src={BG} className={`${styles.bg} fullscreen`} alt=""></img>)
            }            
            
            {
                stackModel && (
                    <div className={`${styles.stackScroller} fullscreen hide-scroller force3d `} onMouseDown={onMouseDownScroller} ref={refScroller}>
                        <div className={`${styles.container} stack-container`} plans={floorModels.length}>
                            <div className={`${styles.leftContainer}`} style={{minWidth:stackModel.svg_width?stackModel.svg_width:'auto'}}>
                                { planBG && ( <img src={planBG} className={styles.planBG} onLoad={onLoadPlanBG} alt=""></img>)}            
                                <div className={`${styles.srcSVG}`} ref={refSVGContainer} alt="" dangerouslySetInnerHTML={{__html:srcSVG}} onMouseMove={onMoveSVG} onMouseUp={onUpSVG}></div>
                                <div className={`${styles.selectorContainer}`}>
                                    { props.model.stacks  && props.model.stacks.length > 1 && (
                                    <StackDropUp selectItem={selectedStack} keyName={'stack-selector'} items={props.model.stacks} 
                                    onSelect={onSelectStack} theme={props.theme}
                                    themeHeader={Globals.instance().getThemeStyle(['mod-stack-drop-down-header'],props.theme)}
                                    themeItem={Globals.instance().getThemeStyle(['mod-stack-drop-down-item'],props.theme)}
                                    themeContainer={Globals.instance().getThemeStyle(['mod-stack-drop-down-container'],props.theme)}
                                    ></StackDropUp>)
                                    }
                                </div>
                            </div>

                            { 
                            floorModels.map((val,index)=>{
                                return (
                                   <StackPlan stackModel={stackModel} 
                                   index={index}
                                   history={props.history}
                                   floorIndex={val.floorModelIndex} 
                                   floorModel={stackModel.floors[val.floorModelIndex]} 
                                   selectedStack={selectedStack} 
                                   key={`stack-plan${selectedStack}-${index} `} 
                                   onZoom={onZoom}
                                   onClose={removePlan}
                                   theme={props.theme}
                                   setModule={setModule}
                                   many={floorModels.length}
                                   hideClose={ (index===0 && floorModels.length===1)?1:0}
                                   onload={onLoadStackPlan}
                                   onChangeFloor={onChangeFloorPlan}></StackPlan>
                                )
                            })
                            }
                        </div>
                    </div>    
                )
            }
              { stackModel  && (
               <div className={`${styles.btnAddPlan} ${(floorModels.length < 4)?'active':'inactive'} outter-round-btn`} onClick={onClickAddPlan}
                style={Globals.instance().getThemeStyle(['mod-stack-btn-add-plan'],props.theme)}>
                   
                <div className={'round-btn'}><PlusButton className={'themeSVGFill'}></PlusButton></div>
            </div>
                )}
            <div className={`${mstyles.closeBtnContainer} ifServerHide`}>
                <ToolsMenu theme={props.theme} setShowDrawControls={props.setShowDrawControls} model={props.model} setShowMarkup={props.setShowMarkup} location={props.history.location}></ToolsMenu>
                
                <div className={`round-btn`} onClick={onClickClose} >
                    
                    <CloseButton></CloseButton>
                </div>
            </div>

            { zoom && (
                <div className={`fullscreen ${styles.zoomPlanContainer}`} ref={refZoomPlan}>
                    {zoomBG &&(
                        <img src={zoomBG} alt="" className={`fullscreen ${styles.zoomPlanBG}`} ></img>
                    )}
                    <img src={zoom} alt="" className={`fullscreen zoom-plan ${styles.zoomPlan}`} onMouseDown={onDownZoomPlan} onTouchStart={onDownZoomPlan}></img>
                    
                    <div className={`${styles.zoomControlsContainer} ifServerHide`}>

                        <div className={`round-btn ${styles.closePopUp}`} onClick={onClickCloseZoom}>
                            <CloseButton></CloseButton>
                        </div>

                            <div className={`${styles.zoomControls}`} >
                            <ZoomOut onClick={onClickZoomOut}></ZoomOut>
                                <div className={`${styles.separator}`}></div>
                                <ZoomIn onClick={onClickZoomIn}></ZoomIn>
                                
                            </div>
                     
                      
                    </div>
                    
                </div>
            )}
        </div>

            { module && (
                <ModuleContainer
                module={module} 
                history={props.history} 
                theme={props.theme} 
                setShowDrawControls={props.setShowDrawControls} 
                key='webgl-module-container'
                closeModule={()=>{
                    setModule(null)
                }}
                ></ModuleContainer>
            )} 

        </div>
    )

})

export default StackModule
