
import React,{useEffect,useRef,useState} from 'react'
import {loadContentFile} from  'system/AssetManager'
import NarrativeMenuItem from 'narrative/NarrativeMenuItem'
import Globals  from 'system/Globals'

import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import ControllerServerComs from 'system/ControllerServerComs'
import ControllerJoystick from './ControllerJoystick'

import {ReactComponent as ZoomIn} from 'assets/icons/plus.svg'
import {ReactComponent as ZoomOut} from 'assets/icons/minus.svg'
import ControllerGalleryPopup from './ControllerGalleryPopup'


const ControllerWebGL = (props)=>{
    const [config,setConfig] = useState()
    const [selected,setSelected] = useState(0)
    const [icons,setIcons] = useState([])
    const [styleMenu,setStyleMenu]=useState({})
    const [styleMenuSelected,setStyleMenuSelected]=useState({})
    const [currentModule,setCurrentModule] = useState(null)
    const [activePage, setActivePage] = useState(null);
    
    const refPopup = useRef()
    
    
    function setIconsFromViewIcons(icons){
        let arrIcons = []
        if(!props.module.markers)return
        icons.forEach((i)=>{

            let mi= props.module.markers.findIndex((m)=>{return m.key===i.image})
            if(mi >=0){
            let m = props.module.markers[mi]
                if(m && m.files.length > 0){
                    m.typeName="gallery"
                    m.standard=false
                    m.name=i.name
                    m.markerindex=mi
                    arrIcons.push(m)
                }
            }
        })
        setIcons(arrIcons)
        console.log(arrIcons)
    }
    useEffect(()=>{
        setActivePage(props.activeNarrativePageIndex);
        // props.setActiveNarrativePageIndex(null);
        let load= async ()=>{
            
            let res = await loadContentFile(props.module.config)
            setConfig(res)
            let v = res.views[0]
            // setCurrentView(v)
            setIconsFromViewIcons(v.icons)
            setSelected(0)
        }
        load()  
        setStyleMenu(Globals.instance().getThemeStyle(['nav-menu-item'],props.theme))
        setStyleMenuSelected(Object.assign({},Globals.instance().getThemeStyle(['nav-menu-item'],props.theme),{color:'var(--primary-accent)', '--hover-color': 'var(--primary-accent)'}))
              
                
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{console.log(config)},[config])
    
    function onClickView (evt){
        let index=Number(evt.currentTarget.getAttribute('index'))
        console.log(index)
        setSelected(index)
        let v = config.views[Number(index)]
        console.log(v)
        // setCurrentView(v)
        setIconsFromViewIcons(v.icons)
        setCurrentModule(null)
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-webgl-select-view',v.category+','+index)   

    }
    function onClickMarker(evt){
        let index=Number(evt.currentTarget.getAttribute('index'))
        let marker = icons[Number(index)]
        console.log(marker)
        if(currentModule && currentModule.key === marker.key){
            setCurrentModule(null)
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-webgl-close-marker','')   
            return
        }

        ControllerServerComs.instance().sendUDPFromControllerToServer('module-webgl-select-marker',marker.markerindex)   
        setCurrentModule(marker)
     
    }
    function onZoomIn(){ ControllerServerComs.instance().sendUDPFromControllerToServer('module-webgl-zoom-in','')   }
    function onZoomOut(){ ControllerServerComs.instance().sendUDPFromControllerToServer('module-webgl-zoom-out','') }  


    const onClickBack = () => {
        console.log('onClickBack') 
        props.setCurrentModule({typeName: 'controller-narrative'});
        props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');
     }
     
   
    return (
        <React.Fragment>
        <div className={`${styles.linksContainer} hide-scroller`} >
                <h3>3D Model</h3>
                <div className={`${styles.linksScrollContainer} hide-scroller`} >   
                            
                            {
                              config &&  config.views.map((view,index)=>{
                                   return(
                                    <NarrativeMenuItem
                                    model={{index,label:view.name}}
                                    index={index}
                                    key={`ctrl-stack-menu-link-${index}`} 
                                    onClickLink={ onClickView } 
                                    onExpand={()=>{}}
                                    className={nstyles.menuLink+' '+styles.controllerMenuLink }
                                    theme={props.theme}
                                    style={selected===index ? styleMenuSelected : styleMenu}
                                     ></NarrativeMenuItem>
                                   )
                               })
                           }
                           </div>
                          
         </div>

        { 
                 
         <section className={`${styles.moduleHolder} fcenter `}>
            {props.showBackBtn && <div className={`${styles.backBtnRow}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Narrative</div>
                </div>}
            
             <ControllerJoystick  sensitivity={0.25} />

             { icons &&  (
                           <div className={`hi ${styles.webglMarkerContainer}`}>
                                <div className={`${styles.webglMarkerContainerButtons} ${styles.stateButtons}`}>
                                {icons.map((icon,i) => (
                                    <div className={`${styles.stateBtn} ${styles.webglMarkerButton}`} 
                                        key={"webgl-marker-" + i} 
                                        onClick={onClickMarker}
                                        index={i}
                                        mindex={icon.markerindex}
                                        image={icon.image}
                                        active={currentModule && icon.markerindex===currentModule.markerindex ? 'true':'false'}
                                        >
                                        {icon.name}
                                    </div>
                                ))}
                                </div>
                                <div className={`${styles.zoomControlsContainer}`}>
                                   
                                    <div className={`${styles.zoomControls}`} >
                
                                        <ZoomOut onClick={() => onZoomOut()} />
                          
                                        <div className={`${styles.separator}`}></div>
                                    
                                  
                                        <ZoomIn onClick={() => onZoomIn()} />
                                  
                                    
                                    </div>
                                </div>
                            </div>    
            )}

         </section>   

        }
        { currentModule && currentModule.files[0].fileType  && (<ControllerGalleryPopup model={currentModule} ref={refPopup} setCurrentModule={setCurrentModule}></ControllerGalleryPopup>)}
        
     </React.Fragment>
    )

}

export default ControllerWebGL
