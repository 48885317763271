
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'
// import {onErrorLoad} from 'system/AssetManager'

function PNGComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [imgSrc,setImgSrc] = useState(null)
     
    let bLoaded=false
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    
    
    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
        
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        // console.log(refAnimationModel.current)
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt,threshold)=>{
        // console.log('onINTERSECT')
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=threshold)
                animateIntersect(refEle.current,refAnimationModel.current)  
        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        
        let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
        // console.log("seting ",getContentPath(f))

        // console.log("FORMAT", refFormat.current, props.model.formats,props.format)

        setImgSrc(getContentPath(f))
        
        //set up animation Type
        if(refFormat.current.animation ){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
                style.willChange='transform'
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
                style.willChange='transform, scale, opacity'
            }
        }
        setInline(style)
        
        
        return ()=>{}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onError(evt){
        console.error("PNG load error"+bLoaded, evt.target.src)
        // onErrorLoad(evt)
        console.log("src "+evt.target.src)
        if(!bLoaded && imgSrc!==""){
            Globals.instance().trackError('PNG Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
        }
    }
    
    function onLoad(){
        //down res the image
        if(!bLoaded){
            props.onLoad(props.index,refEle.current,props.model)
            bLoaded=true

            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

        }
        
    }
    
    return ( 
            
        <img src={imgSrc} alt="" onLoad={onLoad} style={inline} ref={refEle} className={'force3d'} onError={onError}></img>
            
    )

}

const PNG = memo( PNGComponent ,(prev,next)=>{  return prev.imgSrc === next.imgSr })
export  {PNG}
