import React,{forwardRef,useEffect,useState,useRef} from 'react'
import LoaderWheel from 'system/components/LoaderWheel'
import {getContentPath} from 'system/AssetManager';
import * as THREE from "three";
import galleryStyles from './gallery.module.css';
import { render } from '@testing-library/react';
import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import { animateIntersect } from 'narrative/canvas/CanvasAnimator';

function ThreeVR(props) {
    const refContainer = useRef(null);
    const cameraRef = useRef();
    const [vrLoaded,setVRLoaded] = useState(false);
    

    useEffect(() => {
        if ((props.file !== null) && (props.load === true)) {
            THREE.DefaultLoadingManager.onStart = function ( url, itemsLoaded, itemsTotal ) {
                console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
                setVRLoaded(false);
            };
            
            THREE.DefaultLoadingManager.onLoad = function ( ) {
                console.log( 'Loading Complete!');
                setVRLoaded(true);
            };
            
            THREE.DefaultLoadingManager.onProgress = function ( url, itemsLoaded, itemsTotal ) {
                console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );
            };
            
            THREE.DefaultLoadingManager.onError = function ( url ) {
                console.log( 'There was an error loading ' + url );
            };
        }
    }, [props.file, props.load])

    useEffect(()=>{

        var requestAnimID;

        if (props.load === true) {

          
            console.log('vr props init', props);
            // setVRFilePath(props.file);
            var vrFilePath = getContentPath(props.file);
            
            console.log('filePath init', vrFilePath);
            
            var camera, scene, renderer;
            var isUserInteracting = false, onMouseDownMouseX = 0, onMouseDownMouseY = 0,
            lon = 0, onMouseDownLon = 0,
            lat = 0, onMouseDownLat = 0,
            phi = 0, theta = 0, onPointerDownPointerX = 0,  onPointerDownPointerY = 0, onPointerDownLon = 0, onPointerDownLat = 0;
            
            init(vrFilePath);
            animate();
          
        
            function init(vrFilePath) {
    
    
                console.log('filePath anim', vrFilePath);
                var container, mesh;
                
                container = refContainer.current;
                // console.log('threeVR', container);
                camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 1100 );
                camera.target = new THREE.Vector3( 0, 0, 0 );
                scene = new THREE.Scene();        
                var geometry = new THREE.SphereGeometry( 500, 60, 40 );
                geometry.scale( - 1, 1, 1 );
    
                var material = new THREE.MeshBasicMaterial( {
                    map: new THREE.TextureLoader().load( vrFilePath )
                } );
    
                mesh = new THREE.Mesh( geometry, material );
                scene.add( mesh );
                
                renderer = new THREE.WebGLRenderer({});
                renderer.setPixelRatio( window.devicePixelRatio );
                renderer.setSize( window.innerWidth, window.innerHeight );
                container.appendChild( renderer.domElement );
    
                cameraRef.current = camera;

                document.addEventListener( 'mousedown', onDocumentMouseDown, false );
                document.addEventListener( 'mousemove', onDocumentMouseMove, false );
                document.addEventListener( 'mouseup', onDocumentMouseUp, false );
                document.addEventListener( 'wheel', onDocumentMouseWheel, false );
                window.addEventListener( 'resize', onWindowResize, false );
                document.addEventListener( 'touchstart', onDocumentTouchStart, false );
                document.addEventListener( 'touchmove', onDocumentTouchMove, false );
                document.addEventListener( 'touchend', onDocumentTouchEnd, false );




            }
    
              function onWindowResize() {
                camera.aspect = window.innerWidth / window.innerHeight;
                camera.updateProjectionMatrix();
                renderer.setSize( window.innerWidth, window.innerHeight );
            }
        
            function onDocumentMouseDown( event ) {
                event.preventDefault();
                isUserInteracting = true;
                onPointerDownPointerX = event.clientX;
                onPointerDownPointerY = event.clientY;
                onPointerDownLon = lon;
                onPointerDownLat = lat;
            }
        
            function onDocumentMouseMove( event ) {
                // console.log('onDocumentMouseMove', isUserInteracting);
                if ( isUserInteracting === true ) {
                    lon = ( onPointerDownPointerX - event.clientX ) * 0.1 + onPointerDownLon;
                    lat = ( event.clientY - onPointerDownPointerY ) * 0.1 + onPointerDownLat;
                }
            }
        
            function onDocumentMouseUp( event ) {
                isUserInteracting = false;
            }
        
        
            function onDocumentTouchStart( event ) {
                // event.preventDefault();
                isUserInteracting = true;
                onPointerDownPointerX = event.touches[0].clientX;
                onPointerDownPointerY = event.touches[0].clientY;
                onPointerDownLon = lon;
                onPointerDownLat = lat;
            }
        
            function onDocumentTouchMove( event ) {
                // console.log('onDocumentMouseMove', isUserInteracting);
                if ( isUserInteracting === true ) {
                    lon = ( onPointerDownPointerX - event.touches[0].clientX ) * 0.1 + onPointerDownLon;
                    lat = ( event.touches[0].clientY - onPointerDownPointerY ) * 0.1 + onPointerDownLat;
                }
            }
        
            function onDocumentTouchEnd( event ) {
                isUserInteracting = false;
            }
        
        
            function onDocumentMouseWheel( event ) {
                var currFov = camera.fov;
                var change = event.deltaY * 0.05;
                currFov+=change;
                console.log('onDocumentMouseWheel', event.deltaY);
                if ((currFov < 85) && (currFov > 20)) {
                    camera.fov += change;
                    camera.updateProjectionMatrix();
                } 
            }

            function animate() {
                requestAnimID = requestAnimationFrame( animate );
                update();
            }
        
            function update() {
                lat = Math.max( - 85, Math.min( 85, lat ) );
                phi = THREE.Math.degToRad( 90 - lat );
                theta = THREE.Math.degToRad( lon );
                camera.target.x = 500 * Math.sin( phi ) * Math.cos( theta );
                camera.target.y = 500 * Math.cos( phi );
                camera.target.z = 500 * Math.sin( phi ) * Math.sin( theta );
                camera.lookAt( camera.target );
                renderer.render( scene, camera );
            }

           
    
    
            return () => {
                window.cancelAnimationFrame(requestAnimID);
                document.removeEventListener( 'mousedown', onDocumentMouseDown, false );
                document.removeEventListener( 'mousemove', onDocumentMouseMove, false );
                document.removeEventListener( 'mouseup', onDocumentMouseUp, false );
                document.removeEventListener( 'wheel', onDocumentMouseWheel, false );
                window.removeEventListener( 'resize', onWindowResize, false);
                document.removeEventListener( 'touchstart', onDocumentTouchStart, false );
                document.removeEventListener( 'touchmove', onDocumentTouchMove, false );
                document.removeEventListener( 'touchend', onDocumentTouchEnd, false);     
               
                
            };

        }
       
    }, [props.load, props.file]);

    useEffect(() => {
        if (props.vrZoom) {
            var currFov = cameraRef.current.fov;
            var change = props.vrZoom;
            console.log('change', change, currFov, (currFov + change))
            var vrInterval = setInterval(() => {
                if (((change > 0) && (cameraRef.current.fov  < (currFov+change)))||((change < 0) && (cameraRef.current.fov  > (currFov+change)))) {
                    cameraRef.current.fov+=(props.vrZoom/20); 
                    console.log('fov', cameraRef.current.fov, currFov+change);
                    if ((currFov < 85) && (currFov > 20)) {
                        cameraRef.current.updateProjectionMatrix();
                    } 
                } else {
                    clearInterval(vrInterval);
                    console.log('fov done');
                }
                
            }, 30);



            props.setVrZoom(null);
        }
    }, [props.vrZoom])


    return (
        <div>
            {(vrLoaded)?(null):(<div className={`${galleryStyles.vrLoadingContainer} fullscreen fcenter`}><div><LoaderWheel status={'VR Loading'} ></LoaderWheel></div></div>)}
            {(props.load) ? (<div id="vrContainer" ref={refContainer}></div>) : (null) }
        </div>
        
    )   

}

export default ThreeVR;

