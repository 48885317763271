
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath, loadImage} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState} from './CanvasAnimator'
import Globals from 'system/Globals'

function StatePNGComponent(props){
    
    
    const refLoaded= useRef(false)
    const [inline,setInline] = useState({})
    const [imgSrc,setImgSrc] = useState("")
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    const [selected,setSelected] = useState(props.pageState==props.model.id)
    

    
    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
        animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt)=>{
        if(evt.detail.isIntersecting )
        {
            if(evt.detail.intersectionRatio >=0.5)
                animateIntersect(refEle.current,refAnimationModel.current)  
        }
        else if(evt.detail.intersectionRatio <=0.001)
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
    },[refEle])

    useEffect(()=>{
        
        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
          
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform','cursor':'pointer'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        style.zIndex=10;
        setInline(style)

        let format = selected?'selected':'file'
        let f =refFormat.current[format]?refFormat.current[format]:refFormat.current[format]
        setImgSrc(getContentPath(f))
        
        if(refFormat.current.animation){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
            }
        }
        // return ()=>{}
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    let onClick=useCallback( ()=>{
        if(!selected && typeof(props.setPageState) ==='function')
        props.setPageState(props.model.id)
    },[setSelected,selected])
    
    //update image on selected state
    useEffect( ()=>{
        let run = async ()=>{
        if(refLoaded.current){
            let imgsrc = refFormat.current.file?refFormat.current.file:refFormat.current.image
            if(selected)
                imgsrc=refFormat.current.selected
            try{
              await loadImage(getContentPath( imgsrc))
            } catch(e){  console.error(e) }
            setImgSrc(getContentPath(imgsrc))
        }}
        run()
    },[selected,refLoaded])

    //listen for page state changes 
    useEffect(()=>{ setSelected(props.pageState === props.model.id)},[props])

    function onError(evt){
        
        if(!refLoaded.current && imgSrc!==""){
            console.error("PNG BTN ERROR",evt)
            Globals.instance().trackError('PNG Button Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }

        }
    }
    

    function onLoad(){
        //down res the image
        let img
        if(!refLoaded.current){
            props.onLoad(props.index,refEle.current,props.model)
            refLoaded.current=true
        }
    }
    
    return ( 
        <img src={imgSrc} onError={onError} onClick={onClick} alt="" onLoad={onLoad} style={inline} ref={refEle} className={'force3d'}></img>
    )

}

const statePNGButton = memo( StatePNGComponent ,(prev,next)=>{  return prev.imgSrc === next.imgSrc && prev.pageState === next.pageState })
export  {statePNGButton}
