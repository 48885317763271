import React,{forwardRef,useEffect,useRef} from 'react'
import mstyles from '../modules.module.css'
import LoaderWheel from 'system/components/LoaderWheel'

import {ReactComponent as CloseButton} from 'assets/icons/close.svg'
import gsap,{Power2} from 'gsap'

const SampleModule = (props)=>{
    const refEle = useRef()
    useEffect(()=>{
        
        refEle.current.style.display='block'
        
        gsap.from(refEle.current,{duration:0.5,opacity:0,top:'80%', ease:Power2.easeInOut,onComplete:()=>{
              
        }})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onClickClose(evt){props.closeModule()}

    return (
        <div className={`fullscreen fcenter ${mstyles.moduleHolder}` } ref={refEle}>
        <div className={`fullscreen fcenter`}>
            <LoaderWheel></LoaderWheel>
        </div>
        <div className={`fullscreen fcenter`}>
            <h1>SAMPLE</h1>
        </div>
            <div className={`${mstyles.closeBtnContainer}`}>
                <div className={`${mstyles.btn}`} onClick={onClickClose}>
                    <CloseButton></CloseButton>
                </div>
            </div>

        </div>
    )

}

export default SampleModule
