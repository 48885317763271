import axios from 'axios'
import Globals from './Globals'

export function getContentPath(path, fromCore){
    if(!path) {console.warn("empty path sent"); return "" }
    if( path.indexOf('http')===0)return path

    if(!fromCore && ( Globals.instance().electron || Globals.instance().ios)){
        if(Globals.instance().nid) 
        return `${Globals.instance().nid}/${path}`
    }

    return Globals.instance().contentpath +path;
}


export function getCMSPath(path, fromCore){

    if(Globals.instance().electron || Globals.instance().ios){
        
        let p=path;
        if( path.indexOf('http')===0){
            let i=path.indexOf('/api/')
            p=path.slice(i+5) 
        }    
        if(p.slice(-12)==="?format=json")p=p.slice(0,-12)
        if(p.slice(-1)==='/') p=p.slice(0,-1)
        if(p.slice(-5)!=='.json')p=p+'.json'
        if(Globals.instance().nid && !fromCore) {
            // console.log("return nid version")
            // console.log("returning cms path ",`${Globals.instance().nid}/${p}`)
    
            return `${Globals.instance().nid}/${p}`
        }
    }
    
            
    if( path.indexOf('http')===0)return path //hard coded path goes there no matter what

    // console.log(Globals.instance().cmspath +path)
    // console.log("returning cms path ",Globals.instance().cmspath +path)
    
    return Globals.instance().cmspath +path;
}

export async function fetchCMSJSONs(arr,cancelToken){
    return new Promise((resolve,reject)=>{
    let arrPromises = []
        arr.forEach(val=>{ arrPromises.push(fetchCMSJSON(val,cancelToken))})
        Promise.all(arrPromises).then(values=>{  resolve(values) }).catch( error =>{
            reject(error)
        })
    })
}
export async function fetchCMSJSON(path,cancelToken,fromCore){
    // let obj={}
    // if(cancelToken)obj.cancelToken=cancelToken.token
    return new Promise( (resolve,reject) => {
        axios.get(getCMSPath(path,fromCore)).then(response=>{
            resolve(response.data)
        })
        .catch(error=>{
            console.warn('could not fetch json',path)
            reject({error,path})
        })
    })
}

export async function loadContentFile(src){ 
    return new Promise( (resolve,reject) => {
        axios.get(getContentPath(src)).then(response=>{
            resolve(response.data)
        })
        .catch(error=>{
            console.warn('could not fetch json',src)
            reject({error,src})
        })
    })
}

export async function loadImage(src){ return new Promise( resolve=>{
    let img = new Image() 
    img.src=src
    img.onload=()=>{resolve(img)}
    img.onError=()=>{console.error('Error loading image '+src)}
})}

export async function fetchThemes(cancelToken){ return fetchCMSJSON('core/theme'+(Globals.instance().usecms?'/':'.json'),cancelToken)}
export async function fetchNarratives(){ return fetchCMSJSON('core/narratives'+(Globals.instance().usecms?'/':'.json'))}
export async function fetchNarrativeGroups(){ return fetchCMSJSON('core/narrative-group'+(Globals.instance().usecms?'/':'.json'))}

export function onErrorLoad(err){
    //save this somehow?
    // console.error(err)
    // window.dispatchEvent(new CustomEvent('alert',{details:err.toString}))
    
}