import React from 'react'
import { useEffect,useState,memo,useRef,useCallback } from 'react'
import {connect} from 'react-redux'
import LoaderWheel from 'system/components/LoaderWheel'
import { useParams} from 'react-router-dom';
import axios from 'axios';
import Globals from 'system/Globals';
import {fetchCMSJSON} from 'system/AssetManager'
import {getModuleIcon,isVideo} from "../narrative/NarrativeMenu";
import ControllerServerComs from 'system/ControllerServerComs';
import {getContentPath} from 'system/AssetManager'
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import ControllerMap from './ControllerMap'
import ControllerGallery from './ControllerGallery'
import ControllerWebGL from './ControllerWebGL'
import ControllerStack from './ControllerStack'
import ControllerPresentation from './ControllerPresentation'
import {ReactComponent as IconArrowLeft} from 'assets/icons/arrow_left.svg'
import gsap,{Power3} from 'gsap'
import {useHistory} from 'react-router-dom';


let ControllerNarrativeMain =(props)=>{
    let history = useHistory();
    const refMenu = useRef()
    const refEle = useRef()
    const [theme, setTheme] = useState(null);
    const [status,setStatus] = useState('');
    const [state, setState] = useState('loading');
    const [slidOver, setSlidOver] = useState(false);
    const [icons,setIcons] = useState([])
    const [NarrativeModel, setNarrativeModel] = useState(null);
    const [modules, setModules]=useState([]);
    const [pages, setPages]=useState([]);
    const [activeNarrativePageIndex, setActiveNarrativePageIndex] = useState();
    const [activePage, setActivePage] = useState(null);
    const [currentModule, setCurrentModule]=useState(null);
    const [currentParams, setCurrentParams] = useState(null);
    const [animInCurrentModule, setAnimInCurrentModule] = useState(false);
    const [goToNarrative, setGoToNarrative] = useState(null);
    // const [showBackBtn, setShowBackBtn] = useState(false);
    const refModule=useRef()
    const showBackBtn=useRef()

    let { id } = useParams();
    useEffect(()=>{
        
        if ((props.user && props.user.device_sn)) {
            if (Globals.instance().usbRequested === false) {
                Globals.instance().usbRequested = true;          
                ControllerServerComs.instance().sendUDPFromControllerToServer('assign-usb-device', props.user.device_sn)
                console.log('passing arduino sn:', props.user.device_sn)
            }
            console.log('Globals.instance().usbRequested', Globals.instance().usbRequested )
            // (Globals.instance().controllerApp)&&
        }

        ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/narrative/'+id)   
        let notFoundAttempts=0
        //check if we are still syncing 
         const ping = ()=>{
            console.log("ping")
            axios.get('/status/'+id).then((results)=>{
                console.log(results.data)
                let state=results.data.state
                setStatus(state)
                
                if( state ==="loading"){
                     setTimeout( ping, 100)
                }
                if(state==="not found"){
                    notFoundAttempts++
                    if(notFoundAttempts <= 4)
                        setTimeout( ping, 300)        
                    else
                        setState("error")
                }else 
                    setState(state)
            }).catch(function (error) {
                
                setStatus("Error trying to connect to server")
                setState("error")

            })
        }
        if(Globals.instance().mode === 'static-web' || !Globals.instance().ios){
            setState('complete')
        } else {
            setTimeout( ping, 100)
        }
        
    },[id])
    
 
    useEffect(()=>{
       let getNarrative=async ()=>{
        if(state === "complete"){
            let m = props.narratives.find( val=>{ return String(val.id)===id})
            try { 
                // setStatus('Loading JSON')
                let path = m.content? m.content:'narrative/'+m.id+'/'
                Globals.instance().nid=id
                let res=await fetchCMSJSON(path)
                setTheme(props.themes[res.theme?'t'+res.theme:'t1'])
                setNarrativeModel(res)
                showBackBtn.current = false
                console.log('props.themes,res', props.themes,res)

                //  try {
                //     const queryString = window.location.search;
                //     const urlParams = new URLSearchParams(queryString);
                //     const nav = urlParams.get('nav');
                //     console.log('nav', nav)
                //     if (nav === "closed") {
                //         setCurrentParams(null);
                //         showBackBtn.current = false;
                //         animNarrativeMenuPosForController();
                //         setActiveNarrativePageIndex(0);
                
                //     }
                // } catch(err) {
                //     console.log('anim in error', err)
                // }
                
                //load pages
                try{
                    let arrPages = await loadJSONS(res.pages)
                    // arrPages.unshift({"typeName": "controller-narrative"});
                    console.log("Pages",arrPages)
                    setPages(arrPages);
                    
                }catch(err){
                    console.error(err)
                    Globals.instance().showAlert("Error","Could not load narrative module")
                }

                //load modules
                try{
                    let arrMods = await loadJSONS(res.modules)
                    arrMods.unshift({"typeName": "controller-narrative"});
                    console.log("Modules",arrMods)
                    console.log('currentModule', arrMods[0])
                    setCurrentModule(arrMods[0])
                    setModules(arrMods.filter(m=>{return m.typeName!=="document"}));

                    //calc logo height
                    if(Globals.instance().controllerApp){
                        let s = window.getComputedStyle(refEle.current.querySelector('.narr_menu_heading'))
                        let h = parseFloat(s.marginTop)+  parseFloat(s.marginBottom)+  parseFloat(s.height)
                        refEle.current.style.setProperty('--logoHeight',h+'px')
                        console.log('controller loading')
                    }
                }catch(err){
                    console.error(err)
                    Globals.instance().showAlert("Error","Could not load narrative module")
                }

               

            } catch(err){
                console.error(err)
            }
            }

  
        }
        getNarrative()
        showBackBtn.current = false;
    },[id, props.narratives, props.themes, state])

    useEffect(() => {
        if (goToNarrative) {
            setState('loading');
            setStatus('');
            showBackBtn.current = true;
            setSlidOver(false);
            setNarrativeModel(null);
            setCurrentModule(null);
            if(Globals.instance().controllerApp){
                props.history.push('/controllernarrative/'+ goToNarrative )
            }
        }
    }, [goToNarrative])

    useEffect(()=>{
        console.log('THEME: ',theme)
        if(theme){
         for (const key of Object.keys(theme.css)) {
            refEle.current.style.setProperty('--'+key,theme.css[key])
          }
        }
    },[theme])

    let animateInModule=useCallback(()=>{
        gsap.to(refModule.current.querySelector('.'+styles.moduleHolder),{duration:0.85,opacity:1,marginTop:0,ease:Power3.easeInOut,delay:.1})
        setAnimInCurrentModule(false);
        
    },[refModule])

    // useEffect(()=>{
    //     if (currentModule)animateInModule()
    // },[currentModule,animateInModule])

    useEffect(() => {
        if (activeNarrativePageIndex) {
            showBackBtn.current = false;
            setActivePage(pages[activeNarrativePageIndex])
            console.log('setActivePage', activeNarrativePageIndex, pages[activeNarrativePageIndex]);
        }
    }, [activeNarrativePageIndex, pages])


    useEffect(()=>{

        if( modules){ 
             let ps=[]
            modules.forEach((val,index)=>{ ps.push(getModuleIcon(val,props.theme,index)) })
             Promise.all(ps).then(vals=>{ 
                 setIcons(vals)
             })
         }
     },[modules,  props.theme])

     useEffect(() => {
        if (animInCurrentModule) {
            showBackBtn.current = true;
            console.log("animInCurrentModule", animInCurrentModule, showBackBtn)
            animateInModule()
        }
     }, [animInCurrentModule])



   
    async function loadJSONS(arr){
        
        if(!arr || arr.length===0) return[]
        let proms= []
        arr.forEach(async obj =>{ proms.push(fetchCMSJSON(obj))})

        return new Promise( (resolve,reject) => {
              
            Promise.all(proms).then((values)=>{ resolve(values) }).catch((err)=>{
                reject(err)
            })
        
        })
    }
    const animNarrativeMenuPosForController = useCallback(() => {
        if(!slidOver){
        let buttonContainer = refMenu.current.querySelector('.' + styles.buttonContainer);
        // let menuColRight = ref.current.querySelector('.' + styles.buttonContainer);
        function adjustWidths() {
            gsap.to(buttonContainer,{duration:0, left: 0})
        }
        gsap.to(refMenu.current,{duration:1, width: 330, ease:Power3.easeInOut, onComplete: adjustWidths});
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-position','')
        setSlidOver(true)
        }
    }, [slidOver])

    function onClickModule(evt){
        setCurrentParams(null);
        showBackBtn.current = false;
        animNarrativeMenuPosForController();
        let index = evt.currentTarget.getAttribute("index")
        //set narrative url
        let m = modules[Number(index)]
        console.log(m)
        let eleMod=refModule.current.querySelector('.'+styles.moduleHolder)
        gsap.killTweensOf(eleMod)
        gsap.to(eleMod,{duration:0.85, opacity:0,marginTop:'100%',ease:Power3.easeInOut,onComplete:(mod)=>{
            console.log("setting")
            setCurrentModule(mod)
            animateInModule()
        },onCompleteParams:[m]})
        // }else{setCurrentModule(m)}
        if(Number(index) === 0)
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module','')
        else
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module', index-1)
    }

    function onClickBack(){

        console.log('props', props)
        if (slidOver) {
            let buttonContainer = refMenu.current.querySelector('.' + styles.buttonContainer);
            // let menuColRight = ref.current.querySelector('.' + styles.buttonContainer);
            function adjustWidths() {
                gsap.to(buttonContainer,{duration:0, left: 0})
            }
            gsap.to(refMenu.current,{duration:1, width: "100%", ease:Power3.easeInOut, onComplete: adjustWidths});
            setSlidOver(false)
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-open-full', '')
            console.log('narrative-menu-open-full, sent');
        } else {
            if(Globals.instance().homeLink){
                if(Globals.instance().controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate',Globals.instance().homeLink)   
                }
                if(window.location.pathname.indexOf("/home")!==-1)return
    
               
                props.history.push(Globals.instance().homeLink)
    
    
            }else{
                props.history.push(Globals.instance().getRoute('/home'))
                if(Globals.instance.controllerApp){
                    ControllerServerComs.instance().sendUDPFromControllerToServer('navigate','/home')   
                }
            }
        }
        
    }

    function getModule(){
        let mod = (<div></div>)
        switch (currentModule.typeName){
            // case "controller-narrative" :  mod = (<ControllerPresentation model={NarrativeModel} module={currentModule} modules={modules} setCurrentModule={setCurrentModule} theme={props.theme} pages={pages} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} activePage={activePage} setActivePage={setActivePage} animNarrativeMenuPosForController={animNarrativeMenuPosForController}></ControllerPresentation>)
            // break;
            case "mapbox" : mod = (<ControllerMap setCurrentParams={setCurrentParams} params={currentParams} model={NarrativeModel} module={currentModule} theme={theme} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} setCurrentModule={setCurrentModule} animateInModule={animateInModule} setAnimInCurrentModule={setAnimInCurrentModule} showBackBtn={showBackBtn.current}></ControllerMap>)
            break;
            case "gallery" : mod = (<ControllerGallery setCurrentParams={setCurrentParams} params={currentParams} model={NarrativeModel} module={currentModule} theme={theme} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} setCurrentModule={setCurrentModule} animateInModule={animateInModule} setAnimInCurrentModule={setAnimInCurrentModule} showBackBtn={showBackBtn.current}></ControllerGallery>)
            break;
            case "webgl" : mod = (<ControllerWebGL model={NarrativeModel} module={currentModule} theme={theme} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} setCurrentModule={setCurrentModule} animateInModule={animateInModule} setAnimInCurrentModule={setAnimInCurrentModule} showBackBtn={showBackBtn.current}></ControllerWebGL>)
            break;
            case "stack" : mod = (<ControllerStack setCurrentParams={setCurrentParams} params={currentParams} model={NarrativeModel} module={currentModule} theme={theme} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} setCurrentModule={setCurrentModule} animateInModule={animateInModule} setAnimInCurrentModule={setAnimInCurrentModule} showBackBtn={showBackBtn.current}></ControllerStack>)
            break;  
            default:
        }
        return mod
    }

    return (
        <div className={`fullscreen ${styles.topContainer}`} ref={refEle} state={state} status={status}>
                {/* <h1 style={{'color':'blue'}}>NarrativeController id:{id} {state}</h1> */}
                
                {  state==='loading'  && 
                <div className={`fullscreen fcenter`} style={{zIndex:1000}} >
                    <LoaderWheel status={status} ></LoaderWheel>
                </div>
                }

                {  state==='error'  &&  (
                            <div className='fullscreen fcenter' style={{flexDirection:'column',color:'var(--primary)',textAlign:'center',fontSize:'22px',gap:'30px'}} >
                                <div style={{fontWeight:'bold'}} >Lost Connection to Server</div>
                                <div style={{color:'var(--primary-accent)',fontWeight:'bold',gap:'50px'}} className={`fcenter`}> 
                                    <div onClick={()=>{
                                      ControllerServerComs.instance().postMessageFromControllerToIOS('reconnect','');
                                    }}>
                                        Reconnect
                                    </div>
                                    <div onClick={()=>{
                                        ControllerServerComs.instance().postMessageFromControllerToIOS('backToStart','');
                                    }}>
                                        Back to Select Display
                                    </div>
                                    
                                </div>
                                <div style={{fontSize:'14px'}} >If connection issues persist, try restarting the Desktop application, then reconnecting the iPad</div>
                            </div>
                    )}
                    
                
                { NarrativeModel && 
                    <div className={`${nstyles.menuContainer} force3d`} style={{filter: "none"}}  ref={refMenu}>
                        {
                            NarrativeModel.menu_background_right &&
                            ( <img src={getContentPath(NarrativeModel.menu_background_right)} alt="" className={`${nstyles.menuRightBg}`}></img>)
                        }
                        { !NarrativeModel.menu_background  && 
                            ( <img src={getContentPath('core/narrative/loading-bg.png',true)} className={`${nstyles.menuBg}`} alt="" />)
                        }
                        { NarrativeModel.menu_background && !isVideo(NarrativeModel.menu_background) && 
                            ( <img src={getContentPath(NarrativeModel.menu_background)} className={`${nstyles.menuBg}`} alt="" />)
                        }
                        {
                        NarrativeModel.menu_background && isVideo(NarrativeModel.menu_background) && 
                            ( <video muted loop playsInline autoPlay preload={1} src={getContentPath(NarrativeModel.menu_background)} className={`${nstyles.menuBg} constant`} alt="" />)
                        }
                        
                        <div className={`${styles.menuColRight} rightcolcontainer `}  style={
                            NarrativeModel.menu_background_right?{backgroundColor:'transparent'}:{}}>
                            
                            
                            {slidOver &&     
                            <div className={`${nstyles.buttonContainer}`} >
                                <div className={`${nstyles.btnSpacer}`}></div>
                                    <div >
                                        <div className={`round-btn`} onClick={onClickBack} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                                        <IconArrowLeft ></IconArrowLeft>
                                        </div>
                                </div>
                            </div>
                            }

                
                            {currentModule && (
                            <React.Fragment>
                                <div className={`${styles.moduleContainer} ${currentModule && currentModule.typeName==='controller-narrative'?'active':'inactive'}`} >
                                    <ControllerPresentation model={NarrativeModel} module={currentModule} modules={modules} setCurrentModule={setCurrentModule} theme={theme} pages={pages} activeNarrativePageIndex={activeNarrativePageIndex} setActiveNarrativePageIndex={setActiveNarrativePageIndex} activePage={(activePage)?activePage:pages[0]} setActivePage={setActivePage} animNarrativeMenuPosForController={animNarrativeMenuPosForController} setAnimInCurrentModule={setAnimInCurrentModule} setCurrentParams={setCurrentParams}
                                    setGoToNarrative={setGoToNarrative}
                                    ></ControllerPresentation>
                                </div>
                                
                                <div className={`${styles.moduleContainer} `} overlaymodule={'true'} ref={refModule} >
                                    
                                 {currentModule.typeName!=='controller-narrative' && (
                                     getModule()
                                 )}
                                 </div>
                            </React.Fragment> 
                           
                               )}

                            { NarrativeModel.menu_logo &&(
                                <img src={getContentPath(NarrativeModel.menu_logo)} className={`${styles.menuLogo} narr_menu_heading`} alt="logo"></img>
                            )}
                            { !NarrativeModel.menu_logo &&(
                                <div className={`${nstyles.menuTitle} narr_menu_heading`}>{NarrativeModel.heading}</div>
                            )}
                           
                                <div className={`${styles.menuModuleContainer} `} style={Globals.instance().getThemeStyle(['nav-modules-container'],props.theme)}>
                                    {
                                        icons.map( (icon,index) =>{
                                            return (
                                                <div highlighted={currentModule && currentModule.id && currentModule.id===modules[index].id ? 'true':'false'} className={`${styles.moduleLink}`} key={`nar-menu-mode-item-${index}`} index={index} 
                                                onClick={onClickModule}> 
                                                {icon}
                                                </div>
                                        )})
                                    }
                                </div>
                               
                         
                        </div>
                        {!slidOver && 
                        <div className={`${nstyles.buttonContainer}`}>
                            <div className={`${nstyles.btnSpacer}`}></div>
                                <div >
                                    <div className={`round-btn`} onClick={onClickBack} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                                    <IconArrowLeft ></IconArrowLeft>
                                    </div>
                            </div>
                        </div>}
                </div>
                }

   

        </div>
    )
}


const mapStateToProps = state => {
    const {narratives,themes,user} = state
    return { narratives:narratives,themes,user};
};

export default connect(mapStateToProps, null)(memo(ControllerNarrativeMain,(prev,next)=>{
    return true 
}))