

import React,{useEffect,useState,useRef} from 'react'
import MainRouter from './navigation/MainRouter'
import Alert from './components/Alert'
// import { getContentPath } from './AssetManager'
import Globals from './Globals'
// import M from './utils/M'
import LoadingBG from 'assets/intro-bg.jpg'
import Logo from 'assets/logo-BP.png'
import gsap from 'gsap/gsap-core'
import { setUser } from 'store/actions'
import { connect } from "react-redux";
import history from 'system/navigation/MainRouterHistory'

let  Main=(props)=>{

  const [alert,setAlert]=useState()
  
  const [showBG,setShowBG]=useState(Globals.instance().iosOverlay === true ?false:true)
  const refEle=useRef()
   

  useEffect(()=>{
    
    // document.documentElement.style.setProperty('--scale1080', window.innerHeight/1080);
    // document.documentElement.style.setProperty('--scale1920', window.innerWidth/1920);
    // document.documentElement.style.setProperty('--scale768s', window.innerHeight/768);
    // document.documentElement.style.setProperty('--scale1024', window.innerWidth/1024);
    const resize=()=>{
    
    // let winaspect= window.innerWidth/window.innerHeight;
    
    let ele = document.getElementById('root')
    // if(winaspect < 4/3){
    //   ele.style.width=(window.innerHeight*4/3)+"px"
    //   ele.style.height=window.innerHeight+"px"
    // }
    // else{
    //   ele.style.width=window.innerWidth+"px"
    //   ele.style.height=window.innerHeight+"px"
    // }

    let l= ((1024/768) - (ele.clientWidth/ele.clientHeight))/((1024/768)-(1920/1080))
    if(l>1)l=1
    if(l<0)l=0
    document.documentElement.style.setProperty('--lerpAspect', l);
    
    let sx =  ele.clientWidth/ (1024+(l*(1920-1024)))
    let sy =   ele.clientHeight/(768+(l*(1080-768)))
    let s = sx<sy ? sx :sy
    document.documentElement.style.setProperty('--lerpScale', s);
    
    if(ele.clientWidth/ele.clientHeight > 1920/1080){
      document.documentElement.style.setProperty('--scaleHD', ele.clientHeight/1080);
    }
    else{
      document.documentElement.style.setProperty('--scaleHD', ele.clientWidth/1920); 
    }
    if(ele.clientWidth/ele.clientHeight > 1024/768){
      document.documentElement.style.setProperty('--scaleiPad', ele.clientHeight/768);
    }
    else{
      document.documentElement.style.setProperty('--scaleiPad', ele.clientWidth/1024); 
    }
    // document.documentElement.style.setProperty('--scaleVerticaliPad', ele.clientHeight/768);
    
    window.addEventListener('alert',(evt)=>{
      console.log(evt)
        setAlert(evt.detail)
    })

    window.addEventListener('close-alert',()=>{  setAlert(null)})

    

    var map = {}; // You could also use an array
    document.addEventListener('keydown',(evt)=>{
      map[evt.key] = true
      if(!Globals.instance().ios){
        if((map['f'] && map['Control'])){
          map[evt.key] = false
          if(!Globals.instance().electron)Globals.instance().toggleFullscreen() 
        }      
      }
    })
    document.addEventListener('keyup',(evt)=>{
      map[evt.key] = false
    })
    const onHideEvent=()=>{ setShowBG(false);window.removeEventListener('hide-initial-bg',onHideEvent)}
    window.addEventListener('hide-initial-bg',onHideEvent)
    
    

    //listen for controller events
    const onControllerEvent=(evt)=>{
      
      console.log("Controller event", evt)
      let detail = evt.detail
      if(!detail)return
      if(detail.event==='set-user'){
        console.log(detail.user)
        let user = detail.user
        console.log("got user "+user.username)
        props.setUser(user)
      }
      if(detail.event ==='serverIP'){
        console.log('serverIP from onControllerEvent: '+ detail.path)
        Globals.instance().serverIP = detail.path;
      }
      if(detail.event ==='ping'){
        console.log('serverIP from onControllerEvent: ping')
        Globals.instance().controllerPing = true;
      }
      
    }
    
    const onServerEvent=(evt)=>{
      
      // console.log("Server event", evt)
      let detail = evt.detail
      if(!detail)return
      if(detail.event==='navigate'){
        console.log('navigate to path : '+Globals.instance().getRoute(detail.command))

        let arrURL = detail.command.split('?')
        if(arrURL.length===1)
          history.push(Globals.instance().getRoute(detail.command))
        else {
          console.log("params")
          history.push({pathname:arrURL[0], search:'?'+arrURL[1]})
        }
      }
      if(detail.event ==='serverIP'){
        console.log('serverIP from onServerEvent: '+ detail.path)
        Globals.instance().serverIP = detail.path;
      }
      
    }

    if(Globals.instance().controllerApp){
      window.removeEventListener('controller-event',onControllerEvent)
      window.addEventListener('controller-event', onControllerEvent)
    }
    
    if(Globals.instance().serverApp){
      window.removeEventListener('server-event',onServerEvent)
      window.addEventListener('server-event',onServerEvent)
    }


  }
  resize()
    window.addEventListener('resize',()=>{ resize() })

  });

  function onLoadBG(){
    gsap.to(refEle.current,{opacity:1,duration:0.25})
  }
  

    return( 
        
        <div id="main" className="fullscreen"> 
          { showBG && (
          <div className={`fullscreen fcenter background`} ref={refEle}  style={{opacity:0}}>
              <img className={`fullscreen cover`} src={LoadingBG} alt="" onLoad={onLoadBG}></img>
              <img src={Logo} style={{'position':'absolute','left':'7%','width':'12%','bottom':'9.2%'}} alt=""></img>
          </div>
          )}
          <MainRouter ></MainRouter>
          { alert &&  (<Alert alert={alert}></Alert>)}
        </div>
    )
}




function mapDispatchToProps(dispatch) {
  return {setUser: val => dispatch(setUser(val))};
}


export default connect(undefined, mapDispatchToProps,undefined,{forwardRef:true})(Main)

