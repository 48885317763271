import DefatulProject from './default'
import LeadenCamManager from './leadenhallcammanger'

import * as THREE from 'three'
// import { Vector3 } from 'three'

export default class LeadenHall extends DefatulProject{


    constructor(){
        super()
        console.log("leadenhall")        
        
    }

    getCamManager(){
      return new LeadenCamManager(this)
    }

    traverseMesh(child,meshDefault){

        // console.log("TRAVERSE")
  
          if (Object.prototype.hasOwnProperty.call(this.config.scene.mesh_settings, child.name)) {
              const objSettings = this.config.scene.mesh_settings[child.name];
              
             if (objSettings.interactive === true) {
                this.interactiveMeshes[child.name] = child;
              }
              this.setMeshParams(child, Object.assign({}, meshDefault, objSettings));
             } else {
              this.setMeshParams(child, Object.assign({}, meshDefault));
             }
            
            if(this.reflectionCube){
                if (child.name.toLowerCase().indexOf('refl') === 0) {
                  child.material=this.getReflectionMaterial(0.45,1.03,1,0.1,0.75)
                  child.material.depthWrite = false;
                  child.material.depthTest = true;
                  child.renderOrder = -1;
                  child.castShadow = true;
                  child.receiveShadow = false;
                  child.material.transparent = true;
                }
                else if (child.name.toLowerCase().indexOf('glass2') === 0) {
                  child.material=this.getReflectionMaterial(0.75,1.02,3,0.8,2)
                  child.material.depthWrite = false;
                  child.material.depthTest = true;
                  child.renderOrder = -1;
                  child.castShadow = true;
                  child.receiveShadow = false;
                  child.material.transparent = true;
                }
                else if(child.name.toLowerCase().indexOf('chrome')===0){
                child.geometry.computeVertexNormals()
                // child.material=this.getChromeMaterial()
                this.setChromeMaterial(child.material)
                }
                else if (child.name.toLowerCase().indexOf('bldg-fade') === 0) {
                    // console.log("FADE")
                    // if(Object.keys(this.interactiveMeshes).length<=1){
                    this.interactiveMeshes[child.name] = child
                    child.material.transparent=true
                    child.material.opacity=0.5;
                  // }
                    
                }
                
            }
      }

      traverseModel(){
          const PI2= (2* Math.PI)

          super.traverseModel()
          // Object.keys(this.interactiveMeshes).forEach((key,i)=>{
            // if(i!=1){
            //   delete this.interactiveMeshes[key]
            // }
          // })

          Object.keys(this.interactiveMeshes).forEach((key,i)=>{
            // var box = new THREE.Box3().setFromObject(this.interactiveMeshes[key])
            // get center x
            var pntCenter = new THREE.Vector3()
            var pntCenter2 = new THREE.Vector3()
            
            //colorize the fadeable buildings
            // let c = new THREE.Color( Number(i%3)/3.0, Number((i+1)%6)/6.0, Number((i+2)%9)/9.0 );
            // this.interactiveMeshes[key].material.color=c;
            
            const bbox = new THREE.Box3()
            this.interactiveMeshes[key].updateMatrixWorld(true)

            this.interactiveMeshes[key].geometry.computeBoundingBox()
            bbox.copy( this.interactiveMeshes[key].geometry.boundingBox )
            bbox.getCenter(pntCenter2)
            // console.log(pntCenter2)
            
            bbox.applyMatrix4( this.interactiveMeshes[key].matrixWorld );
            // bbox.updateMatrix()
            // bbox.applyMatrix4( this.model.matrixWorld);
            // bbox.updateMatrix()

            bbox.getCenter(pntCenter)
            // FOR SOME REASON WE NEED THIS which should noe be
            pntCenter.multiply(this.model.scale)
            this.interactiveMeshes[key].centroidPnt = pntCenter.clone()
            
            // const cube = new THREE.Mesh( new THREE.BoxGeometry( 0.0025, 0.0025, 0.0025 ), new THREE.MeshBasicMaterial( {color: c} ));
            // cube.position.set(this.interactiveMeshes[key].centroidPnt.x,this.interactiveMeshes[key].centroidPnt.y,this.interactiveMeshes[key].centroidPnt.z)
            // cube.position.setY(.185)
            // cube.updateMatrix();
            
            // this.scene.add(cube)
            //calculate some min max
            this.config.views.forEach((v)=>{
              
              let lookout =  new THREE.Vector2(pntCenter.x-v.lookat[0] ,  pntCenter.z-v.lookat[2])
              // lookout.sub(pntCenter)
              let angle=  PI2 - lookout.angle() +Math.PI/2 //WHY PI/2 here?
              if(angle>PI2) angle = angle - PI2;

              if(!v.interactiveMeshes)v.interactiveMeshes={}
              if(!v.interactiveMeshes[key])v.interactiveMeshes[key]={}

              const spread=Math.PI/2
              let min =angle-(spread/2)
              // if(min<0) min=min + PI2
              let max= angle+(spread/2)
              // if(max<PI2) max=max - PI2
  
              v.interactiveMeshes[key].minTheta= min
              v.interactiveMeshes[key].maxTheta= max
              v.interactiveMeshes[key].mesh=this.interactiveMeshes[key]
              
              // console.log(angle,v.interactiveMeshes[key].minTheta,v.interactiveMeshes[key].maxTheta)
            })
            
            
            // this.interactiveMeshes[key]
            
            })
      }
      cleanup(){
        this.config.views.forEach((v)=>{v.interactiveMeshes=undefined})
        super.cleanup()
      }
  
}