import React,{useRef} from 'react'
import LoginForm from './components/LoginForm'
import Logo from 'assets/home/logo.svg'
import history from './navigation/MainRouterHistory'
import Globals from 'system/Globals'

export default function Login(props){
    const refLogin  = useRef()
    function onLogin(){

        if(props.location && props.location.state && props.location.state.referrer)
        {
            let path = props.location.state.referrer.pathname
            let search = props.location.state.referrer.search
            console.log("referer ... "+path)
            history.replace({pathname:Globals.instance().getRoute(path),search:search})
        }
        else{
            history.replace({ pathname: '/' })
        }
        
    }

return  (

    
    <div className={`fullscreen fcenter`} style={{ background:'#EFEFF0' }}>
        <div style={{width:'302px'}}>
            <img src={Logo} alt="" className={`logo`} />
            <LoginForm onLogin={onLogin} ref={refLogin}></LoginForm>
        </div>
    </div>
)
    
}