
import React,{useRef,useState,useEffect,memo,useCallback} from 'react'
import {useParams} from 'react-router-dom'
import Page from './Page'
import {fetchCMSJSON} from 'system/AssetManager'

const PageRender = function(props){

    let { id,pageid } = useParams();
    
    let [pageModel,setPageModel] = useState(null)

    const [loading , setLoading ]= useState(true)

    useEffect(()=>{

        const loadPage = async (url)=>{
            try{
                let pm = await fetchCMSJSON(url)
                setPageModel(pm)
                setLoading(false)
            }catch( e ){
            
                console.error(e)
         
            }
        }
       loadPage('narrative/'+id+'/'+pageid+'/?format=json')

    },[])
    function setScrollTable(){

    }
    function onPageLoad(){
        
    }
    function Interaction(){}
    function onIntersect(){}

    return (<div className={`fullscreen pagerender ${loading?'loading':'loaded'}`}>
          
          { pageModel && (
          <Page key={`${id}-${pageid}` } 
            id={`${id}-${pageid}` } 
            model={pageModel}  
            onLoad={onPageLoad}
            interaction={Interaction}
            pageIndex={0} 
            theme={{}}
            onIntersect={onIntersect}
            setScrollCallback={setScrollTable}
            renderOnly={true}
            ></Page>)
        }
                    )

        </div>)
    
}

export default PageRender;
