
import React,{useEffect,useCallback,useState,useRef} from 'react'
// import {getContentPath} from  'system/AssetManager'
import gsap,{Power2} from 'gsap'
import {getContentPath} from 'system/AssetManager';
import SwiperCore, { Navigation, A11y, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import Globals  from 'system/Globals'

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import NarrativeMenuItem  from 'narrative/NarrativeMenuItem'
import ControllerServerComs from 'system/ControllerServerComs'
// import { isCompositeComponent } from 'react-dom/test-utils'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const ControllerPresentation = (props)=>{
   
    const [menuItems,setMenuItems] = useState([])
    const [swiperInst, setSwiperInst] = useState(null);
    const [isOverSized, setIsOverSized] = useState(false);
    const [activeMenuIndex, setActiveMenuIndex] = useState(0);
    const refMenuContainer = useRef();
    const refHdFramePos = useRef(0); 

    useEffect(()=>{
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        console.log('controller presentation props', props)

    },[])

    useEffect(()=>{        
        let mitems=[]
        if(props.model.menu_links){
            props.model.menu_links.forEach((val,index)=>{
            
                let menuItem = { label:val.label,index:index, 'id': val.page }
            
            if(val.group){
                let grp = mitems.find(mi=>{return mi.label===val.group})
                if(!grp){
                    grp={label:val.group, subs:[menuItem],index:"grp"+index,'id':val.id}
                    mitems.push(grp)
                }
                else
                    grp.subs.push(menuItem)
            }else{
                mitems.push(menuItem)
            }
            })
            // console.log("SET ",mitems)
            setMenuItems(mitems)
        }

    },[props.model.menu_links])

    useEffect(() =>{
        if (swiperInst) {
            if (props.activeNarrativePageIndex !== null) {
                swiperInst.slideTo(props.activeNarrativePageIndex, 700)
                console.log('swiperInst.slideTo(props.activeNarrativePageIndex, 700)', props.activeNarrativePageIndex)
            } else {
                setTimeout(() => {
                    swiperInst.slideTo(0, 700)
                }, 2000);
                console.log('swiperInst.slideTo(0, 700)')
            }
            console.log('props.activeNarrativePageIndex', props.activeNarrativePageIndex)
        }
       
       
    }, [swiperInst, props.activeNarrativePageIndex])

    useEffect(() =>{
        if (swiperInst) {
            setTimeout(() => {
                swiperInst.slideTo(0, 700)
            }, 300);
            
        }
       
    }, [swiperInst])



    useEffect(() =>{
        if (props.activePage) {
            if ((props.activePage.hd_size[0] > 1920)||(props.activePage.hd_size[1] < 1080)) {
                setIsOverSized(true);
            } else {
                setIsOverSized(false);
            }
        }
    }, [props.activePage])
    
    const onClickLink = (evt) =>{
        // console.log(evt.currentTarget)
        if (menuItems) {
           
            let index = Number(evt.currentTarget.getAttribute("index"));
            console.log('onClickLink', props.model.menu_links[index]);
            const page = props.model.menu_links[index].page;
            let newSerialPage = props.model.menu_links[index];
            let serial;
            if (newSerialPage) {
                serial = props.model.menu_links[index].serial_string;
            }
            if (serial) {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', page + "?s=" + serial)
                console.log('command', page + "?s=" + serial)
            } else {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', page)
            }

            let pageIndex = props.pages.findIndex(obj=>{return obj.id === page});
            console.log(pageIndex)
            if (pageIndex > -1) {
                props.setActivePage(null);
                props.setActiveNarrativePageIndex(pageIndex);
               
            }else{
                props.setActivePage(null);
            }
            props.animNarrativeMenuPosForController()
            setActiveMenuIndex(index);
        }
    }


    const onExpandSubs =useCallback(evt=>{
        let openEle = refMenuContainer.current.querySelector(`.${nstyles.menuLink}[open]:not([index="${evt}"])`)
        if(openEle){
            openEle.dispatchEvent(new CustomEvent('close'))
        }
    },[])


    const selectPrevPage = () => {
        let newNarrativePage = props.activeNarrativePageIndex - 1;
        if (newNarrativePage >= 0) {
            props.setActiveNarrativePageIndex(newNarrativePage)
            let pageId = props.pages[newNarrativePage].id
            let newSerialPage = props.model.menu_links[newNarrativePage];
            let serial;
            if (newSerialPage) {
                serial = props.model.menu_links[newNarrativePage].serial_string;
            }
            if (serial) {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', pageId+ ",scroll?s=" + serial)
            } else {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId+',scroll')
            }
            
            console.log("selectPrevPage", newNarrativePage)
            let pageIndex = props.pages.findIndex(obj=>{return obj.id === pageId});
           
            if (pageIndex > -1) {
                props.setActivePage(null);
                props.setActiveNarrativePageIndex(pageIndex);
            }
            gsap.to("#hdFrame_" + props.activePage.id, {left: 0, duration: 0.3})
            refHdFramePos.current = 0;
        }
    }

    const selectNextPage = () => {
        let newNarrativePage = props.activeNarrativePageIndex + 1;
        if ((newNarrativePage <= (props.model.pages.length - 1))&&(props.activePage)) {
            props.setActiveNarrativePageIndex(newNarrativePage)
            let pageId = props.pages[newNarrativePage].id
            let newSerialPage = props.model.menu_links[newNarrativePage];
            let serial;
            if (newSerialPage) {
                serial = props.model.menu_links[newNarrativePage].serial_string;
            }
            if (serial) {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', pageId + ",scroll?s=" + serial)
            } else {
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId+',scroll')
            }
            gsap.to("#hdFrame_" + props.activePage.id, {left: 0, duration: 0.3})
            refHdFramePos.current = 0;

            let pageIndex = props.pages.findIndex(obj=>{return obj.id === pageId});
           
            if (pageIndex > -1) {
                props.setActivePage(null);
                props.setActiveNarrativePageIndex(pageIndex);
            }
               
            
            console.log('selectNextPage', pageId, newNarrativePage)
        }
    }


    const goToNarrativeLink = (link) => {
        let moduleId = link.split("module=");
        let narrativeId = link.split("narrative=");
        if (narrativeId[1]) {
            let narrativeTarget = narrativeId[1];
            let narrativeParams = narrativeTarget.split('?');
            let narrativeIdTarget = narrativeParams[0];

            narrativeId && console.log("goToNarrativeLink", narrativeIdTarget, props, '/narrative/' + narrativeTarget);
            
            props.setCurrentParams(null);
            props.setCurrentModule(null);
            props.setActivePage(null);
            props.setActiveNarrativePageIndex(0);
            props.setGoToNarrative(narrativeIdTarget);
            swiperInst.slideTo(0, 700);
            // history.push('/controllernarrative/'+narrativeIdTarget + '?nav=closed');
          
            setTimeout(()=>{
                
                ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + narrativeTarget )
         
            },300)
        } else if (moduleId[1]) {
            console.log('moduleId[1]', moduleId[1])
            let moduleParam = moduleId[1].split("&");
            let moduleIdTarget = moduleId[1];
            if (moduleParam) {
                moduleIdTarget = moduleParam[0];
            }
            console.log('moduleId', moduleIdTarget, moduleParam)
            moduleId && console.log("goToNarrativeLink", moduleIdTarget, props);
            let moduleIndex = props.modules.findIndex(md=>{return md.id===moduleIdTarget})
            let m = props.modules.find(md=>{return md.id===moduleIdTarget})
            if(m.typeName!=="controller-narrative") props.setCurrentModule(m);
            if(m.typeName!=="controller-narrative") props.setAnimInCurrentModule(true);
            
            props.setCurrentParams(link);
            // props.setActivePage(null);
            
            console.log('set current params, /narrative/' + props.model.id + link)

            
            setTimeout(()=>{
                ControllerServerComs.instance().sendUDPFromControllerToServer('navigate', '/narrative/' + props.model.id + link)
                ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-module', moduleIndex-1);
            },300)
            // console.log("goToNarrativeLink result", moduleIndex-1, m)
            
        } else {
            console.log('no link found')
        }
   
    }

    const showNarrativeTab = (evt) => {
        console.log('showNarrativeTab',evt)
        let state = props.activePage.controller_states[Number(evt.currentTarget.getAttribute('index'))];
        console.log(state)
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-page-state', state.id)
    }

    function handleCanvasClick(e, pageId) {
        let pageIndex = props.pages.findIndex(obj=>{return obj.id === pageId});

        console.log('handleCanvasClick')
        props.setActiveNarrativePageIndex(pageIndex)
        let newSerialPage = props.model.menu_links[pageIndex];
        let serial;
        if (newSerialPage) {
            serial = props.model.menu_links[pageIndex].serial_string;
        }
        if (serial) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link-with-lights', pageId + ",scroll?s=" + serial)
            console.log('command', pageId + ",scroll?s=" + serial)
        } else {
            ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId+ ",scroll")
        }
        
        // ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-menu-link', pageId +',scroll');           
        if (pageIndex > -1) {
            props.setActivePage(null);
            props.setActiveNarrativePageIndex(pageIndex);
        }
        console.log('props.activePage', props.activePage, props.activeNarrativePageIndex, pageId, pageIndex, props.pages);
       swiperInst.slideTo(pageIndex, 700);
    //    gsap.to(e.target, )
    }

    function handleScrollClick(dir) {
        console.log('dir', dir, props.activePage)
        if ((dir === 'right')&&(props.activePage)) {
            var hdFrame = document.getElementById("hdFrame_" + props.activePage.id);
            let img = document.getElementById("hdFrame_" + props.activePage.id + "_img");
            let img_w = img.offsetWidth;
            let hdFrame_w = hdFrame.offsetWidth;
            var leftPos = 0;
            if ((hdFrame_w + refHdFramePos.current) - img_w > 180) {
                leftPos = 180;
                console.log('leftPos', leftPos);
            } else {
                leftPos = (img_w - (hdFrame_w + refHdFramePos.current))
                console.log('refHdFramePos.current', leftPos, img_w, hdFrame_w, refHdFramePos.current);
            }
            gsap.to("#hdFrame_" + props.activePage.id, {left: leftPos, duration: 0.3})
            refHdFramePos.current = leftPos;
        }
        if ((dir === 'left')&&(props.activePage)) {
            // var hdFrame = document.getElementById("hdFrame_" + props.activePage.id);
            gsap.to("#hdFrame_" + props.activePage.id, {left: "0px", duration: 0.3})
        }
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-scroll', dir);
       
    }

    return (
        <React.Fragment>
           <div className={`${styles.linksContainer} hide-scroller`} ref={refMenuContainer} >
                     <h3>Narrative</h3>
                    {
                        
                       menuItems &&  menuItems.map((val,index)=>{
                            return(
                             <NarrativeMenuItem
                             model={val}
                             index={val.index}
                             key={`nar-menu-link-${index}`} 
                             onClickLink={ onClickLink } 
                             onExpand={onExpandSubs}
                             className={`${nstyles.menuLink} ${styles.controllerMenuLink}`}
                             theme={props.theme}
                             style={Globals.instance().getThemeStyle(['nav-menu-item'],props.theme)}
                             ></NarrativeMenuItem>
                            
                            )
                        })

                        
                    }
            </div>

            { props.pages && props.activePage && 
                    
                <section className={`${styles.moduleHolder}`}>
                    {(props.pages.length > 0) ?
                        (<Swiper
                            spaceBetween={0}
                            slidesPerView={"auto"}
                            className={`${styles.canvasSwiper}`}
                            initialSlide={0}
                            centeredSlides={false}
                            onSwiper={(swiper) => setSwiperInst(swiper)}
                            // onSlideChange={() => console.log(swiperInst)}
                        >
                        {props.pages
                        //   .sort((a, b) => a.ordering > b.ordering ? 1 : -1)
                          .map((section, i) => 
                          <SwiperSlide 
                            key={'swiper_' + i}
                            id={"swiperSlide_" + section.id}
                            onClick={(e) => handleCanvasClick(e, section.id)}
                            // onClick={() => console.log(props.activePage, props, section.id)}
                            className={`${styles.canvasFrame} ${(props.activeNarrativePageIndex === i)?styles.on:''}`}
                            width={"auto"}
                            height={"140px"}
                          > 
                            <div className={`${styles.pageThumb}`}>
                                <span className={`${styles.hdFrame}`} id={"hdFrame_" + section.id}></span>
                                <span className={`${styles.fullFrame}`}></span>
                                <img src={getContentPath(section.screenshot)} id={"hdFrame_" + section.id + "_img"} alt="" />
                
                            </div>
                            
                          </SwiperSlide>
                        )}
                        </Swiper>) : null}
                        
                        

                        <div className={`${styles.grid50}`}>
                        {(isOverSized) ?(
                            <React.Fragment>
                            <div className={`${styles.splitBtn} ${styles.controllerBtn}`}>
                                <div className={`${styles.scrollLeft} ${styles.scrollArea}`} onClick={()=>handleScrollClick('left')}>
                                <CaretLeft />
                                </div>
                                <div className={`${styles.pageBtn}`} onClick={selectPrevPage}>
                                    Previous
                                </div>
                            </div>
                            <div className={`${styles.splitBtn} ${styles.controllerBtn} ${styles.right}`}>
                              
                                 <div className={`${styles.pageBtn}`} onClick={selectNextPage}>
                                 Next
                                </div>
                                <div className={`${styles.scrollLeft} ${styles.scrollArea}`} onClick={()=>handleScrollClick('right')}>
                                    <CaretRight />
                                </div>
                            </div>
                            

                          </React.Fragment>
                        ):(<React.Fragment>
                            <div className={`${styles.controllerBtn} ${styles.pageBtn}`} onClick={selectPrevPage}>
                          Previous
                          </div>
                          <div className={`${styles.controllerBtn} ${styles.pageBtn}`} onClick={selectNextPage}>
                          Next
                          </div>
                          </React.Fragment>)}
                      </div>

           
                    {
                        props.activePage && 
                        <div className={`${styles.linkButtonContainerParent}`}>
                            <div className={`${styles.linkButtonContainer}`}>                              
                            {props.activePage.controller_links && props.activePage.controller_links.map((obj,i) => (
                                <div className={`${styles.linkBtn}`} key={"controller_link_" + i} onClick={() => goToNarrativeLink(obj.link)}>{obj.heading}</div>
                            ))}
                            </div>
                            {props.activePage.controller_states && props.activePage.controller_states.length > 0 && 
                            <div className={`${styles.stateButtonContainer}`}>
                                <h3>Tabs</h3>
                                <div className={`${styles.stateButtons}`}>
                                {props.activePage.controller_states && props.activePage.controller_states.map((obj,i) => (
                                    <div className={`${styles.stateBtn}`} key={"controller_link_" + i} onClick={showNarrativeTab} index={i}>{obj.state_heading}</div>
                                ))}
                                </div>
                            </div>}
                        </div>
                    }

                </section>   

                }
</React.Fragment>
    )

}

export default ControllerPresentation

// ${(props.activePage && (props.activePage.menupage_id === val.id)?styles.on:'')}