import React,{useState,useEffect,useRef} from 'react'
import styles from './narrative.module.scss'
import gsap,{Power3} from 'gsap'
import {ReactComponent as IconCaret} from 'assets/icons/caret_closed.svg';
import Globals  from 'system/Globals';

const NarrativeMenuItem =  ({model,style,onClickLink,onExpand,index,className,theme})=>{
    
    const [open,setOpen] = useState(false)
    const refContainer = useRef()
    const refEle = useRef()

    //allow for close event from outside
    useEffect(()=>{

        // console.log('NarrativeMenuItem', model)

        let ele =refEle.current
        const onClose = ()=>{setOpen(false)}
        ele.removeEventListener('close',onClose)
        ele.addEventListener('close',onClose)
        return ()=>{ ele.removeEventListener('close',onClose)}
    },[])

    //handle open/close state change
    useEffect(()=>{
        if(refContainer.current){
            if(open){
                if(typeof(onExpand) ==='function')onExpand(index)
                let theight = refContainer.current.style.height
                refContainer.current.style.height='auto'
                let height = refContainer.current.clientHeight
                refContainer.current.style.height=theight
                console.log(theight,height,refContainer.current.style.height)
                gsap.to(refContainer.current,{height:height,duration:0.5,ease:Power3.easeInOut})
            }
            else{
                // refContainer.current.style.height='0px'
                gsap.to(refContainer.current,{height:0,duration:0.5,ease:Power3.easeInOut})
            }
        }
        
    },[index, onExpand, open])

    function onClick(evt){
        
        if(evt.target.getAttribute('type')==='page'){
                onClickLink(evt)

           
        }
        else{
            setOpen(!open)
        }
    }

    return(
        <div 
        
        className={`${className}`}
        ref={refEle}
        open={open}
        index={index}
        style={style}
        >
            <span index={model.index} type={model.subs ? 'group' : 'page'  }  onClick={ onClick } >
            {model.label}
            {
                 className.indexOf(styles.menuLink)>=0 && model.subs && model.subs.length  && (
                   
                        <IconCaret className={'themeSVGStroke themeSVGStrokeHover'} style={{...style,'--svg-stroke':style.color,'--svg-stroke-hover':style['--hover-color'],'opacity':0.75}} ></IconCaret>
                    
                )
            }
            
            </span>
            {
                model.subs && (    
                        <div className={styles.menuSubContainer} ref={refContainer}>
                            {
                             model.subs.map((sub,i)=>{
                                return <div key={`narmenu-${index}-${i}`} 
                                    index={sub.index}
                                    onClick={ onClick } 
                                    type='page'
                                    style={style}
                                    className={`${className} ${styles.menuSubLink}`}>
                                    {sub.label}
                                    </div>
                             })   
                            }
                        </div>
                        )
        
            }    
        </div> 
    )

}


export default NarrativeMenuItem