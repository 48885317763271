
import React,{useState,useRef,memo,useCallback,useEffect} from 'react'
import {getContentPath, loadImage} from  'system/AssetManager'
import {animateParallax,getAnimationModel,animateIntersect,setInitialAnimIntersectState,setFinalAnimIntersectState,animateStateTransition} from './CanvasAnimator'
import Globals from 'system/Globals'
import gsap,{Power3} from 'gsap'
// import {onErrorLoad} from 'system/AssetManager'

function StatePNGComponent(props){
    
    
    const [inline,setInline] = useState({})
    const [inlineImage,setInlineImage] = useState({})
    const [imgSrc,setImgSrc] = useState(null)
    
    const refLoaded=useRef(false)
    const refEle = useRef()
    const refFormat = useRef()
    const refOrigOffset = useRef(0)
    const refAnimationModel = useRef({})
    const refStateAnimationEles = useRef([])
    

    
    useEffect(()=>{ 
        refOrigOffset.current=refEle.current.offsetLeft
        if(refFormat.current && refFormat.current.animation){
            refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
            if(props.renderOnly===true){
                setFinalAnimIntersectState(refEle.current,refFormat.current.animation)
            }
        }
    },[inline]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onScroll = useCallback((scrollLeft,scale,pageWidth)=>{
       animateParallax(refEle.current,refAnimationModel.current,scrollLeft,pageWidth,refStateAnimationEles)
    },[props.model.animation,refEle])// eslint-disable-line react-hooks/exhaustive-deps

    const onIntersect=  useCallback((evt,threshold)=>{
        if(evt.detail.isIntersecting )
        {
           if(evt.detail.intersectionRatio >= threshold){
             animateIntersect(refEle.current,refAnimationModel.current,refStateAnimationEles)  
            }
        }
        else if(evt.detail.intersectionRatio <=0.001){
            setInitialAnimIntersectState(refEle.current,refAnimationModel.current)
        }
    },[refEle])

    useEffect(()=>{

        refFormat.current = Globals.instance().getCanvasFormat(props.model.formats,props.format)
        
        let style ={position:'absolute',transform: 'translateZ(0), perspective: 1000px'}
        if(refFormat.current.style){ style = Object.assign(style,refFormat.current.style) }
        
        let f =refFormat.current.file?refFormat.current.file:refFormat.current.image
        //check if initial page state 
        if(props.pageState){
            let s= refFormat.current.states.find(obj=>{return obj.state===props.pageState})
            if(s)f=s.image
        }
        setImgSrc(getContentPath(f))
        
        //set up animation Type
        if(refFormat.current.animation ){
            if(refFormat.current.animation.typeName==='parallax'){
                props.setScrollCallback(onScroll,props.index)
                
            } 
            else if(refFormat.current.animation.typeName==='page'){
                props.onIntersect(onIntersect,props.index)
                // style.willChange='transform, scale, opacity'
            }
        }
        setInline(style)
        setInlineImage(Object.assign({},style,{position:'absolute',transform: 'translateZ(0), perspective: 1000px',willChange:'transform',left:0,top:0}))
        return ()=>{
            refStateAnimationEles.current=[]
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    function onError(evt){
        console.error("PNG load error"+refLoaded.current, evt.target.src)
        // onErrorLoad(evt)
        console.log("error state png "+evt.target.src)
        if(!refLoaded.current && imgSrc!==""){
            Globals.instance().trackError('PNG Component '+imgSrc+' ',false)
            onLoad() //dont hold up the page 
        }
    }
    
    function onLoad(){
        //down res the image
        if(!refLoaded.current){
            props.onLoad(props.index,refEle.current,props.model)
            refLoaded.current=true
            if(props.renderOnly===true){
                refAnimationModel.current = getAnimationModel(refEle.current,refFormat.current.animation) 
                setFinalAnimIntersectState(refEle.current,refAnimationModel.current)
            }
        }   
        else if(refStateAnimationEles.current.length){
            
            //clear out old anims 
            //let arrAnims=refStateAnimationEles.current
            refStateAnimationEles.current.forEach((ele)=>{
                gsap.killTweensOf(ele)
                ele.remove()
            })
            refEle.current.firstElementChild.style.opacity=1
       }
    }

    //generate layer for state animation transition
    function getStateAnimElem(img,currentElem){
        let ele =document.createElement('div')
        ele.appendChild(img)
        ele.style.transform=currentElem.style.transform
        ele.style.opacity=currentElem.style.opacity
        img.style.position='absolute'
        ele.classList.add('stateanim' , 'fullscreen')
         
        let arrps= ['top','left','width','height','object-fit']
        arrps.forEach((param)=>{ img.style[param]=currentElem.style[param]})
        return ele
    }

    useEffect(()=>{
        if(refLoaded.current && refFormat.current.states){
            
            let s= refFormat.current.states.find(obj=>{return obj.state===props.pageState})
            
            if(s && s.image){
                try{
                        //preload the image
                        loadImage(getContentPath( s.image)).then( (img)=>{
                        
                        let animModel = {
                            "typeName":"page",
                            "stateAnimations":refFormat.current.animation.stateAnimations
                        }
                        
                        let m = getAnimationModel(img,animModel,'stateAnimations') 
                        
                        let stateAnimElem =  getStateAnimElem(img,refEle.current)

                        refEle.current.parentNode.insertBefore(stateAnimElem,refEle.current.nextSibling) 
                        
                        //copy
                        let arrps= ['top','left','width','height']
                        arrps.forEach((param)=>{ img.style[param]=refEle.current.style[param] })
                        
                        let opAnim = animateStateTransition(
                            refEle.current, //main element
                            img, //new image state
                            m, //animation model
                            (setSrc,eleAnim)=>{ 
                                eleAnim.parentNode.classList.add("animcomplete")
                                // console.log("COMPLETE" +eleAnim.parentNode.classList.contains('under'))
                                if(!eleAnim.parentNode.classList.contains('under'))
                                setSrc(eleAnim.getAttribute("src"))
                            },
                            [setImgSrc,img])
                            

                         // //flag previous state changes
                         refStateAnimationEles.current.forEach(ele=>{
                            ele.classList.add('under')
                                if(opAnim){
                                    gsap.killTweensOf(ele.firstElementChild,"opacity")
                                    gsap.to(ele.firstElementChild,opAnim)
                                }
                            })
                        refStateAnimationEles.current.push(stateAnimElem)
                        
                    })
                       
                }catch(e){console.error(e)}
                
            }
        }
    },[props,refLoaded])
    
    return ( 
        <div state={props.pageState} style={inline} ref={refEle} >
            <img src={imgSrc} alt="" onLoad={onLoad} state={props.pageState} style={inlineImage} className={'force3d'} onError={onError}></img>
        </div>
    )

}

const statePNG = memo( StatePNGComponent ,(prev,next)=>{  
    return prev.pageState === next.pageState 
})
export  {statePNG}
