
import React,{useEffect,useRef,useState} from 'react'
import {getContentPath} from  'system/AssetManager'
import {fetchCMSJSON,fetchCMSJSONs} from 'system/AssetManager'
import gsap,{Power2} from 'gsap'
import Globals  from 'system/Globals';
import styles from './controller-narrative.module.css'
import nstyles from '../narrative/narrative.module.scss'
import mstyles from '../narrative/modules/modules.module.css'
import NarrativeMenuItem  from 'narrative/NarrativeMenuItem'
import ControllerServerComs from 'system/ControllerServerComs'
import ControllerJoystick from './ControllerJoystick'
import ControllerVideoUI from './ControllerVideoUI';
import {ReactComponent as Chevron} from '../assets/icons/caret_down.svg'
import {ReactComponent as CloseButton} from '../assets/icons/close.svg'
import {ReactComponent as ChevronThinButton} from '../assets/icons/caret_right.svg'
import {ReactComponent as PlayButton} from '../assets/icons/play.svg'
import {ReactComponent as VRButton} from '../assets/icons/vr_white.svg'
import {ReactComponent as Pin} from '../assets/icons/mapbox_pin.svg'
import {ReactComponent as ZoomOut} from '../assets/icons/minus.svg'
import {ReactComponent as ZoomIn} from '../assets/icons/plus.svg'
import {ReactComponent as CaretRight} from '../assets/icons/caret_right.svg'
import {ReactComponent as CaretLeft} from '../assets/icons/caret_left.svg'
import {ReactComponent as CaretUp} from '../assets/icons/caret_up.svg'
import {ReactComponent as CaretDown} from '../assets/icons/caret_down.svg'
import Help2Finger from '../assets/help/Directions-2fingers.webp'
import HelpSwipe from '../assets/help/Directions-swipe.webp'
import HelpTouch from '../assets/help/Directions-touch.webp'

const ControllerGallery = (props)=>{

    const joyStickRef = useRef();
    const videoRef = useRef();
    const [menuOpen, setMenuOpen] = useState(true);
    const [selected,setSelected] = useState(null)
    const [styleMenu,setStyleMenu]=useState({})
    const [menuItems,setMenuItems] = useState([])
    const [styleMenuSelected,setStyleMenuSelected]=useState({})
    const [galleryItems, setGalleryItems] = useState(null);
    const [showJoystick, setShowJoystick] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [fileType, setFileType] = useState(null);
    const [isMatterport, setIsMatterport] = useState(false);
    const [activePage, setActivePage] = useState(null);
    const [vidIsPlaying, setVidIsPlaying] = useState(false);
    const [showLanding, setShowLanding] = useState(true);

    function processParams(){
        let params = new URLSearchParams(props.params);
        let file0 = params.get('file');
        let cat0 = params.get('category');
        let thumbs = params.get('thumbs');
        let landing = params.get('landing');
        
        if (file0){
            let fileId = parseInt(file0);
            let fileIndex = galleryItems.map(function(x) {return x.id; }).indexOf(fileId);
            onThumbnailClick(galleryItems[fileIndex].id, galleryItems[fileIndex].fileType, "", "params")
        }
        
        if (cat0) {
            let catId = parseInt(cat0);
            let catIndex = menuItems.map(function(x) {return x.id; }).indexOf(catId);

            setSelected(catIndex);
            var results = [];
            props.module.files.forEach(obj =>{ 
                obj.categories.includes(menuItems[catIndex].id) && results.push(obj)
            });
            setGalleryItems(results);
            setShowLanding(false);
           
        }

        if ((thumbs === 'false')||(landing === 'false')) {
            setShowLanding(false);
        }
        
        
        props.setCurrentParams(null);

    }
    
    useEffect(()=>{
        console.log('gallery props', props)
        setShowLanding(true);
        return () => {
            // setSelected(0);
        }
    },[])

    useEffect(() => {
        if (props.params && menuItems && (menuItems.length > 0)) {
            processParams();
        }
    }, [props.params, menuItems])

    useEffect(()=>{
        if (props.module.id) {
            // setSelected(0);
            // setActivePage(props.activeNarrativePageIndex);
            // props.setActiveNarrativePageIndex(null);
            let arrMenuItems=[]
            props.module.categories.forEach(obj=>{
                arrMenuItems.push({label:obj.name, 'id': obj.id, "name": obj.name, "thumbnail": obj.thumbnail})
            })
            setMenuItems(arrMenuItems)
            setGalleryItems(props.module.files);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            console.log('controller gallery', props.module.files)
            setStyleMenu(Globals.instance().getThemeStyle(['nav-menu-item'],props.theme))
            setStyleMenuSelected(Object.assign({},Globals.instance().getThemeStyle(['nav-menu-item'],props.theme),{color:'var(--primary-accent)', '--hover-color': 'var(--primary-accent)'}))
            // setCategories(props.module.mapboxes)
        } 

    }, [props.module.id])

    useEffect(() => {
        if (showJoystick) gsap.to(joyStickRef.current, {opacity: 1, duration: 0.3})
        else gsap.to(joyStickRef.current, {opacity: 0, duration: 0.3})
    }, [showJoystick])

    useEffect(() => {
        if (showVideo) gsap.to(videoRef.current, {opacity: 1, duration: 0.3})
        else gsap.to(videoRef.current, {opacity: 0, duration: 0.3})
    }, [showVideo])

    useEffect(() => {
       if (galleryItems && (galleryItems.length > 0)) {
            let result = galleryItems.some(item => item.fileType === "external" && item.external_src.includes('matterport'));
            // console.log('result', galleryItems, result);
            setIsMatterport(result);

            // if (galleryItems[0].fileType === 'video') {
            //     setFileType('video');
            //     setShowVideo(true);
            // }

       }
    }, [galleryItems])

    function handleMenu(){
        if (menuOpen) {
            setMenuOpen(false)
        } else {
            setMenuOpen(true);
        }
    }

    function onThumbnailClick(id, type, file, method) {
        console.log("onThumbnailClick", id, type, file, method);
        setFileType(type);
        if (((type === 'vr')||(type === 'external'))&&((method === "image")||(method === "params"))) {
            (showJoystick) ? setShowJoystick(false) : setShowJoystick(true);
        }
        if ((type === "video")&&(method === "image")) {
            (showVideo) ? setShowVideo(false) : setShowVideo(true);
            setVidIsPlaying(true);
        }
        if ((type === "video")&&(method === "params")) {
            (showVideo) ? setShowVideo(false) : setShowVideo(true);
            setVidIsPlaying(false);
        }
        if (method !== "params") {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', id);
        }
       
    }

    const onClickThumbnail = (index) =>{

        setSelected(index)
        var filter = menuItems[Number(index)]
        var results = [];
        props.module.files.forEach(obj =>{ 
            obj.categories.includes(filter.id) && results.push(obj)
        });
        setGalleryItems(results);
        console.log("gallery click", filter, results, 'test')
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-category', filter.id);
        if (results.length > 0) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', results[0].id);
            onThumbnailClick(results[0].id, results[0].fileType, results[0].file, 'link')
            
        }
        setShowLanding(false);
        
    }

    const onClickLink = (evt) =>{
        
        setSelected(Number(evt.currentTarget.parentNode.getAttribute('index')))
        var filter = menuItems[Number(evt.currentTarget.parentNode.getAttribute('index'))]
        var results = [];
        props.module.files.forEach(obj =>{ 
            obj.categories.includes(filter.id) && results.push(obj)
        });
        setGalleryItems(results);
        console.log("gallery click", filter, results)
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-category', filter.id);
        if (results.length > 0) {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-select-file', results[0].id);
            onThumbnailClick(results[0].id, results[0].fileType, results[0].file, 'link')
            
        }
        setShowLanding(false);
    }   

    const onClickClose = () => {
        console.log('close')
        if ((fileType === 'vr')||(fileType === 'external')) {
            setShowJoystick(false);
        }
        if (fileType === 'vr') {
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "closeVr");
        }
        if (fileType === 'video') {
            setShowVideo(false);
            setVidIsPlaying(false);
            ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vidpause");
        }
        
    }

   

    const onZoomOut = () => {
        // console.log('zoomOut') 
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomout");
     }
 
     const onZoomIn = () => {
        //  console.log('zoomIn') 
         ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "vrzoomin");
      }

      const onPrevClick = () => {
        console.log('onPrevClick') 
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "prev");
     }

     const onNextClick = () => {
        console.log('onNextClick') 
        ControllerServerComs.instance().sendUDPFromControllerToServer('module-gallery-command', "next");
     }

     const onKeyToggle = (command) => {
        console.log('onArrow', command) 
        ControllerServerComs.instance().sendUDPFromControllerToServer('key', command);
     }

     const onClickBack = () => {
        console.log('onClickBack', props.activePage) 
        props.setCurrentModule({typeName: 'controller-narrative'});
        // props.setActiveNarrativePageIndex(activePage);
        props.setAnimInCurrentModule(false);
        ControllerServerComs.instance().sendUDPFromControllerToServer('narrative-close-module');

        console.log('props', props)
       
     }

    return (
        <React.Fragment>
           <div className={`${styles.linksContainer} hide-scroller`}>
                     <h3>Galleries</h3>
                     <nav>
                        {menuItems && (menuItems.length > 0) && menuItems.map((val, index) => (
                            <NarrativeMenuItem
                             model={val}
                             objId={val.id}
                             index={index}
                             key={`gallery_category_${index}`} 
                             onClickLink={ onClickLink } 
                             onExpand={()=>{}}
                             className={nstyles.menuLink+' '+styles.controllerMenuLink }
                             theme={props.theme}
                             style={selected===index ? styleMenuSelected : styleMenu}
                              ></NarrativeMenuItem>
                        ))}
                    </nav>
            </div>

            {
            <section className={`${styles.moduleHolder}`}>
                {props.showBackBtn && <div className={`${styles.backBtnRow}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onClickBack()}>Back to Narrative</div>
                </div>}
                {(showLanding && menuItems && (menuItems.length > 1)) ? 
                    (<div className={`${styles.landingContainer}`}>
                            
                        <div className={`${styles.heading}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>
                                {props.model.heading}  Galleries
                        </div>
                        <div className={`${styles.itemsContainer}`}>
                            {menuItems.map((category, i) => (
                            <div 
                                className={`${styles.itemContainer}`}
                                key={'gallery_thumb_' + i}
                                onClick={() => onClickThumbnail(i)}
                            >
                                <img src={getContentPath(category.thumbnail)} alt=""></img>
                                <div className={`${styles.caption}`} style={(Globals.instance().getThemeStyle(['module-landing-primary'],props.theme))}>{category.name}</div>
                    
                            </div>
                            ))}
                        </div>
            
                    </div>)
                    : null}

                <div className={`${styles.galleryThumbnails} ${(props.showBackBtn)?styles.hasBackBtnRow:""}`}>
                    {galleryItems && galleryItems
                        .sort((a, b) => a.ordering - b.ordering)
                        .map((file, i) => (
                        <div 
                            onClick={() => onThumbnailClick(file.id, file.fileType, file.file, 'image')}
                            key={'gallery_file_' + i} 
                            className={`${styles.galleryThumbnail}`}
                        >
                        <img src={getContentPath(file.thumbnail)} alt="" />
                        {(file.caption) ? 
                            <div 
                            className={`${styles.caption}`}
                            style={{display: "none"}}
                            >{file.caption}</div> 
                            : null}
                        </div>
                    ))}</div>
                
                    <div className={`${styles.moduleThumbsScrollContainerGradient}`}></div>
                    <div className={`${styles.buttons}`}>
                    <div className={`${styles.pillBtns}`}>
                    <div className={`${styles.pillBtn}`} onClick={() => onPrevClick()}><CaretLeft /></div>
                    <div className={`${styles.pillBtn} ${styles.pillBtnRight}`} onClick={() => onNextClick()}><CaretRight /></div>
                    </div>
                </div>
            </section>}
            {showJoystick &&  
                <section ref={joyStickRef} className={`${styles.galleryPopup}`}>
             
                    <ControllerJoystick 
                        fileType={`${(isMatterport)?"matterport":fileType}`}
                        twoFingerDrag={(fileType === "external")?true:false}
                    />
                    {(isMatterport) ?
                        <div className={`${styles.arrowRowsFlex}`}>
                            <div className={`${styles.arrowRows}`}>
                                <div className={`${styles.arrowRow} ${styles.arrowRow0}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('i')}><CaretUp /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow1}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('left')}><CaretLeft /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow2}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('right')}><CaretRight /></div>
                                </div>
                                <div className={`${styles.arrowRow}  ${styles.arrowRow3}`}>
                                    <div className={`${styles.roundBtn}`} onClick={() => onKeyToggle('k')}><CaretDown /></div>
                                </div>
                            </div>
                        </div>
                        
                    : null }
                   
                   {(fileType !== "external") ?
                   <div className={`${styles.buttons} ${styles.buttons70}`}>
                        <div className={`${styles.pillBtns}`}>
                        {(fileType === "vr")?(<React.Fragment><div className={`${styles.pillBtn}`} onClick={() => onZoomOut()}><ZoomOut /></div><div className={`${styles.pillBtn} ${styles.pillBtnRight}`} onClick={() => onZoomIn()}><ZoomIn /></div></React.Fragment>): null}
                        </div>
                    </div>
                        
                    : null }
                    
                    {((isMatterport)||(fileType === "external")||(fileType === "vr")) ?
                    <div className={`${styles.helpPanel}`}>  
                        <h2>Directions</h2>                  
                        {(isMatterport) ?
                        <div className={`${styles.helpRow}`}>
                            <h3>Matterport VR</h3>
                            <p>Use the trackpad to click hotspots around the room</p>
                            <img src={HelpTouch} alt="touch" /> 
                            <p>Use arrow controls to change viewpoint</p>
                        </div>
                        : null }
                        {(!isMatterport && (fileType === "external")) ?
                        <div className={`${styles.helpRow}`}>
                            <h3>Embedded VR</h3>
                            <p>Use 1 finger like a cursor to select on-screen buttons</p>
                            <img src={HelpTouch} alt="touch" />
                            <p>Use 2 fingers to pan around the room</p>
                            <img src={Help2Finger} alt="touch" />
                        </div>
                        : null }
                        {(fileType === "vr") ?
                        <div className={`${styles.helpRow}`}>
                            <h3>360 VR</h3>
                            <p>Tap once to enter the VR</p>
                            <img src={HelpTouch} alt="touch" /> 
                            <p>Slide finger to pan around the space</p>
                            <img src={HelpSwipe} alt="touch" />
                            <p>Use buttons to zoom in/out</p>
                        </div>
                        : null }
                    </div>
                    : null }
                    
                    <span className={`${styles.joystickClose}`}>
                        <div className={`${mstyles.closeBtnContainer}`}>
                            <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <CloseButton></CloseButton>
                            </div>
                        </div>
                    </span>
                  

                </section>
            }
            {showVideo &&  
                <React.Fragment>
                    <ControllerVideoUI ref={videoRef} vidIsPlaying={vidIsPlaying} setVidIsPlaying={setVidIsPlaying} ></ControllerVideoUI>
                   
                    <span className={`${styles.joystickClose}`}>
                        <div className={`${mstyles.closeBtnContainer}`}>
                            <div className={`round-btn`} onClick={onClickClose} style={Globals.instance().getThemeStyle(['nav-menu-btn'],props.theme)}>
                            <CloseButton></CloseButton>
                            </div>
                        </div>
                    </span>
                    </React.Fragment>
            }
        </React.Fragment>
    )

}

export default ControllerGallery
